import { AxiosRequestConfig } from 'axios';
import Cookie from 'js-cookie';
import { StringConstants } from '../constants/string.constants';
import { isArray } from 'lodash';
import isEmpty from 'lodash.isempty';
const getHeaders = (): AxiosRequestConfig => {
  return {
    headers: {
      Authorization: 'Bearer ' + Cookie.get(StringConstants.COOKIE.JWT)
    }
  };
};

/**
 * Util function to replace empty strings with null
 * @param data
 */
const cleanEmptyString = (data: any) => {
  Object.keys(data).forEach(key => {
    if (data[key] === '') data[key] = null;
  });

  return data;
};

/**
 * Util function to add the given params as query params in the given url
 * @param {string} url - url
 * @param {any} params - params to be added as query params in url
 * @returns {string} - updated url with query params
 */
const addQueryParamsToUrl = (url: string, params: any): string => {
  if (isEmpty(params)) return url;

  Object.keys(params).map((key, index) => {
    if (index === 0) url += '?';
    else url += '&';
    const value = params[key];
    if (isArray(value)) {
      value.map((each, i) =>
        i < value.length - 1
          ? (url += key + '=' + encodeURIComponent(each) + '&')
          : (url += key + '=' + encodeURIComponent(each))
      );
    } else url += key + '=' + encodeURIComponent(value);
  });

  return url;
};

export const ApiUtils = {
  getHeaders,
  cleanEmptyString,
  addQueryParamsToUrl
};
