import { Button, Col, PaginationProps, Row, message } from 'antd';
import { useEffect, useState } from 'react';

import AddUserModal from '../../modals/users/add-user';
import ConfirmationModal from '../../modals/confirmation';
import { Group } from '../../types/data/group.type';
import { GroupService } from '../../service/group.service';
import { HeaderMenuItemStore } from '../../store';
import LoadingComponent from '../loading';
import TableComponent from '../table';
import { TableType } from '../../enums/table-type.enum';
import { User } from '../../types/data/user.type';
import { UserRoles } from '../../enums/user-role.enum';
import { UserService } from '../../service/user.service';
import isEmpty from 'lodash.isempty';
import { useSearchParams } from 'react-router-dom';

type UserProps = {
  users?: User[];
  userRole?: string;
  currentUserOid?: string;
  group?: Group;
  refreshGroup?: any;
  paginationAtTop?: boolean;
};

const UsersComponent: React.FC<UserProps> = (props: UserProps) => {
  const [loading, setLoading] = useState(false);
  const [users, setUsers] = useState(props.users);
  const [deleteUserLoading, setDeleteUserLoading] = useState(false);
  const [confirmationModalVisible, setConfirmationModalVisibility] =
    useState(false);
  const [selectedUser, setSelectedUser] = useState<User>();

  const [addUserLoading, setAddUserLoading] = useState(false);
  const [addUserVisible, setAddUserVisible] = useState(false);

  const [searchParams, setSearchParams] = useSearchParams();
  const [page, setPage] = useState(searchParams.get('page') || 1);
  const [size, setPageSize] = useState(searchParams.get('size') || 50);

  const getUsers = () => {
    setLoading(true);
    setAddUserLoading(true);
    HeaderMenuItemStore.setDisableMenuItems(true);
    UserService.getAllUsers({ page, size })
      .then(users => {
        setUsers(users);
        setSearchParams(
          {
            page: users.page,
            size: users.size
          },
          {
            replace: true
          }
        );
      })
      .catch(err => {
        console.log('Error while retrieving users :: ', err);
        message.error('Error while fetching users, please try again later');
      })
      .finally(() => {
        setLoading(false);
        setAddUserLoading(false);
        HeaderMenuItemStore.setDisableMenuItems(false);
      });
  };

  useEffect(() => {
    if (!props.users) getUsers();
  }, [page, size]);

  const onPageChange: PaginationProps['onChange'] = (
    page_: number,
    size_: number
  ) => {
    setPage(page_);
    setPageSize(size_);
  };

  const handleUserRemoval = (user: User) => {
    setSelectedUser(user);
    setConfirmationModalVisibility(true);
  };

  const handleDeleteUserCancel = () => {
    setConfirmationModalVisibility(false);
  };

  const handleDeleteUserOk = () => {
    setDeleteUserLoading(true);
    UserService.deleteUser(selectedUser?.id as string)
      .then(() => {
        setDeleteUserLoading(false);
        setConfirmationModalVisibility(false);
        message.open({
          content: (
            <>
              User deleted from Analytical Dashboard.
              <br />
              The user will also need to be removed from Azure to restrict their
              access.
            </>
          ),
          type: 'warning'
        });
        getUsers();
      })
      .catch(err => {
        setDeleteUserLoading(false);
        console.log('Error while deleting user :: ', err);
        message.error('Failed to delete user, please try again later');
      });
  };

  const handleRemoveUserFromGroup = () => {
    setDeleteUserLoading(true);
    GroupService.removeUsersFromGroup(props.group?.id as string, {
      userIds: [selectedUser?.id]
    })
      .then(() => {
        setDeleteUserLoading(false);
        setConfirmationModalVisibility(false);
        message.success('User removed successfully');
        props.refreshGroup && props.refreshGroup();
      })
      .catch(err => {
        setDeleteUserLoading(false);
        console.log('Error while removing user from group :: ', err);
        message.error(
          'Failed to remove user from group, please try again later'
        );
      });
  };

  const handleAddUserCancel = () => {
    setAddUserVisible(false);
  };

  const handleAddUserOk = (data: any) => {
    setAddUserLoading(true);
    UserService.addUser(data)
      .then(() => {
        setAddUserVisible(false);
        setAddUserLoading(false);
        message.success('User added successfully');
        getUsers();
      })
      .catch((err: any) => {
        setAddUserLoading(false);
        message.error(err.error.message);
        console.log('Error while add user to group :: ', err);
      });
  };

  if (deleteUserLoading) return <LoadingComponent tip={'Deleting User ...'} />;
  else
    return (
      <Row>
        {props.userRole === UserRoles.ADMIN && (
          <Col xs={24} className='table-action-btn'>
            <Button type='primary' onClick={() => setAddUserVisible(true)}>
              Add User
            </Button>
          </Col>
        )}
        <Col xs={24} className='space-top-10'>
          <TableComponent
            loading={loading}
            data={isEmpty(users) ? [] : users}
            tableType={TableType.USERS}
            userRole={props.userRole}
            currentUserOid={props.currentUserOid}
            handleUserRemoval={handleUserRemoval}
            paginationAtTop={props.paginationAtTop}
            onPageChange={onPageChange}
            isUserManagement={isEmpty(props.group)}
          />
        </Col>
        <ConfirmationModal
          title={
            !isEmpty(props.group)
              ? `Are you sure to remove ${selectedUser?.name} from group ${props.group?.name}`
              : `Are you sure to delete ${selectedUser?.name} ?`
          }
          visible={confirmationModalVisible}
          danger={true}
          handleOk={() =>
            !isEmpty(props.group)
              ? handleRemoveUserFromGroup()
              : handleDeleteUserOk()
          }
          handleCancel={handleDeleteUserCancel}
          content={
            !isEmpty(props.group)
              ? `The user (${selectedUser?.email}) will not have access to any report that the group has access to.`
              : `The user (${selectedUser?.email}) will be removed from the portal and will loose access to all reports.`
          }
        />
        <AddUserModal
          handleCancel={handleAddUserCancel}
          loading={addUserLoading}
          handleOk={handleAddUserOk}
          visible={addUserVisible}
        />
      </Row>
    );
};

export default UsersComponent;
