import { Layout, Menu, MenuProps } from 'antd';
import { Link, useLocation } from 'react-router-dom';

import { CurrentUserProp } from '../../types/props/current-user.props';
import EIPLogo from '../../assets/images/eip-logo.png';
import { HeaderMenuItemStore } from '../../store';
import React from 'react';
import { ReadOutlined } from '@ant-design/icons';
import { RouteConstants } from '../../constants/route.constants';
import { observer } from 'mobx-react-lite';

const getMenuItems = (
  isAdmin: boolean,
  currentPath: string,
  disableMenuItems: boolean
): MenuProps['items'] => {
  const menuItems = [
    {
      label: <Link to={RouteConstants.REPORTS}>Reports</Link>,
      key: 'reports'
    },
    {
      label: <Link to={RouteConstants.GROUPS}>Groups</Link>,
      key: 'groups'
    }
  ];

  isAdmin &&
    menuItems.push({
      label: <Link to={RouteConstants.USERS}>Users</Link>,
      key: 'users'
    });

  menuItems.push({
    label: (
      <Link to={RouteConstants.DOCUMENT}>
        User Guide <ReadOutlined />
      </Link>
    ),
    key: 'userguide'
  });

  return menuItems.map(menuItem => ({
    ...menuItem,
    disabled: disableMenuItems && currentPath !== menuItem.key
  }));
};

const Header: React.FC<CurrentUserProp> = ({ isAdmin }) => {
  const location = useLocation();
  const currentPath = location.pathname.split('/').filter(n => n);

  return (
    <Layout.Header
      style={{
        position: 'fixed',
        width: '100%',
        zIndex: 4,
        background: '#fff'
      }}>
      <div className='logo'>
        <Link to={RouteConstants.REPORTS}>
          <img src={EIPLogo} />
        </Link>
      </div>
      <Menu
        mode='horizontal'
        defaultSelectedKeys={[currentPath[0]]}
        items={getMenuItems(
          isAdmin,
          currentPath[0],
          HeaderMenuItemStore.getDisableMenuItems()
        )}
      />
    </Layout.Header>
  );
};

export default observer(Header);
