import { Button, Tag } from 'antd';

import type { ColumnsType } from 'antd/lib/table';
import { DeleteOutlined } from '@ant-design/icons';
import { User } from '../../types/data/user.type';
import { UserRoles } from '../../enums/user-role.enum';
import { UsersTableProps } from '../../types/props/users-table.props';

/**
 * get column options for users table
 */
export const getUsersTableColumns = (
  props: UsersTableProps
): ColumnsType<User> => {
  const columns: ColumnsType<User> = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      sorter: (a, b) => a.name.localeCompare(b.name)
    },
    {
      title: 'Email Address',
      dataIndex: 'email',
      key: 'email',
      render: email => (
        <a href={`https://teams.microsoft.com/l/chat/0/0?users=${email}`}>
          {email}
        </a>
      )
    },
    {
      title: 'Role',
      dataIndex: 'role',
      key: 'role',
      render: role => (
        <Tag color={role === UserRoles.ADMIN ? 'orange' : 'purple'}>{role}</Tag>
      ),
      sorter: (a, b) => a.role.localeCompare(b.role),
      filters: [
        {
          text: UserRoles.ADMIN,
          value: UserRoles.ADMIN
        },
        {
          text: UserRoles.VIEWER,
          value: UserRoles.VIEWER
        }
      ],
      onFilter: (value, record) => record.role.indexOf(value as string) === 0
    },
    {
      title: 'Azure OID',
      dataIndex: 'azureOid',
      key: 'AzureOid'
    }
  ];

  if (props.userRole === 'ADMIN')
    columns.push({
      title: '',
      render: data =>
        props.currentUserOid !== data.azureOid && props.isUserManagement ? (
          <Button
            type='primary'
            danger
            onClick={() => props.handleUserRemoval?.(data)}>
            <DeleteOutlined /> Remove
          </Button>
        ) : (
          <></>
        )
    });

  return columns;
};
