import * as React from 'react';

import { Column, Row } from 'simple-flexbox';

import { Spin } from 'antd';

interface LoadingComponentProps {
  tip?: string;
}

const LoadingComponent: React.FunctionComponent<
  LoadingComponentProps
> = props => {
  return (
    <Row vertical='center' horizontal='center' className='full-height'>
      <Column>
        <Spin size='large' tip={props.tip ? props.tip : 'Loading...'}></Spin>
      </Column>
    </Row>
  );
};

export default LoadingComponent;
