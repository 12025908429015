import { MomentConstants } from '../constants/moment.constants';
import moment from 'moment-timezone';

/**
 * Format the given date in given formate
 * @param {string | Date} date - date to be formatted
 * @param  {string} format - format in which date has to be formatted
 */
export const formatDate = (
  date: string | Date,
  format: string = MomentConstants.DATE_TIME_24_HOUR_FORMAT
) => moment.tz(date, moment.tz.guess().toString()).format(format);

export const zoneAbbr = moment().tz(moment.tz.guess().toString()).zoneAbbr();
