import {
  Button,
  Col,
  Descriptions,
  Row,
  Space,
  Tabs,
  Typography,
  message
} from 'antd';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router';

import ConfirmationModal from '../../../modals/confirmation';
import CreateOrEditGroupModal from '../../../modals/groups/create-or-edit';
import { CurrentUserProp } from '../../../types/props/current-user.props';
import { DeleteOutlined } from '@ant-design/icons';
import EmptyDataComponent from '../../../components/empty-data';
import { Group } from '../../../types/data/group.type';
import { GroupService } from '../../../service/group.service';
import { Helmet } from 'react-helmet';
import LoadingComponent from '../../../components/loading';
import { PageHeader } from '@ant-design/pro-layout';
import ReportsComponent from '../../../components/report/all-reports';
import { RouteConstants } from '../../../constants/route.constants';
import { SecureComponent } from '../../../components';
import { UserRoles } from '../../../enums/user-role.enum';
import UsersComponent from '../../../components/user';

const GroupDetailsPage: React.FC<CurrentUserProp> = (
  props: CurrentUserProp
) => {
  const { groupId } = useParams();
  const navigate = useNavigate();

  const [groupDetail, setGroupDetail] = useState<Group>();
  const [loading, setLoading] = useState(true);

  const [editGroupVisible, setEditGroupVisibility] = useState(false);
  const [editGroupLoading, setEditGroupLoading] = useState(false);

  const [deleteGroupModalVisible, setDeleteGroupModalVisible] = useState(false);

  const getGroupDetails = (groupId: string) => {
    setLoading(true);
    GroupService.getGroupByID(groupId)
      .then((group: Group) => {
        setGroupDetail(group);
      })
      .catch(err => {
        console.log('Error while fetching group details :: ', err);
        message.error('Failed to fetch group detail, please try again later');
      })
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    getGroupDetails(groupId as string);
  }, [groupId]);

  const handleDeleteGroupCancel = () => {
    setDeleteGroupModalVisible(false);
  };

  const handleDeleteGroupOk = () => {
    setLoading(true);
    GroupService.deleteGroup(groupId as string)
      .then(() => {
        message.success('Group deleted successfully');
        setDeleteGroupModalVisible(false);
        setLoading(false);
        navigate(RouteConstants.GROUPS);
      })
      .catch(err => {
        console.log('error while deleting report ::', err);
        message.error('Failed to delete report, please try again later');
        setLoading(false);
        handleDeleteGroupCancel();
      });
  };

  const handleEditModalCancel = () => {
    setEditGroupVisibility(false);
    setEditGroupLoading(false);
  };

  const handleEditModelOk = (data: any) => {
    setEditGroupLoading(true);

    GroupService.updateGroup(groupId as string, data)
      .then(() => {
        setEditGroupLoading(false);
        setEditGroupVisibility(false);
        message.success('Group updated successfully');
        getGroupDetails(groupId as string);
      })
      .catch(err => {
        setEditGroupLoading(false);
        message.error('Failed to update group, please try again later');
        console.log('Error while updating group :: ', err);
      });
  };

  if (loading) return <LoadingComponent tip='Loading Group...' />;
  else if (groupDetail)
    return (
      <>
        <Helmet>
          <title>
            {groupDetail.name} Group | Analytics Dashboard | Elite Insurance
            Partners
          </title>
          <meta
            name='description'
            content={`${groupDetail.name} | Elite Insurance Partners | Analytics Dashboard - Group`}
          />
        </Helmet>
        <PageHeader
          style={{ backgroundColor: 'white', borderRadius: 6 }}
          onBack={() => navigate(RouteConstants.GROUPS)}
          title={groupDetail.name}
          extra={
            props.isAdmin
              ? [
                  <Space key='buttons'>
                    <Button
                      key='edit_report'
                      type='primary'
                      onClick={() => {
                        setEditGroupLoading(true);
                        setEditGroupVisibility(true);
                      }}>
                      Edit Group
                    </Button>
                    <Button
                      key='delete_report'
                      type='primary'
                      danger
                      onClick={() => {
                        setDeleteGroupModalVisible(true);
                      }}>
                      <DeleteOutlined /> Delete Group
                    </Button>
                  </Space>
                ]
              : []
          }>
          <Descriptions
            size='small'
            column={{ xxl: 3, xl: 3, lg: 3, md: 3, sm: 1, xs: 1 }}
            className='space-top-10'>
            <Descriptions.Item label='Email'>
              <Typography.Text copyable={groupDetail.email ? true : false}>
                {groupDetail.email ? groupDetail.email : '-'}
              </Typography.Text>
            </Descriptions.Item>
            <Descriptions.Item label='Description' span={3}>
              <Typography.Text>
                {groupDetail.description ? groupDetail.description : '-'}
              </Typography.Text>
            </Descriptions.Item>
          </Descriptions>
          <Tabs defaultActiveKey='1' className='space-top'>
            <Tabs.TabPane tab='Group Members' key='1'>
              <Row gutter={16} className='space-top-10'>
                <Col xs={24}>
                  <UsersComponent
                    users={groupDetail.users}
                    userRole={
                      props.isAdmin ? UserRoles.ADMIN : UserRoles.VIEWER
                    }
                    currentUserOid={props.oid}
                    group={groupDetail}
                    refreshGroup={() => getGroupDetails(groupId as string)}
                    paginationAtTop={true}
                  />
                </Col>
              </Row>
            </Tabs.TabPane>
            <Tabs.TabPane tab='Associated Reports' key='2'>
              <Row gutter={16} className='space-top-10'>
                <Col xs={24}>
                  <ReportsComponent
                    reports={groupDetail.reports}
                    paginationAtTop={true}
                  />
                </Col>
              </Row>
            </Tabs.TabPane>
          </Tabs>
        </PageHeader>
        <CreateOrEditGroupModal
          visible={editGroupVisible}
          loading={editGroupLoading}
          data={groupDetail}
          handleOk={handleEditModelOk}
          handleCancel={handleEditModalCancel}
        />

        {/* Delete Group Confirmation */}
        <ConfirmationModal
          title={`Are you sure to delete group - ${groupDetail.name} ?`}
          visible={deleteGroupModalVisible}
          danger={true}
          handleOk={handleDeleteGroupOk}
          handleCancel={handleDeleteGroupCancel}
          content={`This group and all its associated data would be deleted permanently.`}
        />
      </>
    );
  // Empty Component
  else
    return (
      <EmptyDataComponent
        title={`Group Not Found`}
        subTitle='Sorry, the group you are looking for might have been deleted'
        navigateTo={RouteConstants.GROUPS}
      />
    );
};

export default SecureComponent(GroupDetailsPage, true);
