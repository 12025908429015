import { Modal } from 'antd';

type ConfirmationModalProps = {
  visible: boolean;
  handleOk: (data: any) => void;
  handleCancel: (data: any) => void;
  title: string;
  content?: string;
  danger?: boolean;
};

const ConfirmationModal = (props: ConfirmationModalProps) => (
  <Modal
    title={props.title}
    onCancel={props.handleCancel}
    okText='Yes, Confirm'
    okButtonProps={
      props.danger
        ? { danger: true }
        : {
            type: 'primary'
          }
    }
    onOk={props.handleOk}
    open={props.visible}>
    {props.content && <p>{props.content}</p>}
  </Modal>
);

export default ConfirmationModal;
