import { PaginationProps } from 'antd';
import { Report } from '../../../types/data/report.type';
import TableComponent from '../../table';
import { TableType } from '../../../enums/table-type.enum';

type ReportProps = {
  reports?: Report[];
  loading?: boolean;
  paginationAtTop?: boolean;
  onPageChange?: PaginationProps['onChange'];
};

const ReportsComponent = (props: ReportProps) => (
  <TableComponent
    loading={props.loading}
    data={props.reports}
    tableType={TableType.REPORTS}
    paginationAtTop={props.paginationAtTop}
    onPageChange={props.onPageChange}
  />
);

export default ReportsComponent;
