import { PaginationProps, Spin, Table, Typography } from 'antd';
import {
  generateHistSummaryDetailInitialCellContent,
  isHistoricalStatisticsOperator
} from '../../utils/report.utils';
import {
  getReportHistoryColumns,
  getReportResultColumns,
  getReportResultSubStatsColumns,
  getReportsColumns
} from '../../utils/table-managers/reports.table-manager';

import type { ColumnsType } from 'antd/lib/table';
import { GroupTableProps } from '../../types/props/group-table.props';
import { Report } from '../../types/data/report.type';
import { ReportHistoricalStatisticsType } from '../../enums/report-historical-statistics.enum';
import { ReportResultHistoricalSummary } from '../../types/data/report-result-historical-summary.type';
import { ReportResultSummary } from '../../types/data/report-result-summary.type';
import { TableType } from '../../enums/table-type.enum';
import { UsersTableProps } from '../../types/props/users-table.props';
import capitalize from 'lodash.capitalize';
import { getGroupsColumns } from '../../utils/table-managers/groups.table-manager';
import { getIndividualUserColumns } from '../../utils/table-managers/individual.table-manager';
import { getUsersTableColumns } from '../../utils/table-managers/users.table-manager';
import isEmpty from 'lodash.isempty';

type TableComponentProps = {
  data: any;
  tableType: TableType;
  loading?: boolean;
  summary?: ReportResultSummary[];
  paginationAtTop?: boolean;
  baseColumn?: any;
  onPageChange?: PaginationProps['onChange'];
  report?: Report;
  historicalSummary?: ReportResultHistoricalSummary[];
} & UsersTableProps &
  GroupTableProps;

const TableComponent = (props: TableComponentProps) => {
  let columns: ColumnsType<any> | null = null;
  if (props.tableType === TableType.USERS)
    columns = getUsersTableColumns({
      userRole: props.userRole,
      currentUserOid: props.currentUserOid,
      handleUserRemoval: props.handleUserRemoval,
      isUserManagement: props.isUserManagement
    });
  else if (props.tableType === TableType.REPORT_HISTORY)
    columns = getReportHistoryColumns();
  else if (props.tableType === TableType.REPORT_RESULT && props.data.length > 0)
    columns = getReportResultColumns(props.data[0], props.report);
  // get columns from first index
  else if (props.tableType === TableType.REPORTS) columns = getReportsColumns();
  else if (props.tableType === TableType.SUB_STATS && props.data.length > 0)
    columns = getReportResultSubStatsColumns(props.data[0], props.baseColumn);
  else if (props.tableType === TableType.GROUPS)
    columns = getGroupsColumns({
      userRole: props.userRole,
      handleGroupRemoval: props.handleGroupRemoval
    });
  else if (props.tableType === TableType.INDIVIDUAL_USERS)
    columns = getIndividualUserColumns({
      userRole: props.userRole,
      handleUserRemoval: props.handleUserRemoval
    });

  /**
   * Function to get the filtered report result summary array
   * @param {boolean} forHistoricalStats - indicates if the summaries are to be filtered by historical or non historical stats
   * @returns {ReportResultSummary[]} - filtered report result summary array
   */
  const getFilteredSummary = (
    forHistoricalStats: boolean
  ): ReportResultSummary[] => {
    if (props.summary) {
      if (forHistoricalStats)
        return props.summary.filter(eachSummary => {
          if (isHistoricalStatisticsOperator(eachSummary.operator))
            return eachSummary;
        });
      else
        return props.summary.filter(eachSummary => {
          if (!isHistoricalStatisticsOperator(eachSummary.operator))
            return eachSummary;
        });
    }
    return [];
  };

  const nonHistStatsLength = getFilteredSummary(false).length;

  const histSummaryLastRunStatsBgColor = '#b8eeff';
  const histSummaryCumulativeStatsBgColor = '#daeff0';

  if (columns)
    return (
      <Table
        loading={props.loading}
        columns={columns}
        pagination={{
          defaultPageSize: 50,
          total: props.data.totalCount || props.data.length,
          showTotal: (total, range) =>
            `${range[0]}-${range[1]} of ${total} items`,
          pageSizeOptions: [20, 50, 100, 200],
          position: props.paginationAtTop
            ? ['bottomRight', 'topRight']
            : ['bottomRight'],
          showSizeChanger: true,
          ...(props.onPageChange ? { onChange: props.onPageChange } : {}),
          ...(props.data.page ? { current: props.data.page } : {}),
          ...(props.data.size ? { pageSize: props.data.size } : {})
        }}
        rowKey={row => {
          if (!isEmpty(row.id)) return row.id;
          else return JSON.stringify(row);
        }}
        defaultExpandAllRows={true}
        dataSource={props.data.data || props.data}
        bordered
        scroll={{ x: 'fit-content' }}
        summary={() => (
          <Table.Summary>
            {getFilteredSummary(false).map((eachSummary, index) => (
              <Table.Summary.Row key={index}>
                <>
                  <Table.Summary.Cell index={0} align='center'>
                    <Typography.Text mark strong>
                      {capitalize(eachSummary.operator)}
                    </Typography.Text>
                  </Table.Summary.Cell>
                  {eachSummary.stats.map((stat, index) => (
                    <Table.Summary.Cell
                      key={index}
                      index={stat.index}
                      align='center'>
                      <Typography.Text mark strong>
                        {stat.value}
                      </Typography.Text>
                    </Table.Summary.Cell>
                  ))}
                </>
              </Table.Summary.Row>
            ))}
            {getFilteredSummary(true).map((eachSummary, index) => (
              <>
                <Table.Summary.Row key={nonHistStatsLength + index}>
                  <>
                    <Table.Summary.Cell index={0} align='center'>
                      <Typography.Text mark strong>
                        {capitalize(eachSummary.operator)}
                      </Typography.Text>
                    </Table.Summary.Cell>
                    {eachSummary.stats.map((stat, index) => (
                      <Table.Summary.Cell
                        key={index}
                        index={stat.index}
                        align='center'>
                        <Typography.Text mark strong>
                          {stat.value}
                        </Typography.Text>
                      </Table.Summary.Cell>
                    ))}
                  </>
                </Table.Summary.Row>
                {props.historicalSummary &&
                  props.historicalSummary
                    .filter(
                      histSummary =>
                        histSummary.operator === eachSummary.operator
                    )
                    .map((histSummary, histIndex) => (
                      <Table.Summary.Row
                        key={nonHistStatsLength + index + histIndex}>
                        <>
                          <Table.Summary.Cell index={0} align='center'>
                            <Typography.Text
                              style={{
                                backgroundColor:
                                  histSummary.statsType ===
                                  ReportHistoricalStatisticsType.LAST_RUN
                                    ? histSummaryLastRunStatsBgColor
                                    : histSummaryCumulativeStatsBgColor
                              }}
                              strong>
                              {generateHistSummaryDetailInitialCellContent(
                                histSummary.statsType,
                                histSummary.operator,
                                histSummary.numberOfDays
                              )}
                            </Typography.Text>
                          </Table.Summary.Cell>
                          {histSummary.stats.map((stat, index) => (
                            <Table.Summary.Cell
                              key={index}
                              index={stat.index}
                              align='center'>
                              <Typography.Text
                                style={{
                                  backgroundColor:
                                    histSummary.statsType ===
                                    ReportHistoricalStatisticsType.LAST_RUN
                                      ? histSummaryLastRunStatsBgColor
                                      : histSummaryCumulativeStatsBgColor
                                }}
                                strong>
                                {stat.value}
                              </Typography.Text>
                            </Table.Summary.Cell>
                          ))}
                        </>
                      </Table.Summary.Row>
                    ))}
              </>
            ))}
          </Table.Summary>
        )}
      />
    );
  else return <Spin />;
};

export default TableComponent;
