import {
  Button,
  Form,
  FormInstance,
  Input,
  Modal,
  Select,
  Switch,
  Tooltip
} from 'antd';
import { RefObject, createRef, useEffect, useState } from 'react';

import { Group } from '../../../types/data/group.type';
import { InfoCircleOutlined } from '@ant-design/icons';
import { PaginatedType } from '../../../types/data/paginated.type';
import { User } from '../../../types/data/user.type';
import { UserService } from '../../../service/user.service';
import { getTokenForBrowser } from '../../../utils/auth.utils';

type CreateOrEditGroupModalProps = {
  loading: boolean;
  visible: boolean;
  handleOk: (data: any) => void;
  handleCancel: () => void;
  data?: Group;
};

const CreateOrEditGroupModal = (props: CreateOrEditGroupModalProps) => {
  const [users, setUsers] = useState<User[]>();
  const [loading, setLoading] = useState(false);
  const [addSelf, setAddSelf] = useState(true);
  const createModalRef: RefObject<FormInstance> = createRef<FormInstance>();
  const [form] = Form.useForm();

  const getAllUsers = () => {
    setLoading(true);
    UserService.getAllUsers()
      .then(async (users: PaginatedType<User>) => {
        const userFromCookie = await getTokenForBrowser();
        const preferred_username = JSON.parse(userFromCookie as string)[
          'preferred_username'
        ];
        //For Edit Groups all the users have to be displayed as addToSelf not present in Edit Groups.
        const users_ = props.data
          ? users.data
          : users.data.filter(user => preferred_username != user.email);

        setUsers(users_);
      })
      .catch(err => {
        console.log('Error while fetching users :: ', err);
      })
      .finally(() => setLoading(false));
  };

  const clearFormData = () => {
    form.resetFields();
  };

  useEffect(() => {
    getAllUsers();
  }, []);

  const handleFormSubmit = () => {
    createModalRef.current
      ?.validateFields()
      .then(data => {
        props.handleOk(data);
        clearFormData();
      })
      .catch(err => {
        console.log('Form has validation errors :: ', err);
      });
  };

  const layout = {
    labelCol: { span: 7 },
    wrapperCol: { span: 17 }
  };

  return (
    <div>
      <Modal
        key='create-edit-group'
        title={
          props.data ? `Edit Group - ${props.data.name}` : 'Create New Group'
        }
        onCancel={() => {
          props.handleCancel();
          clearFormData();
        }}
        open={props.visible}
        footer={[
          <>
            <Button
              key='cancel'
              type='primary'
              danger
              onClick={() => {
                props.handleCancel();
                clearFormData();
              }}>
              Cancel
            </Button>
            <Button key='submit' type='primary' onClick={handleFormSubmit}>
              {props.data ? `Update Group` : 'Create Group'}
            </Button>
          </>
        ]}>
        <Form
          form={form}
          {...layout}
          ref={createModalRef}
          initialValues={
            props.data
              ? { ...props.data, users: props.data.users?.map(user => user.id) }
              : { addSelf: true }
          }>
          <Form.Item
            label='Name'
            name='name'
            rules={[{ required: true, message: 'Please input group name' }]}
            required>
            <Input disabled={loading} />
          </Form.Item>

          <Form.Item
            label={
              <div>
                <Tooltip title='Group Email'>
                  <InfoCircleOutlined />
                </Tooltip>{' '}
                Email
              </div>
            }
            name='email'
            rules={[
              {
                type: 'email',
                message: 'Please enter a valid email'
              }
            ]}>
            <Input disabled={loading} />
          </Form.Item>

          <Form.Item label='Description' name='description'>
            <Input.TextArea showCount maxLength={100} disabled={loading} />
          </Form.Item>

          {!props.data && (
            <Form.Item
              name='addSelf'
              label={<span>Add Self to group</span>}
              valuePropName='checked'>
              <Switch
                key='add-self-check'
                checkedChildren='Yes'
                unCheckedChildren='No'
                onChange={checked => setAddSelf(checked)}
              />
            </Form.Item>
          )}

          <Form.Item
            name='users'
            label='Users'
            required={!addSelf}
            rules={[
              { required: !addSelf, message: 'Please select at least one user' }
            ]}>
            <Select
              mode='multiple'
              disabled={loading}
              optionFilterProp='label'
              allowClear>
              {users &&
                users.map((user, index) => {
                  return (
                    <Select.Option
                      value={user.id}
                      label={user.name}
                      key={index}>
                      {user.name}
                    </Select.Option>
                  );
                })}
            </Select>
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};

export default CreateOrEditGroupModal;
