import {
  Button,
  Col,
  Descriptions,
  List,
  Row,
  Space,
  Tabs,
  Tag,
  Typography,
  message
} from 'antd';
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import {
  Report,
  ReportConditionalFormattingRules,
  ReportEmailRules
} from '../../../types/data/report.type';
import {
  getConditionalFormattingOperatorKey,
  getConfiguredStatisticsDetails,
  getReportEmailConditionalOperatorKey,
  isHistoricalStatisticsOperator
} from '../../../utils/report.utils';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import ActivityComponent from '../../../components/report/activity';
import ConfirmationModal from '../../../modals/confirmation';
import { CurrentUserProp } from '../../../types/props/current-user.props';
import EmptyDataComponent from '../../../components/empty-data';
import { Helmet } from 'react-helmet';
import LoadingComponent from '../../../components/loading';
import { PageHeader } from '@ant-design/pro-layout';
import { ReportDataSource } from '../../../enums/report-data-source.enum';
import { ReportDetailStatus } from '../../../enums/status.enum';
import ReportHistoryComponent from '../../../components/report/history';
import ReportRecipientsComponent from '../../../components/report/recipients';
import { ReportRepeatedDays } from '../../../enums/report-repeated-days.enum';
import { ReportService } from '../../../service/report.service';
import { RouteConstants } from '../../../constants/route.constants';
import SecureComponent from '../../../components/authorization';
import { StringConstants } from '../../../constants/string.constants';
import { UserRoles } from '../../../enums/user-role.enum';
import { formatJobTime } from '../../../utils/common.utils';
import isEmpty from 'lodash.isempty';
import moment from 'moment';
import { zoneAbbr } from '../../../utils/moment.utils';

const ReportDetailsPage: React.FC<CurrentUserProp> = (
  props: CurrentUserProp
) => {
  const { reportId } = useParams();
  const navigate = useNavigate();

  const [reportDetail, setReportDetail] = useState<Report>();
  const [loading, setLoading] = useState(true);

  const [deleteReportModalVisible, setDeleteReportModalVisible] =
    useState(false);

  const getReportDetails = (reportId: string) => {
    setLoading(true);
    ReportService.getReportById(reportId)
      .then(report => {
        setReportDetail(report);
        setLoading(false);
      })
      .catch(err => {
        console.log('Error while fetching report', err);
        message.error('Failed to fetch report detail, please try again later');
        setLoading(false);
      });
  };

  // Get Report Details using UseEffect
  useEffect(() => {
    getReportDetails(reportId as string);
  }, [reportId]);

  const handleDeleteReportCancel = () => {
    setDeleteReportModalVisible(false);
  };

  const handleDeleteReportOk = () => {
    setLoading(true);
    ReportService.deleteReport(reportId as string)
      .then(() => {
        message.success('Report deleted successfully');
        setDeleteReportModalVisible(false);
        setLoading(false);
        navigate(RouteConstants.REPORTS);
      })
      .catch(err => {
        console.log('error while deleting report ::', err);
        message.error('Failed to delete report, please try again later');
        setLoading(false);
        handleDeleteReportCancel();
      });
  };

  if (loading) return <LoadingComponent tip='Loading Report...' />;
  else if (reportDetail)
    return (
      <>
        <Helmet>
          <title>
            {reportDetail.name} Report | Analytics Dashboard | Elite Insurance
            Partners
          </title>
          <meta
            name='description'
            content={`${reportDetail.name} | Elite Insurance Partners | Analytics Dashboard - Report`}
          />
        </Helmet>
        <PageHeader
          style={{ backgroundColor: 'white', borderRadius: 6 }}
          onBack={() => navigate(RouteConstants.REPORTS)}
          title={`Report : ${reportDetail.name}`}
          extra={
            props.isAdmin
              ? [
                  <Space key='buttons'>
                    <Button
                      key='edit_report'
                      type='primary'
                      onClick={() => {
                        navigate(
                          RouteConstants.EDIT_REPORT.replace(
                            ':reportId',
                            reportId as string
                          )
                        );
                      }}>
                      <EditOutlined /> Edit Report
                    </Button>
                    <Button
                      key='delete_report'
                      type='primary'
                      danger
                      onClick={() => {
                        setDeleteReportModalVisible(true);
                      }}>
                      <DeleteOutlined /> Delete Report
                    </Button>
                  </Space>
                ]
              : []
          }>
          <Descriptions
            size='small'
            column={{ xxl: 3, xl: 3, lg: 3, md: 3, sm: 1, xs: 1 }}
            className='space-top-10'>
            <Descriptions.Item label='Data Source' span={1}>
              <Typography.Text strong>
                {reportDetail.dataSource}
              </Typography.Text>
            </Descriptions.Item>
            <Descriptions.Item label='Status' span={2}>
              <Tag color={reportDetail.isActive ? 'green' : 'red'}>
                {reportDetail.isActive
                  ? ReportDetailStatus.ACTIVE
                  : ReportDetailStatus.DISABLED}
              </Tag>
            </Descriptions.Item>
            <Descriptions.Item label='Query/URL' span={3}>
              <Typography.Text strong copyable>
                {reportDetail.query}
              </Typography.Text>
            </Descriptions.Item>
          </Descriptions>
        </PageHeader>
        <PageHeader
          style={{
            backgroundColor: 'white',
            borderRadius: 6,
            marginTop: '0.5%'
          }}>
          <Tabs defaultActiveKey='1' className='space-top'>
            <Tabs.TabPane
              tab='Report Details'
              key='1'
              style={{ padding: '1% 3% 0% 3%' }}>
              <Row gutter={{ xs: 16 }} className='space-top-10'>
                <Col xs={24}>
                  {reportDetail.dataSource === ReportDataSource.SNOWFLAKE && (
                    <Descriptions>
                      <Descriptions.Item label='Database'>
                        <Typography.Text strong>
                          {reportDetail.metadata.database}
                        </Typography.Text>
                      </Descriptions.Item>

                      <Descriptions.Item label='Schema'>
                        <Typography.Text strong>
                          {reportDetail.metadata.schema}
                        </Typography.Text>
                      </Descriptions.Item>
                    </Descriptions>
                  )}

                  <Descriptions
                    size='small'
                    column={{ xxl: 5, xl: 4, lg: 4, md: 2, sm: 1, xs: 1 }}
                    className='space-top-10'>
                    <Descriptions.Item label='Frequency' span={1}>
                      <Typography.Text strong>
                        {
                          StringConstants.REPORT_FREQUENCY.find(
                            reportFrequency =>
                              reportFrequency.FREQUENCY ===
                              reportDetail.frequency
                          )?.VALUE
                        }
                      </Typography.Text>
                    </Descriptions.Item>
                    {!['Weekly', 'Monthly', 'Quarterly', 'Yearly'].includes(
                      reportDetail.frequency
                    ) && (
                      <Descriptions.Item label='Repeated Days' span={1}>
                        <Typography.Text strong>
                          {reportDetail.repeatedDays == ReportRepeatedDays.DAILY
                            ? 'Daily'
                            : 'Weekdays'}
                        </Typography.Text>
                      </Descriptions.Item>
                    )}
                    <Descriptions.Item
                      label='Add as attachment in Emails'
                      span={1}>
                      {/* Use Icons */}
                      <Typography.Text strong>
                        {reportDetail.addAsAttachment ? 'Yes' : 'No'}
                      </Typography.Text>
                    </Descriptions.Item>

                    <Descriptions.Item label='Mark email as important' span={1}>
                      <Typography.Text strong>
                        {reportDetail.isImportant ? 'Yes' : 'No'}
                      </Typography.Text>
                    </Descriptions.Item>

                    <Descriptions.Item label='Send e-mail if no data'>
                      <Typography.Text strong>
                        {reportDetail.sendMailIfNoData ? 'Yes' : 'No'}
                      </Typography.Text>
                    </Descriptions.Item>

                    <Descriptions.Item label='Job time' span={1}>
                      <Typography.Text strong>
                        {formatJobTime(
                          reportDetail.frequency,
                          reportDetail.jobStartTime,
                          reportDetail.jobEndTime
                        ) + zoneAbbr}
                      </Typography.Text>
                    </Descriptions.Item>

                    {['Weekly', 'Monthly', 'Quarterly', 'Yearly'].includes(
                      reportDetail.frequency
                    ) && (
                      <Descriptions.Item label='Job Date' span={1}>
                        <Typography.Text strong>
                          {reportDetail.jobDate?.toString()}
                        </Typography.Text>
                      </Descriptions.Item>
                    )}
                  </Descriptions>

                  <Descriptions style={{ marginTop: '2%' }}>
                    {reportDetail.dataSource ===
                      ReportDataSource.SALESFORCE && (
                      <>
                        <Descriptions.Item
                          label='Configured Aggregations'
                          span={3}>
                          {isEmpty(reportDetail.statistics) ? (
                            '-'
                          ) : (
                            <div
                              style={{
                                overflow: 'auto',
                                padding: '0 16px',
                                border: '1px solid rgba(140, 140, 140, 0.35)',
                                width: '100%',
                                marginLeft: '22px'
                              }}>
                              <List
                                itemLayout='horizontal'
                                dataSource={getConfiguredStatisticsDetails(
                                  reportDetail.statistics
                                )}
                                renderItem={(reportStatistics: string) => (
                                  <List.Item>
                                    <Typography.Text strong>
                                      {reportStatistics}
                                    </Typography.Text>
                                  </List.Item>
                                )}
                              />
                            </div>
                          )}
                        </Descriptions.Item>

                        <Descriptions.Item
                          label='Conditional Formatting'
                          span={3}>
                          {isEmpty(reportDetail.conditionalFormattingRules) ? (
                            '-'
                          ) : (
                            <div
                              style={{
                                overflow: 'auto',
                                padding: '0 16px',
                                border: '1px solid rgba(140, 140, 140, 0.35)',
                                width: '100%',
                                marginLeft: '34px'
                              }}>
                              <List
                                itemLayout='horizontal'
                                dataSource={
                                  reportDetail.conditionalFormattingRules
                                }
                                renderItem={(
                                  conditionalFormattingRules: ReportConditionalFormattingRules
                                ) => (
                                  <List.Item
                                    style={{
                                      display: 'flex',
                                      justifyContent: 'start'
                                    }}>
                                    <Typography.Text strong>
                                      {conditionalFormattingRules.column}
                                    </Typography.Text>
                                    :&nbsp;
                                    {conditionalFormattingRules.conditions.map(
                                      (condition, index) => (
                                        <Typography.Text
                                          key={index}
                                          style={{
                                            display: 'flex',
                                            flexDirection: 'row'
                                          }}>
                                          {getConditionalFormattingOperatorKey(
                                            condition.operator
                                          )}
                                          &nbsp;
                                          {condition.value ||
                                            condition.from ||
                                            condition.field}{' '}
                                          - &nbsp;
                                          {condition.to}&nbsp;
                                          <div
                                            key={index}
                                            style={{
                                              height: '20px',
                                              width: '20px',
                                              border: '1px solid black',
                                              clear: 'both',
                                              backgroundColor: condition.colour
                                            }}
                                          />
                                          {index ===
                                          conditionalFormattingRules.conditions
                                            .length -
                                            1
                                            ? ''
                                            : ', '}
                                          &nbsp;
                                        </Typography.Text>
                                      )
                                    )}
                                  </List.Item>
                                )}
                              />
                            </div>
                          )}
                        </Descriptions.Item>

                        <Descriptions.Item
                          label='Configured Email Conditions'
                          span={3}
                          style={{ width: '20%' }}>
                          {isEmpty(reportDetail.emailConditions) ? (
                            '-'
                          ) : (
                            <div
                              style={{
                                overflow: 'auto',
                                padding: '0 16px',
                                border: '1px solid rgba(140, 140, 140, 0.35)',
                                width: '100%'
                              }}>
                              <List
                                itemLayout='horizontal'
                                dataSource={reportDetail.emailConditions}
                                renderItem={(
                                  emailConditions: ReportEmailRules
                                ) => (
                                  <List.Item
                                    style={{
                                      display: 'flex',
                                      justifyContent: 'start'
                                    }}>
                                    <Typography.Text strong>
                                      {emailConditions.column}
                                    </Typography.Text>
                                    :&nbsp;
                                    {emailConditions.conditions.map(
                                      (condition, index) => (
                                        <Typography.Text
                                          key={index}
                                          style={{
                                            display: 'flex',
                                            flexDirection: 'row'
                                          }}>
                                          &nbsp;
                                          {condition.statistics}
                                          &nbsp;
                                          {getReportEmailConditionalOperatorKey(
                                            condition.operator
                                          )}
                                          &nbsp;
                                          {condition.value}
                                          {index ===
                                          emailConditions.conditions.length - 1
                                            ? ''
                                            : ', '}
                                        </Typography.Text>
                                      )
                                    )}
                                  </List.Item>
                                )}
                              />
                            </div>
                          )}
                        </Descriptions.Item>
                      </>
                    )}
                  </Descriptions>

                  <Descriptions
                    size='small'
                    column={{ xxl: 5, xl: 4, lg: 4, md: 2, sm: 1, xs: 1 }}
                    className='space-top-10'>
                    {reportDetail.dataSource ===
                      ReportDataSource.SALESFORCE && (
                      <>
                        {StringConstants.REPORT_FREQUENCY.filter(
                          reportFreq =>
                            ![
                              'Weekly',
                              'Monthly',
                              'Quarterly',
                              'Yearly'
                            ].includes(reportFreq.FREQUENCY)
                        ).some(
                          reportFrequency =>
                            reportFrequency.FREQUENCY === reportDetail.frequency
                        ) &&
                          !isEmpty(reportDetail.statistics) &&
                          reportDetail.statistics
                            .map(stat => stat.operator)
                            .some(oper =>
                              isHistoricalStatisticsOperator(oper)
                            ) && (
                            <Descriptions.Item
                              label='Show Historical Reports'
                              span={2}>
                              <Typography.Text strong>
                                {reportDetail.showHistoricalStatistics
                                  ? 'Yes'
                                  : 'No'}
                              </Typography.Text>
                            </Descriptions.Item>
                          )}

                        {reportDetail.showHistoricalStatistics && (
                          <>
                            {reportDetail.historicalStatistics && (
                              <Descriptions.Item
                                label='Historical report statistics'
                                span={2}>
                                <Typography.Text strong>
                                  {reportDetail.historicalStatistics.join(', ')}
                                </Typography.Text>
                              </Descriptions.Item>
                            )}

                            {reportDetail.historicalStatisticsTypes && (
                              <Descriptions.Item
                                label='Type of historical report'
                                span={2}>
                                <Typography.Text strong>
                                  {reportDetail.historicalStatisticsTypes
                                    .map(types => types.replaceAll('_', ' '))
                                    .join(', ')}
                                </Typography.Text>
                              </Descriptions.Item>
                            )}

                            {reportDetail.noOfPreviousDaysReportsToUse && (
                              <Descriptions.Item
                                label='Number of days of historical reports'
                                span={2}>
                                <Typography.Text strong>
                                  {reportDetail.noOfPreviousDaysReportsToUse.join(
                                    ', '
                                  )}
                                </Typography.Text>
                              </Descriptions.Item>
                            )}

                            {reportDetail.exclusionDates && (
                              <Descriptions.Item
                                label='Exclusion dates (EST)'
                                span={2}>
                                <Typography.Text strong>
                                  {isEmpty(reportDetail.exclusionDates)
                                    ? 'None'
                                    : reportDetail.exclusionDates
                                        .map(date =>
                                          moment(date).format('YYYY-MM-DD')
                                        )
                                        .join(', ')}
                                </Typography.Text>
                              </Descriptions.Item>
                            )}
                          </>
                        )}
                      </>
                    )}
                  </Descriptions>
                </Col>
              </Row>
            </Tabs.TabPane>
            <Tabs.TabPane tab='Report History' key='2'>
              <Row gutter={16} className='space-top-10'>
                <Col xs={24}>
                  {/* Render Table of Report runs */}
                  <ReportHistoryComponent
                    reportId={reportId as string}
                    minimal={true}
                  />
                </Col>
              </Row>
            </Tabs.TabPane>
            <Tabs.TabPane tab='Associated Recipients' key='3'>
              <Row gutter={16} className='space-top-10'>
                <Col xs={24}>
                  {/* Render Table of Report runs */}
                  <ReportRecipientsComponent
                    reportId={reportId as string}
                    isAdmin={props.isAdmin ? UserRoles.ADMIN : UserRoles.VIEWER}
                  />
                </Col>
              </Row>
            </Tabs.TabPane>
            <Tabs.TabPane tab='Activity' key='4'>
              <Row gutter={16} className='space-top-10'>
                <Col xs={24}>
                  <ActivityComponent
                    numericColumn={reportDetail.numericColumns as any}
                    displayname={reportDetail.displayNames as any}
                    widgetId={reportDetail.widgets as any}
                    isAdmin={props.isAdmin}
                  />
                </Col>
              </Row>
            </Tabs.TabPane>
          </Tabs>
        </PageHeader>

        {/* Delete Report Confirmation */}
        <ConfirmationModal
          title={`Are you sure to delete report - ${reportDetail.name} ?`}
          visible={deleteReportModalVisible}
          danger={true}
          handleOk={handleDeleteReportOk}
          handleCancel={handleDeleteReportCancel}
          content={`This report and all its associated data would be deleted permanently.`}
        />
      </>
    );
  // Empty Component
  else
    return (
      <EmptyDataComponent
        title={`Report Not Found`}
        subTitle='Sorry, the report you are looking for might have been deleted'
        navigateTo={RouteConstants.REPORTS}
      />
    );
};

export default SecureComponent(ReportDetailsPage, false);
