import { Report, ReportStatistics } from '../types/data/report.type';
import { capitalize, cloneDeep, differenceWith, isEqual } from 'lodash';

import { RegExConstants } from '../constants/regex.constants';
import { ReportConditionalFormattingOperator } from '../enums/report-conditional-formatting-operator.enum';
import { ReportDataSource } from '../enums/report-data-source.enum';
import { ReportEmailConditionalOperator } from '../enums/report-email-conditional-operator.enum';
import { ReportHistoricalStatisticsType } from '../enums/report-historical-statistics.enum';
import { ReportHistory } from '../types/data/report-history.type';
import { ReportStatisticsOperator } from '../enums/report-statistics-operator.enum';

/**
 * To access key by value
 * @param {ReportEmailConditionalOperator } - operator
 */
export const getReportEmailConditionalOperatorKey = (
  operator: ReportEmailConditionalOperator
) => {
  const indexOfValue = Object.values(ReportEmailConditionalOperator).indexOf(
    operator as unknown as ReportEmailConditionalOperator
  );
  const key = Object.keys(ReportEmailConditionalOperator)[indexOfValue];
  return key;
};

/**
 * To access key by value
 * @param { ReportConditionalFormattingOperator} - operator
 */
export const getConditionalFormattingOperatorKey = (
  operator: ReportConditionalFormattingOperator
) => {
  const indexOfValue = Object.values(
    ReportConditionalFormattingOperator
  ).indexOf(operator as unknown as ReportConditionalFormattingOperator);
  const key = Object.keys(ReportConditionalFormattingOperator)[indexOfValue];
  return key;
};

/**
 * To format configured statistics data for reports results/history page
 * @param {ReportStatistics[] } - statistics object
 */
export const getConfiguredStatisticsDetails = (
  reportStatistics: ReportStatistics[]
): string[] => {
  const formattedStatistics: string[] = [];
  let statistics = 'Statistics - ';
  let subTotals: any = 'Sub Total - ';
  let subAverage: any = 'Sub Average - ';
  const statsOperators: ReportStatisticsOperator[] = [];

  reportStatistics.forEach(stat => {
    if (stat.operator === ReportStatisticsOperator.SUB_TOTAL) {
      subTotals = subTotals.concat(stat.baseColumn + ', ');
      statsOperators.push(stat.operator);
    } else if (stat.operator === ReportStatisticsOperator.SUB_AVERAGE) {
      subAverage = subAverage.concat(stat.baseColumn + ', ');
      statsOperators.push(stat.operator);
    } else {
      statistics = statistics.concat(stat.operator + ', ');
      statsOperators.push(stat.operator);
    }
  });

  //slicing the string as it contains ', ' at last
  if (
    statsOperators.includes(
      ReportStatisticsOperator.TOTAL ||
        ReportStatisticsOperator.MAXIMUM ||
        ReportStatisticsOperator.MINIMUM ||
        ReportStatisticsOperator.AVERAGE
    )
  )
    formattedStatistics.push(statistics.slice(0, -2));
  if (statsOperators.includes(ReportStatisticsOperator.SUB_TOTAL))
    formattedStatistics.push(subTotals.slice(0, -2));
  if (statsOperators.includes(ReportStatisticsOperator.SUB_AVERAGE))
    formattedStatistics.push(subAverage.slice(0, -2));

  return formattedStatistics;
};

/**
 * match characters between 'select' and 'from'
 * @param {any} queryValue - queryByvalue
 */
export const getQueryColumns = (queryValue: any) => {
  // flags i -> case insensitive, g -> global, s -> ignores white spaces (space, new line)
  let columns = [];
  if (queryValue) {
    const columnString = queryValue.match(RegExConstants.QUERY_COLUMN);
    if (columnString) {
      columns = columnString[0]?.split(','); // split the column string
      // replace(/\s/g, '') //replace all white spaces in the matched string
    }
    for (let i = 0; i < columns.length; i++) {
      const split = columns[i].trim().split(' '); //replace all white spaces in the matched string and split by space to find alias name
      if (split.length === 2)
        columns[i] = split[1].trim(); //Alias name available
      else columns[i] = split[0].trim(); //No Alias name for column, pick original column name from query
    }
  }
  return columns;
};

/**
 * To check if the previewed report matches with the updated data
 * @param {ReportHistory } - previewRequest
 * @param {any} - updateReportRequest
 */
export const storePreviewResult = (
  previewRequest: ReportHistory,
  updateReportRequest: any
) => {
  if (updateReportRequest['dataSource'] === ReportDataSource.SALESFORCE) {
    const previewRequestStatistics: string[] =
      previewRequest.data.report.statistics;

    const updateReportRequestStatistics: string[] =
      updateReportRequest.statistics;

    previewRequestStatistics.forEach(stat => delete stat['columns']);

    updateReportRequestStatistics.forEach(stat => delete stat['columns']);

    const updateReportRequest_ = cloneDeep(updateReportRequest);
    const displayNames = previewRequest.data.report.displayNames;

    updateReportRequest_.conditionalFormattingRules.map((condition: any) => {
      condition.column = displayNames[condition.column];
    });

    const conditionalFormattingDifference = differenceWith(
      previewRequest.data.report.conditionalFormattingRules,
      updateReportRequest_.conditionalFormattingRules,
      isEqual
    );
    const statisticsDifference = differenceWith(
      previewRequestStatistics,
      updateReportRequestStatistics,
      isEqual
    );

    if (
      previewRequest.data.report.query == updateReportRequest.query &&
      conditionalFormattingDifference.length == 0 &&
      statisticsDifference.length == 0
    ) {
      return true;
    } else {
      return false;
    }
  } else {
    if (previewRequest.data.report.query === updateReportRequest.query) {
      return true;
    } else return false;
  }
};

/**
 * Util function to get content for popover in report result table
 * @param {string} columnName - column name
 * @param {string} colorApplied - color for that column sent by backend
 * @param {Report} report - report detail
 * @returns {string} - content for popover
 */
export const getReportResultCellPopoverContent = (
  columnName: string,
  colorApplied: string,
  report: Report
): string => {
  // Find salesforce query name for the given column name
  const sfName = Object.keys(report.displayNames).find(
    sfName_ => report.displayNames[sfName_] === columnName
  );

  // Find the formats applied for the given column
  const conditionalFormatForColumn = report.conditionalFormattingRules.find(
    rule => rule.column === sfName
  );

  // Find the applied condition based on the given color
  const appliedCondition = conditionalFormatForColumn?.conditions.find(
    condition => condition.colour === colorApplied
  );

  return appliedCondition
    ? `Highlighted since value ${getConditionalFormattingOperatorKey(
        appliedCondition.operator
      ).toLowerCase()} ${
        [
          ReportConditionalFormattingOperator['Is null'],
          ReportConditionalFormattingOperator['Is not null']
        ].includes(appliedCondition.operator)
          ? ''
          : appliedCondition.value ||
            report.displayNames[appliedCondition.field] ||
            `${appliedCondition.from} and ${appliedCondition.to}`
      }`
    : '';
};

/**
 * Util function to get initial cell content regarding historical summary detail
 * @param {ReportHistoricalStatisticsType} statsType - historical statistics type
 * @param {ReportStatisticsOperator} statsOperator - historical statistics operator
 * @param {number} noOfDays - number of days for which the historical statistics was generated
 * @returns {string} - historical Summary detail content
 */
export const generateHistSummaryDetailInitialCellContent = (
  statsType: ReportHistoricalStatisticsType,
  statsOperator: ReportStatisticsOperator,
  noOfDays: number
): string => {
  return (
    'Average of ' +
    capitalize(statsType.replace(/_/g, ' ')) +
    ' ' +
    capitalize(statsOperator) +
    's for ' +
    noOfDays +
    ' days'
  );
};

/**
 * Util function to check if the given string or ReportStatisticsOperator is a historical statistics operator
 * @param {ReportStatisticsOperator|string} operator - historical statistics operator or a string
 * @returns {boolean} - status indicates if the operator is a historical statistics operator or not
 */
export const isHistoricalStatisticsOperator = (
  operator: ReportStatisticsOperator | string
): boolean => {
  if (
    operator === ReportStatisticsOperator.AVERAGE ||
    operator === ReportStatisticsOperator.TOTAL
  )
    return true;
  else return false;
};
