import './App.css';

// import 'antd/dist/antd.css';


import './interceptors/response.interceptor';

import {
  Document,
  GroupDetails,
  Groups,
  Login,
  Redirect,
  ReportDetails,
  ReportResult,
  Reports,
  Users
} from './pages';
import { Route, Routes } from 'react-router-dom';

import CreateOrEditReport from './pages/reports/create-or-edit-report';
import React from 'react';
import { RouteConstants } from './constants/route.constants';

const App = () => {
  return (
    <Routes>
      <Route path={RouteConstants.HOME} element={<Login />} />
      <Route path={RouteConstants.LOGIN} element={<Login />} />
      <Route path={RouteConstants.REDIRECT} element={<Redirect />} />
      <Route path={RouteConstants.REPORTS} element={<Reports />} />
      <Route path={RouteConstants.REPORT_DETAILS} element={<ReportDetails />} />
      <Route path={RouteConstants.REPORT_RESULT} element={<ReportResult />} />
      <Route path={RouteConstants.GROUPS} element={<Groups />} />
      <Route path={RouteConstants.GROUP_DETAILS} element={<GroupDetails />} />
      <Route path={RouteConstants.USERS} element={<Users />} />
      <Route path={RouteConstants.DOCUMENT} element={<Document />} />
      <Route
        path={RouteConstants.CREATE_REPORT}
        element={<CreateOrEditReport />}
      />
      <Route
        path={RouteConstants.EDIT_REPORT}
        element={<CreateOrEditReport />}
      />
    </Routes>
  );
};

export default App;
