export const StringConstants = {
  COOKIE: {
    JWT: 'jwt',
    USER: 'user'
  },
  REPORT_FREQUENCY: [
    {
      FREQUENCY: '5',
      VALUE: 'Every 5 minutes'
    },
    {
      FREQUENCY: '15',
      VALUE: 'Every 15 minutes'
    },
    {
      FREQUENCY: '30',
      VALUE: 'Every 30 minutes'
    },
    {
      FREQUENCY: '60',
      VALUE: 'Every 60 minutes'
    },
    {
      FREQUENCY: '1440',
      VALUE: 'Once a day'
    },
    {
      FREQUENCY: 'Weekly',
      VALUE: 'Weekly'
    },
    {
      FREQUENCY: 'Monthly',
      VALUE: 'Monthly'
    },
    {
      FREQUENCY: 'Quarterly',
      VALUE: 'Quarterly'
    },
    {
      FREQUENCY: 'Yearly',
      VALUE: 'Yearly'
    }
  ]
};
