import { Bar, Doughnut, Line, Pie } from 'react-chartjs-2';
import React, { useEffect, useState } from 'react';

import { CategoryScale } from 'chart.js';
import ChartJS from 'chart.js/auto';
import { Empty } from 'antd';
import EmptyDataComponent from '../../../components/empty-data';
import annotationPlugin from 'chartjs-plugin-annotation';
import { isEmpty } from 'lodash';

ChartJS.register(CategoryScale);
ChartJS.register(annotationPlugin);

// Function to download the chart as an image
type chartProps = {
  dataset: any;
  chartType: any;
  download?: boolean;
  handleDownload?: any;
};

const ChartComponent: React.FC<chartProps> = (props: chartProps) => {
  const stacked_bar = {
    options: {
      responsive: true,
      scales: {
        x: {
          stacked: true
        },
        y: {
          stacked: true
        }
      }
    }
  };

  const doughnutOptions = {
    options: {
      responsive: true,
      maintainAspectRatio: true,
      aspectRatio: 2,
      plugins: {
        legend: {
          display: true
          // position: 'top'
        },
        title: {
          display: true,
          text: 'Doughnut Chart',
          padding: {
            top: 10,
            bottom: 20
          }
        }
      },
      elements: {
        arc: {
          borderWidth: 10 // Set the border width to 0 to remove the border
        }
      },
      cutout: '50%' // Adjust the cutout value to control the size of the doughnut (percentage of radius)
    }
  };

  const pieOptions = {
    options: {
      responsive: true,
      maintainAspectRatio: true,
      aspectRatio: 2,
      plugins: {
        legend: {
          display: true
        },
        title: {
          display: true,
          text: 'Pie Chart',
          padding: {
            top: 10,
            bottom: 20
          }
        }
      }
    }
  };

  const areaOptions = {
    options: {
      plugins: {
        title: {
          display: true
        },
        zoom: {
          pan: {
            enabled: true,
            mode: 'xy'
          },
          zoom: {
            enabled: true,
            mode: 'xy'
          }
        }
      },
      scales: {
        x: {
          display: true
        },
        y: {
          display: true,
          beginAtZero: true
        }
      },
      elements: {
        line: {
          tension: 0.4 // Adjust the curve of the line
        },
        area: {
          fill: '-1',
          borderColor: 'rgba(0, 0, 0, 0)', // transparent border color
          borderWidth: 1
        }
      },
      pointBackgroundColor: '#fff',
      radius: 10,
      interaction: {
        intersect: false
      }
    }
  };

  const [chartType, setChartType] = useState('BAR');

  useEffect(() => {
    setChartType(props?.chartType ? props?.chartType : chartType);
  }, []);

  const renderChart = () => {
    let pieData = { labels: [], datasets: [] as any };
    let updatedData = { data: {} as any };
    if (props.chartType == 'PIE' || props.chartType == 'DOUGHNUT') {
      pieData = {
        labels: props.dataset.data.datasets.map(
          (dataset: any) => dataset.label
        ),
        datasets: [
          {
            data: props.dataset.data.datasets.map((dataset: any) =>
              dataset.data.reduce((a: any, b: any) => a + b, 0)
            ),
            backgroundColor: props.dataset.data.datasets.map(
              (dataset: any) => dataset.backgroundColor
            ),
            borderColor: props.dataset.data.datasets.map(
              (dataset: any) => dataset.borderColor
            ),
            borderWidth: 1
          }
        ]
      };
    }

    if (props.chartType === 'AREA') {
      updatedData = {
        ...props.dataset.data,
        data: {
          ...props.dataset.data,
          datasets: props.dataset.data.datasets.map((dataset: any) => ({
            ...dataset,
            borderWidth: 2,
            fill: true
          }))
        }
      };
    }

    if (!isEmpty(props.dataset)) {
      switch (props.chartType) {
        case 'BAR':
          return <Bar data={props.dataset.data} id='bar' key='vertical_bar' />;

        case 'STACKED_BAR':
          return (
            <Bar
              data={props.dataset.data}
              options={stacked_bar.options}
              key='stacked_bar'
              id='stacked_bar'
            />
          );

        case 'HORIZONTAL_BAR':
          return (
            <Bar
              data={props.dataset.data}
              options={{
                indexAxis: 'y'
              }}
              key='horizontal_bar'
              id='stacked_bar'
            />
          );

        case 'LINE':
          return <Line data={props.dataset.data} id='stacked_bar' key='line' />;

        case 'AREA':
          return (
            <Line
              data={updatedData.data}
              options={areaOptions.options}
              id='area'
              key='area'
            />
          );

        case 'PIE':
          return (
            <Pie
              data={pieData}
              options={pieOptions.options}
              id='pie'
              key='pie'
            />
          );
        case 'DOUGHNUT':
          return (
            <Doughnut
              data={pieData}
              options={doughnutOptions.options}
              id='doughnut'
              key='doughnut'
            />
          );
        default:
          return <Empty />;
      }
    } else <EmptyDataComponent title='No data available' />;
  };

  return (
    <div
      style={{
        display: 'flex'
      }}>
      {renderChart()}
    </div>
  );
};

export default ChartComponent;
