import {
  AccountInfo,
  AuthenticationResult,
  SilentRequest
} from '@azure/msal-browser';
import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
  useMsal
} from '@azure/msal-react';
import { Button, Result } from 'antd';
import React, { useEffect, useState } from 'react';
import {
  getTokenForBrowser,
  handleLogout,
  parseUserAndValidateRoles,
  saveCookie
} from '../../utils/auth.utils';

import { CurrentUserProp } from '../../types/props/current-user.props';
import { Layout } from '../../pages';
import { RouteConstants } from '../../constants/route.constants';
import { useNavigate } from 'react-router';

export default (Page: React.ComponentType<any>, isAdmin = false) => {
  const SecureComponent = (props: any) => {
    const [isUserAuthorized, setIsUserAuthorized] = useState(true);
    const [userDetails, setUserDetails] = useState<CurrentUserProp>({
      isAdmin: false,
      oid: ''
    });
    const navigate = useNavigate();

    const { instance } = useMsal();

    // get id token for the active account
    const acquireToken = async (): Promise<void> => {
      return new Promise(resolve => {
        const account: AccountInfo | null = instance.getActiveAccount();
        if (account)
          instance
            .acquireTokenSilent((account ? { account } : {}) as SilentRequest)
            .then((tokenResponse: AuthenticationResult) => {
              saveCookie(tokenResponse.idToken);
              resolve();
            })
            .catch(error => {
              console.log(
                'error while fetching token secure component :: ',
                error
              );
              navigate(RouteConstants.LOGIN);
            });
        else {
          navigate(RouteConstants.LOGIN);
        }
      });
    };
    // get user from cookie and authorize user
    const validateAccessToken = async (): Promise<void> => {
      const loggedInUser: string | undefined | false =
        await getTokenForBrowser();

      // if user token is not in cookie
      // acquire new token and get logged in user
      if (!loggedInUser) {
        console.log('invalid jwt token, acquiring new token');
        await acquireToken();
        return validateAccessToken();
      } else {
        const parsedUser: any = parseUserAndValidateRoles(loggedInUser);
        if (parsedUser) {
          // get user's role, and authorize
          const userRoles: string[] = parsedUser.roles;
          console.log('Token valid: User roles :: ', userRoles);

          userRoles.includes(
            process.env.REACT_APP_AZURE_ADMIN_ROLE as string
          ) && setUserDetails({ isAdmin: true, oid: parsedUser.oid });

          // if page accessible only by admin and user is not an Admin eg: ['View']
          isAdmin &&
            !userRoles.includes(
              process.env.REACT_APP_AZURE_ADMIN_ROLE as string
            ) &&
            setIsUserAuthorized(false);
        } else {
          handleLogout(instance);
        }
      }
    };

    useEffect(() => {
      validateAccessToken();
    }, []);

    if (!isUserAuthorized) {
      return (
        <Result
          status={'warning'}
          title='Unauthorized access'
          extra={[
            <Button
              type='primary'
              key='logout'
              onClick={() => handleLogout(instance)}>
              Logout
            </Button>
          ]}
        />
      );
    }

    return (
      <>
        <AuthenticatedTemplate>
          <Layout isAdmin={userDetails.isAdmin} oid={userDetails.oid}>
            <Page
              {...props}
              isAdmin={userDetails.isAdmin}
              oid={userDetails.oid}
            />
          </Layout>
        </AuthenticatedTemplate>
        <UnauthenticatedTemplate>
          <Result
            status={'error'}
            title="You're not signed in"
            extra={[
              <Button
                key='login'
                type='primary'
                onClick={() => navigate(RouteConstants.LOGIN)}>
                Login
              </Button>
            ]}
          />
        </UnauthenticatedTemplate>
      </>
    );
  };

  return SecureComponent;
};
