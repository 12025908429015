export enum ReportConditionalFormattingOperator {
  'Greater than' = '>',
  'Less than' = '<',
  'Greater than or equal to' = '>=',
  'Less than or equal to' = '<=',
  'Is equal to' = '==',
  'Is not equal to' = '!==',
  'Is null' = '<>',
  'Is not null' = '!<>',
  'Is between' = 'btw',
  'Is not between' = '!btw'
}
