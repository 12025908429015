import { ApiUtils } from '../utils/api.utils';
import { UrlConstants } from '../constants/url.constants';
import axios from 'axios';

const createGroup = async (data: any): Promise<any> => {
  return axios.post(
    UrlConstants.CREATE_GROUP,
    ApiUtils.cleanEmptyString(data),
    ApiUtils.getHeaders()
  );
};

const getAllGroups = (params?: any): Promise<any> => {
  return new Promise((resolve, reject) => {
    const url: string = ApiUtils.addQueryParamsToUrl(
      UrlConstants.GET_ALL_GROUPS,
      params
    );
    axios
      .get(url, ApiUtils.getHeaders())
      .then(response => {
        if (response.status === 200) resolve(response.data);
        else throw response;
      })
      .catch(err => reject(err));
  });
};

const getGroupByID = (groupId: string): Promise<any> => {
  return new Promise((resolve, reject) => {
    const url = UrlConstants.GET_GROUP_BY_ID.replaceAll(':groupId', groupId);
    axios
      .get(url, ApiUtils.getHeaders())
      .then(response => {
        if (response.status === 200) resolve(response.data.data);
        else throw response;
      })
      .catch(err => reject(err));
  });
};

const updateGroup = (groupId: string, data: any): Promise<any> => {
  const url = UrlConstants.UPDATE_GROUP_BY_ID.replaceAll(':groupId', groupId);
  return axios.patch(
    url,
    ApiUtils.cleanEmptyString(data),
    ApiUtils.getHeaders()
  );
};

const deleteGroup = (groupId: string): Promise<any> => {
  const url = UrlConstants.DELETE_GROUP_BY_ID.replaceAll(':groupId', groupId);
  return axios.delete(url, ApiUtils.getHeaders());
};

const addUsersToGroup = (groupId: string, data: any): Promise<any> => {
  const url = UrlConstants.ADD_USERS_TO_GROUP.replaceAll(':groupId', groupId);
  return axios.patch(url, data, ApiUtils.getHeaders());
};

const removeUsersFromGroup = (groupId: string, data: any): Promise<any> => {
  const url = UrlConstants.REMOVE_USERS_FROM_GROUP.replaceAll(
    ':groupId',
    groupId
  );
  return axios.patch(url, data, ApiUtils.getHeaders());
};

export const GroupService = {
  createGroup,
  getAllGroups,
  getGroupByID,
  updateGroup,
  deleteGroup,
  addUsersToGroup,
  removeUsersFromGroup
};
