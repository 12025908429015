import {
  Button,
  Card,
  Col,
  Empty,
  Row,
  Space,
  Tag,
  Timeline,
  Typography,
  message
} from 'antd';
import {
  ReportRunStatisticsEffect,
  ReportStatisticsOverview
} from '../../../types/data/report-activities.types';
import { formatDate, zoneAbbr } from '../../../utils/moment.utils';
import { useEffect, useState } from 'react';

import CreateOrEditWidget from '../../../pages/charts/create-or-edit-chart';
import { MomentConstants } from '../../../constants/moment.constants';
import { ReportConstants } from '../../../constants/report.constants';
import { ReportService } from '../../../service/report.service';
import SavedCharts from '../../../pages/charts/saved-charts';
import emptyBox from '../../../assets/images/empty.svg';
import isEmpty from 'lodash.isempty';
import { useParams } from 'react-router-dom';

const { Meta } = Card;

type DropdownProp = {
  numericColumn: any;
  displayname: any;
  widgetId: any;
  isAdmin: boolean;
};

const ActivityComponent: React.FC<DropdownProp> = (props: DropdownProp) => {
  const { reportId } = useParams();

  const [auditData, setAuditData] = useState<
    { action: string; actionAt: string }[]
  >([]);

  const [auditDataLoading, setAuditDataLoading] = useState(true);
  const [widgetId, setWidgetId] = useState(props.widgetId);

  const [reportStatsOverviewLoading, setReportStatsOverviewLoading] =
    useState(true);
  const [reportStatsOverview, setReportStatsOverview] = useState<
    ReportStatisticsOverview | undefined
  >(undefined);

  const [showEmptyData, setShowEmptyData] = useState(false);

  const getReportStatisticsOverview = (reportId: string) => {
    setReportStatsOverviewLoading(true);
    ReportService.getReportStatisticsOverview(reportId)
      .then(reportStatsOverview => {
        setReportStatsOverview(reportStatsOverview);
        setReportStatsOverviewLoading(false);
      })
      .catch(err => {
        console.log('Error while fetching report stats overview', err);
        message.error(
          'Failed to fetch report stats overview, please try again later'
        );
        setReportStatsOverviewLoading(false);
      });
  };

  const getReportAuditStatus = (reportId: string) => {
    setAuditDataLoading(true);
    ReportService.getReportStatusById(reportId)
      .then(statusData => {
        setAuditData(statusData);
        setAuditDataLoading(false);
      })
      .catch(err => {
        console.log('Error While Fetching Report Status :: ', err);
        message.error('Failed to fetch report status, please try again later');
        setAuditDataLoading(false);
      });
  };

  const handleAddWidget = () => {
    setShowEmptyData(true);
  };

  const handleCancel = () => {
    setShowEmptyData(false);
  };

  const handleReload = () => {
    setShowEmptyData(false);
    setWidgetId(['']);
  };

  useEffect(() => {
    getReportStatisticsOverview(reportId as string);
    getReportAuditStatus(reportId as string);
  }, [reportId]);

  /**
   * Get description for the Card meta data based on the statistics
   * @param statistics
   */
  const getMetaDescription = (
    statType: 'TotalRuns' | 'SuccessfulRuns' | 'FailedRuns' | 'NoDataReports',
    statistics: {
      effect: ReportRunStatisticsEffect;
      by: string;
    }
  ) => {
    const metaTagDetails =
      ReportConstants.ReportStatisticsOverviewMetaDetails[
        `${statType},${statistics.effect}`
      ];

    return (
      <Typography.Text type={metaTagDetails.textType}>
        <Tag color={metaTagDetails.tagColor} icon={metaTagDetails.tagIcon}>
          {metaTagDetails.tagContent
            ? metaTagDetails.tagContent
            : statistics.by}
        </Tag>
        vs Previous 7 days
      </Typography.Text>
    );
  };

  return (
    <Row gutter={16}>
      <Col span={18}>
        <Space direction='vertical' style={{ display: 'flex' }}>
          <Row>
            <Col span={6}>
              <Typography.Title level={5}>Last 7 day Runs</Typography.Title>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={6}>
              <Card
                title='Total Runs'
                loading={reportStatsOverviewLoading}
                headStyle={{ color: 'gray' }}
                style={{ height: '100%' }}>
                <Meta
                  title={reportStatsOverview?.totalReportRuns.count.toString()}
                  description={
                    reportStatsOverview?.totalReportRuns.statistics
                      ? getMetaDescription(
                          'TotalRuns',
                          reportStatsOverview?.totalReportRuns.statistics
                        )
                      : ''
                  }
                />
              </Card>
            </Col>
            <Col span={6}>
              <Card
                title='Successful Runs'
                loading={reportStatsOverviewLoading}
                headStyle={{ color: 'gray' }}>
                <Meta
                  title={reportStatsOverview?.successfulReportRuns.count.toString()}
                  description={
                    reportStatsOverview?.successfulReportRuns.statistics
                      ? getMetaDescription(
                          'SuccessfulRuns',
                          reportStatsOverview?.successfulReportRuns.statistics
                        )
                      : ''
                  }
                />
              </Card>
            </Col>
            <Col span={6}>
              <Card
                title='Failed Runs'
                headStyle={{ color: 'gray' }}
                loading={reportStatsOverviewLoading}>
                <Meta
                  title={reportStatsOverview?.failedReportRuns.count.toString()}
                  description={
                    reportStatsOverview?.failedReportRuns.statistics
                      ? getMetaDescription(
                          'FailedRuns',
                          reportStatsOverview?.failedReportRuns.statistics
                        )
                      : ''
                  }
                />
              </Card>
            </Col>
            <Col span={6}>
              <Card
                title='No Data Reports'
                headStyle={{ color: 'gray' }}
                loading={reportStatsOverviewLoading}>
                <Meta
                  title={reportStatsOverview?.noDataReports.count.toString()}
                  description={
                    reportStatsOverview?.noDataReports.statistics
                      ? getMetaDescription(
                          'NoDataReports',
                          reportStatsOverview?.noDataReports.statistics
                        )
                      : ''
                  }
                />
              </Card>
            </Col>
          </Row>
          <Row>
            <Col span={6}>
              <Typography.Title level={5}>Widgets</Typography.Title>
            </Col>
            {props.isAdmin && (
              <Col
                span={6}
                offset={12}
                style={{ display: 'flex', justifyContent: 'flex-end' }}>
                <Button onClick={handleAddWidget}>+ Add Widget</Button>
              </Col>
            )}
          </Row>
          <Row>
            <Col span={24}>
              <Card>
                {isEmpty(widgetId) || showEmptyData ? (
                  <Empty
                    image={emptyBox}
                    imageStyle={{ height: '129px' }}
                    description={
                      <span>
                        Visualize your Report data with Multiple widgets
                      </span>
                    }>
                    <>
                      {!showEmptyData ? (
                        props.isAdmin && (
                          <Button
                            type='primary'
                            disabled={false}
                            onClick={handleAddWidget}>
                            Add your first widget
                          </Button>
                        )
                      ) : (
                        <CreateOrEditWidget
                          handleCancel={handleCancel}
                          numericColumns={props.numericColumn}
                          displayNames={props.displayname}
                          reportId={reportId}
                          reloadData={handleReload}
                        />
                      )}
                    </>
                  </Empty>
                ) : (
                  <>
                    <SavedCharts
                      widgetId={props.widgetId}
                      reportId={reportId}
                      numericColumns={props.numericColumn}
                      displayNames={props.displayname}
                      isAdmin={props.isAdmin}
                    />
                  </>
                )}
              </Card>
            </Col>
          </Row>
        </Space>
      </Col>
      <Col span={6}>
        <Card title='Status' loading={auditDataLoading}>
          <div
            style={{
              overflow: 'auto',
              height: '500px',
              paddingTop: '2px'
            }}>
            <Timeline>
              {auditData?.map((item, index) => (
                <Timeline.Item key={index} style={{ paddingRight: '10px' }}>
                  <p dangerouslySetInnerHTML={{ __html: item.action }}></p>
                  <p style={{ color: '#1890FF', marginTop: '-10px' }}>
                    {' '}
                    {formatDate(
                      item.actionAt,
                      MomentConstants.DATE_TIME_LOCALE_FORMAT
                    )}{' '}
                    {zoneAbbr}
                  </p>
                </Timeline.Item>
              ))}
            </Timeline>
          </div>
        </Card>
      </Col>
    </Row>
  );
};

export default ActivityComponent;
