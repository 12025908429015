import { Group } from './group.type';
import { User } from './user.type';

export type ReportRecipients = {
  name: string;
  groups: Group[];
  users: User[];
};

export const InitialValues = {
  name: '',
  groups: [],
  users: []
};
