import { Button, Result } from 'antd';

import React from 'react';
import { RouteConstants } from '../../constants/route.constants';
import { useNavigate } from 'react-router';

type EmptyDataProp = {
  title: string;
  subTitle?: string;
  navigateTo?: typeof RouteConstants[keyof typeof RouteConstants];
};

const EmptyDataComponent: React.FC<EmptyDataProp> = (props: EmptyDataProp) => {
  const navigate = useNavigate();
  return (
    <Result
      status='404'
      title={props.title}
      subTitle={
        props.subTitle
          ? props.subTitle
          : 'Sorry, the page you visited does not exist.'
      }
      extra={
        props.navigateTo ? (
          <Button
            type='default'
            onClick={() => navigate(props.navigateTo as string)}>
            Go Back
          </Button>
        ) : (
          <></>
        )
      }
    />
  );
};

export default EmptyDataComponent;
