import { Button, Layout } from 'antd';

import { useMsal } from '@azure/msal-react';
import { version } from '../../../package.json';

const Footer: React.FC = () => {
  const { instance } = useMsal();
  return (
    <Layout.Footer className='footer'>
      <p>
        EIP Analytical Dashboard ©{new Date().getFullYear()} v{version}
      </p>
      <Button
        type='link'
        onClick={() => {
          instance.logoutRedirect();
        }}>
        Logout
      </Button>
    </Layout.Footer>
  );
};

export default Footer;
