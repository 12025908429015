import { Button, Form, FormInstance, Input, Modal, Select } from 'antd';
import { RefObject, createRef } from 'react';

import { UserRoles } from '../../../enums/user-role.enum';
import capitalize from 'lodash.capitalize';

type AddUserModalProps = {
  loading: boolean;
  visible: boolean;
  handleOk: (data: any) => void;
  handleCancel: (data: any) => void;
};

const AddUserModal = (props: AddUserModalProps) => {
  const createModalRef: RefObject<FormInstance> = createRef<FormInstance>();
  const [form] = Form.useForm();

  const clearFormData = () => {
    form.resetFields();
  };

  const handleFormSubmit = () => {
    createModalRef.current
      ?.validateFields()
      .then((data: any) => {
        props.handleOk(data);
        createModalRef.current?.setFieldsValue(data);
      })
      .catch((err: any) => {
        console.log('Form has validation errors :: ', err);
      });
  };

  const layout = {
    labelCol: { span: 7 },
    wrapperCol: { span: 17 }
  };

  return (
    (<div>
      <Modal
        title='Add User'
        onCancel={e => {
          clearFormData();
          props.handleCancel(e);
        }}
        open={props.visible}
        footer={[
          <>
            <Button
              key='cancel'
              type='primary'
              danger
              onClick={e => {
                clearFormData();
                props.handleCancel(e);
              }}
              disabled={props.loading}>
              Cancel
            </Button>
            <Button
              key='submit'
              type='primary'
              onClick={handleFormSubmit}
              loading={props.loading}>
              Add user
            </Button>
          </>
        ]}>
        <Form form={form} {...layout} ref={createModalRef}>
          <Form.Item
            label='Email'
            name='email'
            rules={[
              {
                required: true,
                message: 'Please enter user email',
                type: 'email'
              }
            ]}
            required>
            <Input disabled={props.loading} />
          </Form.Item>

          <Form.Item
            name='role'
            label='Role'
            rules={[
              {
                required: true,
                message: 'Please select user role'
              }
            ]}>
            <Select disabled={props.loading}>
              {Object.values(UserRoles).map((userRole, index) => {
                return (
                  <Select.Option value={userRole} key={index}>
                    {capitalize(userRole)}
                  </Select.Option>
                );
              })}
            </Select>
          </Form.Item>
        </Form>
      </Modal>
    </div>)
  );
};

export default AddUserModal;
