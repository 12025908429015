import {
  InitialValues,
  ReportRecipients
} from '../../../types/data/report-recipients.type';
import { Radio, message } from 'antd';
import { useEffect, useState } from 'react';

import ConfirmationModal from '../../../modals/confirmation';
import { Group } from '../../../types/data/group.type';
import { ReportService } from '../../../service/report.service';
import TableComponent from '../../../components/table';
import { TableType } from '../../../enums/table-type.enum';
import { User } from '../../../types/data/user.type';
import { UserRoles } from '../../../enums/user-role.enum';
import { useParams } from 'react-router-dom';

type ReportRecipientsProps = {
  reportId: string;
  isAdmin: string;
};

const ReportRecipientsComponent = (props: ReportRecipientsProps) => {
  const { reportId } = useParams();

  const [loading, setLoading] = useState(true);
  const [activeTab, setActiveTab] = useState('groups');
  const [removeGroupModalVisible, setRemoveGroupModalVisible] = useState(false);
  const [removeUserModalVisible, setRemoveUserModalVisible] = useState(false);
  const [selectedGroup, setSelectedGroup] = useState<Group>();
  const [selectedIndividuals, setSelectedIndividuals] = useState<User>();
  const [reportRecipients, setReportRecipients] =
    useState<ReportRecipients>(InitialValues);
  const handleRemoveGroupFromReport = (data: Group) => {
    setSelectedGroup(data);
    setRemoveGroupModalVisible(true);
  };
  const handleRemoveIndividualsFromReport = (data: User) => {
    setSelectedIndividuals(data);
    setRemoveUserModalVisible(true);
  };
  const getReportRecipients = (reportId: string) => {
    setLoading(true);
    ReportService.getReportRecipientsById(reportId)
      .then(reportRecipients => {
        setReportRecipients(reportRecipients);
      })
      .catch(err => {
        console.log('Error While Fetching Report Recipients :: ', err);
        message.error(
          'Failed to fetch report recipients, please try again later'
        );
      })
      .finally(() => {
        setLoading(false);
      });
  };
  const handleRemoveGroupFromReportOk = () => {
    setLoading(true);
    ReportService.removeGroupsFromReport(reportId as string, {
      groupIds: [selectedGroup?.id]
    })
      .then(() => {
        getReportRecipients(reportId as string);
        message.success('Group successfully removed from report');
      })
      .catch(err => {
        console.log('Error while removing group :: ', err);
        message.error(
          'Failed to remove group from report, please try again later'
        );
      })
      .finally(() => {
        setLoading(false);
        setSelectedGroup(undefined);
        setRemoveGroupModalVisible(false);
      });
  };
  const handleRemoveUserFromReportOk = () => {
    setLoading(true);
    ReportService.removeUsersFromReport(reportId as string, {
      userIds: [selectedIndividuals?.id]
    })
      .then(() => {
        getReportRecipients(reportId as string);
        message.success('User successfully removed from report');
      })
      .catch(err => {
        console.log('Error while removing user :: ', err);
        message.error(
          'Failed to remove user from report, please try again later'
        );
      })
      .finally(() => {
        setLoading(false);
        setSelectedIndividuals(undefined);
        setRemoveUserModalVisible(false);
      });
  };
  const handleRemoveGroupFromReportCancel = () => {
    setLoading(false);
    setSelectedGroup(undefined);
    setRemoveGroupModalVisible(false);
  };
  const handleRemoveUserFromReportCancel = () => {
    setLoading(false);
    setSelectedIndividuals(undefined);
    setRemoveUserModalVisible(false);
  };
  useEffect(() => {
    getReportRecipients(props.reportId);
  }, []);
  const handleRadioChange = (e: any) => {
    setActiveTab(e.target.value);
  };
  return (
    <>
      <Radio.Group
        style={{ marginTop: '1%' }}
        defaultValue='groups'
        buttonStyle='solid'
        onChange={handleRadioChange}>
        <Radio.Button value='groups'>Groups</Radio.Button>
        <Radio.Button value='individuals'>Individuals</Radio.Button>
      </Radio.Group>
      {activeTab === 'groups' && (
        <TableComponent
          userRole={props.isAdmin ? UserRoles.ADMIN : UserRoles.VIEWER}
          loading={loading}
          data={reportRecipients?.groups}
          tableType={TableType.GROUPS}
          handleGroupRemoval={(data: Group) =>
            handleRemoveGroupFromReport(data)
          }
          paginationAtTop={true}
        />
      )}
      {activeTab === 'individuals' && (
        <TableComponent
          userRole={props.isAdmin ? UserRoles.ADMIN : UserRoles.VIEWER}
          loading={loading}
          data={reportRecipients?.users}
          tableType={TableType.INDIVIDUAL_USERS}
          handleUserRemoval={(data: User) =>
            handleRemoveIndividualsFromReport(data)
          }
          paginationAtTop={true}
        />
      )}
      {/* Remove Group From Report Confirmation */}
      <ConfirmationModal
        title={`Are you sure to remove group (${selectedGroup?.name}) from  (${reportRecipients.name})  report?`}
        visible={removeGroupModalVisible}
        danger={true}
        handleOk={handleRemoveGroupFromReportOk}
        handleCancel={handleRemoveGroupFromReportCancel}
        content={`This action would remove group (${selectedGroup?.name}) from this report, thereby revoking access of ${selectedGroup?.users?.length} users to this report`}
      />
      {/* Remove Individual user From Report Confirmation */}
      <ConfirmationModal
        title={`Are you sure to remove user (${selectedIndividuals?.name}) from (${reportRecipients.name})  report?`}
        visible={removeUserModalVisible}
        danger={true}
        handleOk={handleRemoveUserFromReportOk}
        handleCancel={handleRemoveUserFromReportCancel}
        content={`This action would remove user (${selectedIndividuals?.name}) from this report`}
      />
    </>
  );
};
export default ReportRecipientsComponent;
