import {
  Button,
  ColorPicker,
  DatePicker,
  Divider,
  Form,
  FormInstance,
  Input,
  InputNumber,
  Select,
  Space,
  Switch,
  TimePicker,
  Tooltip,
  Typography,
  message
} from 'antd';
import {
  ConditionalFormattingRuleConditions,
  EmailRuleConditions,
  Report,
  ReportConditionalFormattingRules,
  ReportEmailRules,
  ReportStatistics
} from '../../../types/data/report.type';
import {
  InfoCircleOutlined,
  MinusCircleOutlined,
  PlusOutlined,
  TeamOutlined,
  UserOutlined
} from '@ant-design/icons';
import React, { useEffect, useState } from 'react';
import {
  ReportStatisticsOperator,
  ReportStatisticsOperatorDisplayName
} from '../../../enums/report-statistics-operator.enum';
import {
  getConditionalFormattingOperatorKey,
  getQueryColumns,
  getReportEmailConditionalOperatorKey,
  isHistoricalStatisticsOperator,
  storePreviewResult
} from '../../../utils/report.utils';
import { isMobile, isTablet } from 'react-device-detect';
import { useNavigate, useParams } from 'react-router-dom';

import Checkbox from 'antd/lib/checkbox/Checkbox';
import type { Color } from 'antd/es/color-picker';
import EmptyDataComponent from '../../../components/empty-data';
import { Group } from '../../../types/data/group.type';
import { GroupService } from '../../../service/group.service';
import { Helmet } from 'react-helmet';
import LoadingComponent from '../../../components/loading';
import { MomentConstants } from '../../../constants/moment.constants';
import { PageHeader } from '@ant-design/pro-layout';
import { PaginatedType } from '../../../types/data/paginated.type';
import { ReportConditionalFormattingOperator } from '../../../enums/report-conditional-formatting-operator.enum';
import { ReportDataSource } from '../../../enums/report-data-source.enum';
import { ReportEmailConditionalOperator } from '../../../enums/report-email-conditional-operator.enum';
import { ReportHistoricalStatisticsType } from '../../../enums/report-historical-statistics.enum';
import { ReportHistory } from '../../../types/data/report-history.type';
import { ReportRepeatedDays } from '../../../enums/report-repeated-days.enum';
import { ReportService } from '../../../service/report.service';
import ReportsPreviewModal from '../report-preview';
import { RouteConstants } from '../../../constants/route.constants';
import SecureComponent from '../../../components/authorization';
import { User } from '../../../types/data/user.type';
import { UserService } from '../../../service/user.service';
import capitalize from 'lodash.capitalize';
import dayjs from 'dayjs';
import flatten from 'lodash.flatten';
import { formatDate } from '../../../utils/moment.utils';
import isEmpty from 'lodash.isempty';
import startCase from 'lodash.startcase';
import uniq from 'lodash.uniq';

// import EmptyDataComponent from '../../../components/empty-data';

const CreateOrEDitReportPage: React.FC = () => {
  const createModalRef: React.RefObject<FormInstance> =
    React.createRef<FormInstance>();
  const [users, setUsers] = useState<User[]>();
  const [groups, setGroups] = React.useState<PaginatedType<Group>>();
  const [selectValue, setSelectValue] = useState<string>();
  const [reportDetail, setReportDetail] = useState<Report>();
  const [loadingTip, setLoadingTip] = useState<string>('');
  const [queryColumns, setQueryColumns] = useState<string[]>([]);

  const [loadingGroups, setLoadingGroups] = React.useState(false);
  const [loadingReport, setLoadingReport] = React.useState(true);
  const [loading, setLoading] = React.useState(false);
  const [previewReportLoading, setPreviewReportLoading] = useState(false);
  const [previewPopUpVisibility, setPreviewPopUpVisibility] = useState(false);
  const [previewReportData, setPreviewReportData] =
    React.useState<ReportHistory>();

  const { reportId } = useParams();
  const navigate = useNavigate();

  const [form] = Form.useForm();
  const queryValue = Form.useWatch('query', form);
  const statisticsValue = Form.useWatch('statisticsOperators', form);
  const jobStartTime = Form.useWatch('jobStartTime', form);
  const jobEndTime = Form.useWatch('jobEndTime', form);
  const jobTimePicker = Form.useWatch('jobTimePicker', form);
  const jobDate = Form.useWatch('jobDate', form);
  const cc: any = Form.useWatch('conditionalFormattingRules', form);
  const dataSource = Form.useWatch('dataSource', form);
  const showHistoricalStatistics = Form.useWatch(
    'showHistoricalStatistics',
    form
  );
  const exclusionDates = Form.useWatch('exclusionDates', form);

  const frequencies = [
    { name: '5 minutes', value: '5' },
    { name: '15 minutes', value: '15' },
    { name: '30 minutes', value: '30' },
    { name: '60 minutes', value: '60' },
    { name: 'Once a day', value: '1440' },
    { name: 'Weekly', value: 'Weekly' },
    { name: 'Monthly', value: 'Monthly' },
    { name: 'Quarterly', value: 'Quarterly' },
    { name: 'Yearly', value: 'Yearly' }
  ];
  const options = [
    { value: 'value', label: 'Value' },
    { value: 'field', label: 'Field' }
  ];

  useEffect(() => {
    // Display warning when select value is 5, 15, 30, 60 and
    // job start and end times are equal and
    // minute of job start and end times are equal to zero, since time pickers sets current time as value for a second
    // right now we don't support minute hence using this logic

    if (
      selectValue &&
      jobStartTime &&
      jobEndTime &&
      ['5', '15', '30', '60'].includes(selectValue) &&
      jobStartTime.minute() === 0 &&
      jobEndTime.minute() === 0 &&
      jobStartTime.format(MomentConstants.TIME_ALONE_FORMAT) ===
        jobEndTime.format(MomentConstants.TIME_ALONE_FORMAT)
    )
      message.warning(
        'Warning: The scheduled "from" and "to" time are the same'
      );
  }, [selectValue, jobStartTime, jobEndTime]);

  /**
   * Returns a summary string if frequency and job run time is configured
   * @returns {string}
   */
  const getReportRunSummaryString = (): string => {
    if (
      (selectValue && jobTimePicker) ||
      (selectValue && jobStartTime && jobEndTime)
    )
      if (selectValue === '1440') {
        return `* Scheduled to run once a day at ${jobTimePicker.format(
          MomentConstants.timeFormat
        )} (EST)`;
      } else if (selectValue === 'Weekly') {
        return `* Scheduled to run once a week every ${jobDate.format(
          MomentConstants.WEEKDAY_NAME
        )} at ${jobTimePicker.format(MomentConstants.timeFormat)} (EST)`;
      } else if (selectValue === 'Yearly') {
        return `* Scheduled to run once a year on ${jobDate.format(
          MomentConstants.MONTH_DATE
        )} at ${jobTimePicker.format(MomentConstants.timeFormat)} (EST)`;
      } else if (['Quarterly', 'Monthly'].includes(selectValue)) {
        return `* Scheduled to run once a ${
          selectValue === 'Monthly' ? 'month' : 'quarter'
        } on ${jobDate.date()}th at ${jobTimePicker.format(
          MomentConstants.timeFormat
        )} (EST)`;
      } else {
        return `* Scheduled to run every ${
          frequencies.find(frequency => frequency.value === selectValue)?.name
        } from ${jobStartTime.format(
          MomentConstants.timeFormat
        )} to ${jobEndTime.format(MomentConstants.timeFormat)} (EST)`;
      }
    else return '';
  };

  const validateColumn = (rule: any, value: any, callback: any) => {
    if (!queryColumns.includes(value) && value !== undefined) {
      callback('This column does not exist');
    } else {
      callback();
    }
  };

  const createReport = (data: any) => {
    setLoading(true);
    setLoadingTip('Creating report...');

    ReportService.createReport(data)
      .then(() => {
        setLoading(false);
        message.success('Report created successfully');
        navigate(RouteConstants.REPORTS);
      })
      .catch(err => {
        console.log('Error while creating report', err);
        let errorMessage = 'Failed to create report, please try again later';
        if (err?.error?.message) errorMessage = err.error.message;
        message.error(errorMessage);
        setLoading(false);
      });
  };

  const handleCancelPreviewReport = () => {
    setPreviewPopUpVisibility(false);
  };

  const updateReport = (data: any) => {
    setLoading(true);
    setLoadingTip('Updating report...');
    ReportService.updateReport(reportId as string, data)
      .then(() => {
        setLoading(false);
        message.success('Report updated successfully');
        navigate(
          RouteConstants.REPORT_DETAILS.replace(':reportId', reportId as string)
        );
      })
      .catch(err => {
        console.log('Error while updating report', err);
        let errorMessage = 'Failed to update report, please try again later';
        if (err?.error?.message) errorMessage = err.error.message;
        message.error(errorMessage);
        setLoading(false);
      });
  };

  const constructRecipients = (recipients: any) => {
    try {
      const usersList = users?.map(user => user.id);
      const groupList = groups?.data.map(group => group.id);

      const userRecipients = recipients.filter((recipient: any) =>
        usersList?.includes(recipient)
      );
      const groupRecipients = recipients.filter((recipient: any) =>
        groupList?.includes(recipient)
      );
      return [groupRecipients, userRecipients];
    } catch (error) {
      console.log('Error while constructing recipients object :: ', error);
      return [];
    }
  };

  const constructStatisticsObject = (selectedOperators: any) => {
    try {
      const statistics: any = selectedOperators.map((operator: any) => ({
        operator
      }));
      return statistics;
    } catch (error) {
      console.log('Error while constructing statistics object :: ', error);
      return [];
    }
  };

  const deconstructStatisticsObject = (
    reportStatistics: ReportStatistics[]
  ) => {
    try {
      const statisticsOperators: any = [];
      reportStatistics.forEach(stat => {
        if (
          stat.operator != ReportStatisticsOperator.SUB_AVERAGE &&
          stat.operator != ReportStatisticsOperator.SUB_TOTAL
        )
          statisticsOperators.push(stat.operator);
      });
      return statisticsOperators;
    } catch (err) {
      console.log('Error while deconstructing statistics object :: ', err);
      return {};
    }
  };

  const constructStatisticalEmailCondition = (
    reportStatisticalCondition: any
  ) => {
    try {
      const statisticalConditioneRules: any = [];
      const columns = uniq(
        Object.values(
          reportStatisticalCondition.map((each: any) => each.column)
        )
      );
      columns.forEach(column => {
        const filteredColumns = reportStatisticalCondition.filter(
          (formattingRule: any) => formattingRule.column === column
        );

        statisticalConditioneRules.push({
          column,
          conditions: uniq(
            flatten(
              filteredColumns.map((filteredColumn: any) => {
                const tempObj = {
                  statistics: filteredColumn.statistics,
                  operator: filteredColumn.operator,
                  value: filteredColumn.value
                };
                return tempObj;
              })
            )
          )
        });
      });
      return statisticalConditioneRules;
    } catch (error) {
      console.log(
        'Error while constructing statisticalConditional object :: ',
        error
      );
      return reportStatisticalCondition;
    }
  };

  const deconstructStatisticalEmailCondition = (
    reportStatisticalCondition: ReportEmailRules[]
  ) => {
    const statisticalConditioneRules: any = [];

    reportStatisticalCondition?.forEach(
      (eachStatisticalFormatRule: ReportEmailRules) => {
        eachStatisticalFormatRule.conditions.map(
          (eachCondition: EmailRuleConditions) => {
            statisticalConditioneRules.push({
              column: eachStatisticalFormatRule.column,
              statistics: eachCondition.statistics,
              value: eachCondition.value,
              operator: eachCondition.operator
            });
          }
        );
      }
    );
    return statisticalConditioneRules;
  };

  const constructConditionalFormattingObject = (
    reportConditionalFormats: any
  ) => {
    try {
      const conditionalFormateRules: any = [];
      const columns = uniq(
        Object.values(reportConditionalFormats.map((each: any) => each.column))
      );

      columns.forEach(column => {
        // get the columns selected from all configured data
        const filteredColumns = reportConditionalFormats.filter(
          (formattingRule: any) => formattingRule.column === column
        );

        conditionalFormateRules.push({
          column,
          conditions: uniq(
            flatten(
              filteredColumns.map((filteredColumn: any) => {
                const tempObj = {
                  colour:
                    typeof filteredColumn.colour === 'object'
                      ? (filteredColumn.colour as Color).toHexString()
                      : filteredColumn.colour,
                  operator: filteredColumn.operator,
                  type: filteredColumn.type
                };
                if (filteredColumn.type === 'field')
                  tempObj['field'] = filteredColumn.field;
                else if (
                  filteredColumn.operator !==
                    ReportConditionalFormattingOperator['Is null'] &&
                  filteredColumn.operator !==
                    ReportConditionalFormattingOperator['Is not null'] &&
                  filteredColumn.operator !==
                    ReportConditionalFormattingOperator['Is between'] &&
                  filteredColumn.operator !==
                    ReportConditionalFormattingOperator['Is not between']
                ) {
                  tempObj['value'] = filteredColumn.value;
                }
                if (
                  filteredColumn.operator ===
                    ReportConditionalFormattingOperator['Is between'] ||
                  filteredColumn.operator ===
                    ReportConditionalFormattingOperator['Is not between']
                ) {
                  tempObj['from'] = filteredColumn.from;
                  tempObj['to'] = filteredColumn.to;
                }

                return tempObj;
              })
            )
          )
        });
      });
      return conditionalFormateRules;
    } catch (error) {
      console.log(
        'Error while constructing conditional formatting object :: ',
        error
      );
      return reportConditionalFormats;
    }
  };

  const deConstructConditionalFormattingObject = (
    reportConditionalFormats: ReportConditionalFormattingRules[]
  ) => {
    const conditionalFormateRules: any = [];

    reportConditionalFormats.forEach(
      (eachConditionalFormatRule: ReportConditionalFormattingRules) => {
        eachConditionalFormatRule.conditions.map(
          (eachCondition: ConditionalFormattingRuleConditions) => {
            conditionalFormateRules.push({
              column: eachConditionalFormatRule.column,
              value: eachCondition.value,
              field: eachCondition.field,
              colour: eachCondition.colour,
              operator: eachCondition.operator,
              from: eachCondition.from,
              to: eachCondition.to,
              type: eachCondition.type ? eachCondition.type : 'value'
            });
          }
        );
      }
    );
    return conditionalFormateRules;
  };

  const formattedData = (data: any) => {
    if (data.jobStartTime && data.jobEndTime) {
      data.jobStartTime = data.jobStartTime.format(
        MomentConstants.TIME_ALONE_FORMAT
      );
      data.jobEndTime = data.jobEndTime.format(
        MomentConstants.TIME_ALONE_FORMAT
      );
    } else {
      data.jobStartTime = data.jobTimePicker.format(
        MomentConstants.TIME_ALONE_FORMAT
      );
      data.jobEndTime = data.jobTimePicker.format(
        MomentConstants.TIME_ALONE_FORMAT
      );
      delete data.jobTimePicker;
    }

    if (data.recipient) {
      [data.groups, data.users] = constructRecipients(data.recipient);
      delete data.recipient;
    }
    if (data.statisticsOperators) {
      data.statistics = constructStatisticsObject(data.statisticsOperators);
      delete data.statisticsOperators;
    }

    if (data.conditionalFormattingRules)
      data.conditionalFormattingRules = constructConditionalFormattingObject(
        data.conditionalFormattingRules
      );

    if (data.emailConditions)
      data.emailConditions = constructStatisticalEmailCondition(
        data.emailConditions
      );

    if (data.subStatistics) {
      if (data.statistics)
        data.statistics = [...data.statistics, ...data.subStatistics];
      else data.statistics = data.subStatistics;
      delete data.subStatistics;
    }

    if (data.jobDate)
      data.jobDate = data.jobDate.format(MomentConstants.DATE_ALONE);

    if (data.isWeekday) {
      data.repeatedDays = ReportRepeatedDays.WEEKDAY;
      delete data.isWeekday;
    } else {
      data.repeatedDays = ReportRepeatedDays.DAILY;
      delete data.isWeekday;
    }

    if (data.dataSource === ReportDataSource.SNOWFLAKE) {
      data.metadata = {
        database: data.snowflakeDatabase,
        schema: data.snowflakeSchema
      };
      delete data.snowflakeDatabase;
      delete data.snowflakeSchema;
    }

    if (data.dataSource === ReportDataSource.SALESFORCE) {
      if (data.exclusionDates) {
        data.exclusionDates = data.exclusionDates
          .filter((date: any) => date && date)
          .map((date: any) => date.format(MomentConstants.DATE_ALONE));
      }
    }

    return data;
  };

  const handleOnFinish = () => {
    createModalRef.current
      ?.validateFields()
      .then(async data => {
        const formatData = formattedData(data);
        formatData.savePreviewReport = false;
        if (reportId) {
          if (previewReportData?.data) {
            if (storePreviewResult(previewReportData, formatData)) {
              formatData.savePreviewReport = true;
              message.info('Preview will be stored');
            } else {
              message.warning(
                'Preview wont be stored as report configuration has been changed.'
              );
            }
          }
          updateReport(formatData);
        } else {
          createReport(data);
        }
      })
      .catch(err => {
        console.log('Form has validation errors :: ', err);
      });
  };

  const handleOnFinishReportPreview = async (): Promise<any> => {
    await createModalRef.current?.validateFields().then(async data => {
      const formatData = formattedData(data);
      setPreviewReportLoading(true);
      ReportService.previewReport(formatData, reportId)
        .then(previewReportData => {
          setPreviewReportData(previewReportData);
          setPreviewReportLoading(false);
          setPreviewPopUpVisibility(true);
        })
        .catch(err => {
          console.log('Error while generating preview for report :: ', err);
          message.error('Unable to generate preview. Please try again later.');
          setPreviewReportLoading(false);
        });
    });
  };

  const getReportDetails = (reportId: string) => {
    setLoadingReport(true);
    setLoadingTip('Loading Report...');
    ReportService.getReportById(reportId)
      .then(report => {
        setReportDetail(report);
        if (report && report.frequency) setSelectValue(report.frequency);
      })
      .catch(err => {
        console.log('Error while fetching report', err);
        message.error('Failed to fetch report detail, please try again later');
      })
      .finally(() => {
        setLoadingReport(false);
      });
  };

  const getAllUsers = () => {
    setLoading(true);
    UserService.getAllUsers()
      .then(async (users: PaginatedType<User>) => {
        setUsers(users.data);
      })
      .catch(err => {
        console.log('Error while fetching users :: ', err);
      })
      .finally(() => setLoading(false));
  };

  const getAllGroups = () => {
    setLoadingGroups(true);
    GroupService.getAllGroups()
      .then(groups => {
        setGroups(groups);
        setLoadingGroups(false);
      })
      .catch(err => {
        console.log('Error while fetching data', err);
        setLoadingGroups(false);
      });
  };

  useEffect(() => {
    getAllGroups();
    getAllUsers();
  }, []);

  // Get Report Details using UseEffect
  useEffect(() => {
    if (reportId) getReportDetails(reportId as string);
    else setLoadingReport(false);
  }, [reportId]);

  const layout = {
    labelCol: { xl: { span: 4 }, lg: { span: 5 }, md: { span: 7 } },
    wrapperCol: { span: 14 }
  };

  useEffect(() => {
    if (queryValue) {
      setQueryColumns(getQueryColumns(queryValue));
    }
  }, [queryValue]);

  return loading || loadingReport || loadingGroups ? (
    <LoadingComponent tip={loadingTip} />
  ) : !reportId || (reportId && reportDetail) ? (
    <>
      <Helmet>
        <title>
          {reportDetail ? reportDetail.name : 'Create'} Report | Analytics
          Dashboard | Elite Insurance Partners
        </title>
        <meta
          name='description'
          content={`${
            reportDetail ? reportDetail.name : 'Create'
          }  | Elite Insurance Partners | Analytics Dashboard - Report`}
        />
      </Helmet>
      <PageHeader
        style={{ backgroundColor: 'white', borderRadius: 6 }}
        onBack={() =>
          navigate(
            reportDetail
              ? RouteConstants.REPORT_DETAILS.replace(
                  ':reportId',
                  reportId as string
                )
              : RouteConstants.REPORTS
          )
        }
        title={`${
          reportDetail ? `Edit Report - ${reportDetail.name}` : 'Create Report'
        }`}>
        <Form
          {...layout}
          labelWrap={true}
          ref={createModalRef}
          initialValues={
            reportDetail
              ? {
                  ...reportDetail,
                  recipient: reportDetail.groups
                    .map(g => g.id)
                    .concat(reportDetail.users.map(u => u.id)),
                  jobTimePicker: dayjs(
                    reportDetail.jobStartTime,
                    MomentConstants.TIME_ALONE_FORMAT
                  ),
                  jobStartTime: dayjs(
                    reportDetail.jobStartTime,
                    MomentConstants.TIME_ALONE_FORMAT
                  ),
                  jobEndTime: dayjs(
                    reportDetail.jobEndTime,
                    MomentConstants.TIME_ALONE_FORMAT
                  ),
                  conditionalFormattingRules:
                    deConstructConditionalFormattingObject(
                      reportDetail.conditionalFormattingRules
                    ),
                  statisticsOperators: deconstructStatisticsObject(
                    reportDetail.statistics
                  ),

                  emailConditions: deconstructStatisticalEmailCondition(
                    reportDetail.emailConditions
                  ),
                  subStatistics: reportDetail.statistics.filter(stat => {
                    return [
                      ReportStatisticsOperator.SUB_TOTAL,
                      ReportStatisticsOperator.SUB_AVERAGE
                    ].includes(stat.operator);
                  }),
                  jobDate: reportDetail.jobDate
                    ? dayjs(reportDetail.jobDate, MomentConstants.DATE_ALONE)
                    : dayjs(),
                  isWeekday:
                    reportDetail.repeatedDays === ReportRepeatedDays.WEEKDAY
                      ? true
                      : false,
                  ...(reportDetail.dataSource ===
                    ReportDataSource.SNOWFLAKE && {
                    snowflakeDatabase: reportDetail.metadata?.database,
                    snowflakeSchema: reportDetail.metadata?.schema
                  }),
                  ...(reportDetail.dataSource ===
                    ReportDataSource.SALESFORCE && {
                    noOfPreviousDaysReportsToUse:
                      reportDetail.noOfPreviousDaysReportsToUse
                        ? reportDetail.noOfPreviousDaysReportsToUse.length === 0
                          ? [undefined]
                          : reportDetail.noOfPreviousDaysReportsToUse
                        : [undefined],
                    exclusionDates: reportDetail.exclusionDates
                      ? reportDetail.exclusionDates.map(date =>
                          dayjs(formatDate(date, MomentConstants.DATE_ALONE))
                        )
                      : []
                  })
                }
              : {
                  isActive: true,
                  addAsAttachment: true,
                  isImportant: false,
                  isWeekday: false,
                  jobTimePicker: dayjs(12, MomentConstants.timeFormat),
                  jobStartTime: dayjs('08 a', MomentConstants.timeFormat),
                  jobEndTime: dayjs('08 p', MomentConstants.timeFormat),
                  sendMailIfNoData: true,
                  jobDate: dayjs(),
                  showHistoricalStatistics: false,
                  noOfPreviousDaysReportsToUse: [undefined]
                }
          }
          style={{ height: '100%', overflowY: 'auto' }}
          onFinish={handleOnFinish}
          form={form}>
          <Form.Item
            name='name'
            label='Name'
            rules={[
              {
                required: true,
                message: 'Please enter a name'
              }
            ]}>
            <Input />
          </Form.Item>

          <Form.Item
            name='dataSource'
            label={<span>Data Source</span>}
            rules={[
              {
                required: true,
                message: 'Please choose an data source'
              }
            ]}>
            <Select disabled={reportId ? true : false}>
              {Object.values(ReportDataSource).map((dataSource, index) => {
                return (
                  <Select.Option value={dataSource} key={index}>
                    {dataSource}
                  </Select.Option>
                );
              })}
            </Select>
          </Form.Item>

          {dataSource === ReportDataSource.SNOWFLAKE && (
            <>
              <Form.Item
                name='snowflakeDatabase'
                label='Database'
                rules={[
                  {
                    required: true,
                    message: 'Please enter a database'
                  }
                ]}
                style={{ marginLeft: '7em' }}>
                <Input />
              </Form.Item>
              <Form.Item
                style={{ marginLeft: '7em' }}
                name='snowflakeSchema'
                label='Schema'
                rules={[
                  {
                    required: true,
                    message: 'Please enter a schema'
                  }
                ]}>
                <Input />
              </Form.Item>
            </>
          )}

          <Form.Item
            name='query'
            label='Query/URL'
            rules={[
              {
                required: true,
                message: 'Please enter a query or url'
              }
            ]}>
            <Input.TextArea rows={4} />
          </Form.Item>

          {reportDetail && (
            <Form.Item
              name='isActive'
              label={<span>Is Report Active</span>}
              valuePropName='checked'>
              <Switch checkedChildren='Yes' unCheckedChildren='No' />
            </Form.Item>
          )}

          <Form.Item
            name='frequency'
            label='Frequency'
            rules={[
              {
                required: true,
                message: 'Please choose a frequency'
              }
            ]}
            style={{
              marginBottom: 0
            }}>
            <Select
              onChange={(value: string) => {
                setSelectValue(value);
                form.setFieldsValue({
                  jobTimePicker: undefined,
                  jobStartTime: undefined,
                  jobEndTime: undefined
                });
              }}>
              {frequencies.map((frequency, index) => {
                return (
                  <Select.Option value={frequency.value} key={index}>
                    {frequency.name}
                  </Select.Option>
                );
              })}
            </Select>
          </Form.Item>

          {selectValue &&
          !['Weekly', 'Monthly', 'Quarterly', 'Yearly'].includes(
            selectValue
          ) ? (
            <Form.Item
              name='isWeekday'
              valuePropName='checked'
              wrapperCol={{
                xl: { offset: 4 },
                lg: { offset: 5 },
                md: { offset: 7 },
                sm: { offset: 2 }
              }}>
              <Checkbox>Run only on weekdays</Checkbox>
            </Form.Item>
          ) : (
            <span
              style={{
                marginBottom: 24,
                display: 'block'
              }}></span>
          )}

          {selectValue &&
          ['1440', 'Weekly', 'Monthly', 'Quarterly', 'Yearly'].includes(
            selectValue
          ) ? (
            <Form.Item
              name='jobTimePicker'
              label='Job Time (EST)'
              rules={[
                {
                  required: true,
                  message: 'Please select report run time'
                }
              ]}>
              <TimePicker
                showSecond={false}
                showMinute={false}
                use12Hours={true}
                showNow={false}
                minuteStep={30}
                format={MomentConstants.timeFormat}
              />
            </Form.Item>
          ) : (
            <>
              <Form.Item
                label='Job Time (EST)'
                required={true}
                style={{ marginBottom: 0 }}>
                <div style={{ display: 'flex' }}>
                  <Form.Item
                    name='jobStartTime'
                    rules={[
                      {
                        required: true,
                        message: 'Please select report run start time'
                      }
                    ]}>
                    <TimePicker
                      showSecond={false}
                      showMinute={false}
                      use12Hours={true}
                      showNow={false}
                      format={MomentConstants.timeFormat}
                    />
                  </Form.Item>
                  <span style={{ margin: '4px 10px 0px 10px' }}>to</span>
                  <Form.Item
                    name='jobEndTime'
                    rules={[
                      {
                        required: true,
                        message: 'Please select report run end time'
                      }
                    ]}>
                    <TimePicker
                      showSecond={false}
                      showMinute={false}
                      use12Hours={true}
                      showNow={false}
                      format={MomentConstants.timeFormat}
                    />
                  </Form.Item>
                </div>
              </Form.Item>
            </>
          )}
          {selectValue &&
            ['Weekly', 'Monthly', 'Quarterly', 'Yearly'].includes(
              selectValue
            ) && (
              <Form.Item
                name='jobDate'
                label='Job Start Date'
                rules={[{ required: true, message: 'Please select a date' }]}>
                <DatePicker format={MomentConstants.DATE_ALONE} />
              </Form.Item>
            )}

          <Form.Item
            name='recipient'
            label='Recipient(s) / Group(s)'
            rules={[
              {
                required: true,
                message: 'Please choose at-least one recipient/group'
              }
            ]}>
            <Select mode='multiple' optionFilterProp='label' allowClear>
              {groups?.data &&
                groups.data.map((group, index) => {
                  return (
                    <Select.Option
                      value={group.id}
                      label={group.name}
                      key={`group-${index}`}>
                      <Tooltip title='User Group'>
                        <TeamOutlined />
                      </Tooltip>{' '}
                      {group.name}
                    </Select.Option>
                  );
                })}
              {users &&
                users.map((user, index) => {
                  return (
                    <Select.Option
                      value={user.id}
                      label={user.name}
                      key={`user-${index}`}>
                      <Tooltip title='User'>
                        <UserOutlined />
                      </Tooltip>{' '}
                      {user.name}
                    </Select.Option>
                  );
                })}
            </Select>
          </Form.Item>

          <Form.Item
            name='emailSubject'
            label='Subject'
            rules={[
              {
                required: false
              }
            ]}>
            <Input.TextArea rows={2} showCount maxLength={100} />
          </Form.Item>

          <Form.Item
            name='emailBody'
            label='Body'
            rules={[
              {
                required: false
              }
            ]}>
            <Input.TextArea rows={4} showCount maxLength={500} />
          </Form.Item>

          <Form.Item
            name='addAsAttachment'
            label={<span>Attach report to mail</span>}
            valuePropName='checked'>
            <Switch checkedChildren='Yes' unCheckedChildren='No' />
          </Form.Item>

          <Form.Item
            name='isImportant'
            label={<span>Mark email as important</span>}
            valuePropName='checked'>
            <Switch checkedChildren='Yes' unCheckedChildren='No' />
          </Form.Item>

          <Form.Item
            name='sendMailIfNoData'
            label={<span>Send e-mail if no data</span>}
            valuePropName='checked'>
            <Switch checkedChildren='Yes' unCheckedChildren='No' />
          </Form.Item>

          {dataSource === ReportDataSource.SALESFORCE && (
            <>
              <Form.Item label='Send e-mail if' rules={[{ required: false }]}>
                <Form.List name='emailConditions'>
                  {(fields, { add, remove }) => {
                    return (
                      <>
                        {fields.map(({ key, name, ...restFields }, index) => {
                          return (
                            <React.Fragment key={index}>
                              {(isMobile || isTablet) && index > 0 && (
                                <Divider
                                  dashed
                                  style={{ marginTop: 0, width: '120%' }}
                                />
                              )}
                              <Space
                                key={key}
                                size={'middle'}
                                className='form-input-multi-spaced'
                                align='baseline'>
                                <Form.Item
                                  {...restFields}
                                  label='Statistics'
                                  name={[name, 'statistics']}
                                  rules={[
                                    {
                                      required: true,
                                      message: 'Please choose an statistics'
                                    }
                                  ]}>
                                  <Select
                                    style={{
                                      width: 200
                                    }}>
                                    {statisticsValue?.map(
                                      (operator: any, index: any) => {
                                        return (
                                          <Select.Option
                                            key={index}
                                            value={operator}>
                                            {operator}
                                          </Select.Option>
                                        );
                                      }
                                    )}
                                  </Select>
                                </Form.Item>
                                <Form.Item
                                  {...restFields}
                                  label='Column'
                                  name={[name, 'column']}
                                  rules={[
                                    {
                                      required: true,
                                      message: 'Please choose a column'
                                    },
                                    {
                                      validator: validateColumn
                                    }
                                  ]}>
                                  <Select
                                    style={{
                                      width: 200
                                    }}>
                                    {queryColumns.map((column, index) => (
                                      <Select.Option key={index} value={column}>
                                        {column}
                                      </Select.Option>
                                    ))}
                                  </Select>
                                </Form.Item>

                                <Form.Item
                                  {...restFields}
                                  label='Rule'
                                  name={[name, 'operator']}
                                  rules={[
                                    {
                                      required: true,
                                      message: 'Please choose an operator'
                                    }
                                  ]}>
                                  <Select
                                    style={{
                                      width: 200
                                    }}>
                                    {Object.values(
                                      ReportEmailConditionalOperator
                                    ).map((operator, index) => {
                                      return (
                                        <Select.Option
                                          key={index}
                                          value={operator}>
                                          {getReportEmailConditionalOperatorKey(
                                            operator as ReportEmailConditionalOperator
                                          )}
                                        </Select.Option>
                                      );
                                    })}
                                  </Select>
                                </Form.Item>

                                <Form.Item
                                  {...restFields}
                                  label='Value'
                                  name={[name, 'value']}
                                  rules={[
                                    {
                                      required: true,
                                      message: 'Please enter a value'
                                    }
                                  ]}>
                                  <InputNumber />
                                </Form.Item>

                                <MinusCircleOutlined
                                  style={{ color: 'red' }}
                                  onClick={() => remove(name)}
                                />
                              </Space>
                            </React.Fragment>
                          );
                        })}
                        <Form.Item>
                          <Button
                            type='dashed'
                            onClick={() => {
                              const { emailConditions } = form.getFieldsValue([
                                'emailConditions'
                              ]);
                              let emptyExists = false;
                              emailConditions &&
                                emailConditions.forEach((ec: any) => {
                                  if (ec === undefined) emptyExists = true;
                                });
                              if (emptyExists)
                                message.warning(
                                  'Please fill all details to add another condition'
                                );
                              else add();
                            }}
                            block
                            disabled={
                              isEmpty(statisticsValue) || isEmpty(queryValue)
                            }
                            icon={<PlusOutlined />}>
                            Add Conditions
                          </Button>
                        </Form.Item>
                      </>
                    );
                  }}
                </Form.List>
              </Form.Item>
              <Form.Item label='Statistics' name='statisticsOperators'>
                <Select mode='multiple'>
                  {Object.keys(ReportStatisticsOperator)
                    .filter(
                      operator =>
                        operator !== ReportStatisticsOperator.SUB_AVERAGE &&
                        operator !== ReportStatisticsOperator.SUB_TOTAL
                    )
                    .map((operator, index) => (
                      <Select.Option key={index} value={operator}>
                        {startCase(
                          capitalize(
                            ReportStatisticsOperatorDisplayName[operator]
                          )
                        )}
                      </Select.Option>
                    ))}
                </Select>
              </Form.Item>
              <Form.Item label='Sub-Statistics' rules={[{ required: false }]}>
                <Form.List name='subStatistics'>
                  {(fields, { add, remove }) => {
                    return (
                      <>
                        {fields.map(({ key, name, ...restFields }, index) => (
                          <React.Fragment key={index}>
                            {(isMobile || isTablet) && index > 0 && (
                              <Divider
                                dashed
                                style={{ marginTop: 0, width: '120%' }}
                              />
                            )}
                            <Space
                              key={key}
                              size={'middle'}
                              className='form-input-multi-spaced'
                              align='baseline'>
                              <Form.Item
                                {...restFields}
                                label='Operator'
                                name={[name, 'operator']}
                                rules={[
                                  {
                                    required: true,
                                    message: 'Please choose an operator'
                                  }
                                ]}>
                                <Select
                                  style={{
                                    width: 200
                                  }}>
                                  {Object.keys(ReportStatisticsOperator)
                                    .filter(
                                      operator =>
                                        operator ===
                                          ReportStatisticsOperator.SUB_AVERAGE ||
                                        operator ===
                                          ReportStatisticsOperator.SUB_TOTAL
                                    )
                                    .map((operator, index) => (
                                      <Select.Option
                                        key={index}
                                        value={operator}>
                                        {capitalize(operator)}
                                      </Select.Option>
                                    ))}
                                </Select>
                              </Form.Item>
                              <Form.Item
                                {...restFields}
                                label='Group By'
                                name={[name, 'baseColumn']}
                                rules={[
                                  {
                                    required: true,
                                    message: 'Please select a base column'
                                  },
                                  {
                                    validator: validateColumn
                                  }
                                ]}>
                                <Select
                                  style={{
                                    width: 200
                                  }}
                                  disabled={
                                    !queryValue || isEmpty(queryColumns)
                                  }>
                                  {queryColumns.map((column, index) => (
                                    <Select.Option key={index} value={column}>
                                      {column}
                                    </Select.Option>
                                  ))}
                                </Select>
                              </Form.Item>
                              <MinusCircleOutlined
                                style={{ color: 'red' }}
                                onClick={() => remove(name)}
                              />
                            </Space>
                          </React.Fragment>
                        ))}
                        <Form.Item>
                          <Button
                            type='dashed'
                            onClick={() => {
                              const { subStatistics } = form.getFieldsValue([
                                'subStatistics'
                              ]);
                              let emptyExists = false;
                              subStatistics &&
                                subStatistics.forEach((ec: any) => {
                                  if (ec === undefined) emptyExists = true;
                                });
                              if (emptyExists)
                                message.warning(
                                  'Please fill all details to add another sub-statistics'
                                );
                              else add();
                            }}
                            block
                            disabled={!queryValue || isEmpty(queryColumns)}
                            icon={<PlusOutlined />}>
                            Add Sub-Statistics
                          </Button>
                        </Form.Item>
                      </>
                    );
                  }}
                </Form.List>
              </Form.Item>
              <Form.Item
                label='Conditional Formatting'
                rules={[{ required: false }]}>
                <Form.List name='conditionalFormattingRules'>
                  {(fields, { add, remove }) => {
                    return (
                      <>
                        {fields.map(({ key, name, ...restFields }, index) => {
                          return (
                            <React.Fragment key={index}>
                              {(isMobile || isTablet) && index > 0 && (
                                <Divider
                                  dashed
                                  style={{ marginTop: 0, width: '120%' }}
                                />
                              )}
                              <Space
                                key={key}
                                size={'middle'}
                                className='form-input-multi-spaced'
                                align='baseline'>
                                <Form.Item
                                  {...restFields}
                                  label='Column'
                                  name={[name, 'column']}
                                  rules={[
                                    {
                                      required: true,
                                      message: 'Please choose a column'
                                    },
                                    {
                                      validator: validateColumn
                                    }
                                  ]}>
                                  <Select
                                    style={{
                                      width: 250
                                    }}>
                                    {queryColumns
                                      .filter(
                                        column =>
                                          column !== cc?.[name]?.['field']
                                      )
                                      .map((column, index) => (
                                        <Select.Option
                                          key={index}
                                          value={column}>
                                          {column}
                                        </Select.Option>
                                      ))}
                                  </Select>
                                </Form.Item>

                                <Form.Item
                                  {...restFields}
                                  label='Type'
                                  initialValue='value'
                                  name={[name, 'type']}
                                  rules={[
                                    {
                                      required: true,
                                      message: 'Please choose an Type'
                                    }
                                  ]}>
                                  <Select
                                    defaultValue='Value'
                                    style={{
                                      width: 100
                                    }}>
                                    {options.map((option, index) => {
                                      return (
                                        <Select.Option
                                          value={option.value}
                                          key={index}>
                                          {option.label}
                                        </Select.Option>
                                      );
                                    })}
                                  </Select>
                                </Form.Item>

                                <Form.Item
                                  {...restFields}
                                  label='Rule'
                                  name={[name, 'operator']}
                                  rules={[
                                    {
                                      required: true,
                                      message: 'Please choose an operator'
                                    }
                                  ]}>
                                  <Select
                                    style={{
                                      width: 200
                                    }}>
                                    <>
                                      {cc?.[name]?.['type'] === 'field' ? (
                                        <>
                                          {Object.values(
                                            ReportEmailConditionalOperator
                                          ).map((operator, index) => {
                                            return (
                                              <Select.Option
                                                key={index}
                                                value={operator}>
                                                {getReportEmailConditionalOperatorKey(
                                                  operator as ReportEmailConditionalOperator
                                                )}
                                              </Select.Option>
                                            );
                                          })}
                                        </>
                                      ) : (
                                        <>
                                          {Object.values(
                                            ReportConditionalFormattingOperator
                                          ).map((operator, index) => {
                                            return (
                                              <Select.Option
                                                key={index}
                                                value={operator}>
                                                {getConditionalFormattingOperatorKey(
                                                  operator as ReportConditionalFormattingOperator
                                                )}
                                              </Select.Option>
                                            );
                                          })}
                                        </>
                                      )}
                                    </>
                                  </Select>
                                </Form.Item>

                                {cc?.[name]?.['type'] === 'field' ? (
                                  <Form.Item
                                    {...restFields}
                                    label='Field'
                                    name={[name, 'field']}
                                    rules={[
                                      {
                                        required: true,
                                        message: 'Please choose a column'
                                      },
                                      {
                                        validator: validateColumn
                                      }
                                    ]}>
                                    <Select
                                      style={{
                                        width: 190
                                      }}>
                                      {queryColumns
                                        .filter(
                                          column =>
                                            column !== cc?.[name]?.['column']
                                        )
                                        .map((column, index) => (
                                          <Select.Option
                                            key={index}
                                            value={column}>
                                            {column}
                                          </Select.Option>
                                        ))}
                                    </Select>
                                  </Form.Item>
                                ) : (
                                  <>
                                    {cc?.[name]?.['operator'] ===
                                      ReportConditionalFormattingOperator[
                                        'Is between'
                                      ] ||
                                    cc?.[name]?.['operator'] ===
                                      ReportConditionalFormattingOperator[
                                        'Is not between'
                                      ] ? (
                                      <>
                                        <Form.Item
                                          {...restFields}
                                          label='From'
                                          name={[name, 'from']}
                                          rules={[
                                            {
                                              required: true,
                                              message: 'Please enter a value'
                                            }
                                          ]}>
                                          <InputNumber />
                                        </Form.Item>
                                        <Form.Item
                                          {...restFields}
                                          label='To'
                                          name={[name, 'to']}
                                          rules={[
                                            {
                                              required: true,
                                              message: 'Please enter a value'
                                            }
                                          ]}>
                                          <InputNumber />
                                        </Form.Item>
                                      </>
                                    ) : (
                                      <Form.Item
                                        {...restFields}
                                        label={
                                          cc?.[name]?.['operator'] === '<>' ||
                                          cc?.[name]?.['operator'] === '!<>'
                                            ? '\u00a0\u00a0\u00a0Value'
                                            : 'Value'
                                        }
                                        name={
                                          cc?.[name]?.['operator'] === '<>' ||
                                          cc?.[name]?.['operator'] === '!<>'
                                            ? undefined
                                            : [name, 'value']
                                        }
                                        rules={[
                                          {
                                            required:
                                              cc?.[name]?.['operator'] ===
                                                '<>' ||
                                              cc?.[name]?.['operator'] === '!<>'
                                                ? false
                                                : true,
                                            message: 'Please enter a value'
                                          }
                                        ]}>
                                        <Input
                                          disabled={
                                            cc?.[name]?.['operator'] === '<>' ||
                                            cc?.[name]?.['operator'] === '!<>'
                                          }
                                        />
                                      </Form.Item>
                                    )}
                                  </>
                                )}

                                <Form.Item
                                  label='Background'
                                  name={[name, 'colour']}
                                  rules={[
                                    {
                                      required: true,
                                      message: 'Please choose a color '
                                    }
                                  ]}>
                                  <ColorPicker defaultValue={'#ffffff'} />
                                </Form.Item>
                                <MinusCircleOutlined
                                  style={{ color: 'red' }}
                                  onClick={() => remove(name)}
                                />
                              </Space>
                            </React.Fragment>
                          );
                        })}
                        <Form.Item>
                          <Button
                            type='dashed'
                            onClick={() => {
                              const { emailConditions } = form.getFieldsValue([
                                'emailConditions'
                              ]);
                              let emptyExists = false;
                              emailConditions &&
                                emailConditions.forEach((ec: any) => {
                                  if (ec === undefined) emptyExists = true;
                                });
                              if (emptyExists)
                                message.warning(
                                  'Please fill all details to add another condition'
                                );
                              else add();
                            }}
                            block
                            disabled={!queryValue || isEmpty(queryColumns)}
                            icon={<PlusOutlined />}>
                            Add Conditional Formatting
                          </Button>
                        </Form.Item>
                      </>
                    );
                  }}
                </Form.List>
              </Form.Item>
              {selectValue &&
                !['Weekly', 'Monthly', 'Quarterly', 'Yearly'].includes(
                  selectValue
                ) &&
                !isEmpty(statisticsValue) &&
                statisticsValue.some((statVal: string) =>
                  isHistoricalStatisticsOperator(statVal)
                ) && (
                  <>
                    <Form.Item
                      name='showHistoricalStatistics'
                      label={<span>Show Historical Reports</span>}
                      valuePropName='checked'>
                      <Switch checkedChildren='Yes' unCheckedChildren='No' />
                    </Form.Item>
                    {showHistoricalStatistics && (
                      <>
                        <Form.Item
                          label='Historical Statistics'
                          name='historicalStatistics'
                          rules={[
                            {
                              required: true,
                              message: 'Please select a historical statistic'
                            }
                          ]}>
                          <Select mode='multiple'>
                            {Object.keys(ReportStatisticsOperator)
                              .filter(operator =>
                                isHistoricalStatisticsOperator(operator)
                              )
                              .filter(operator =>
                                statisticsValue.some(
                                  (statVal: string) => statVal === operator
                                )
                              )
                              .map((operator, index) => (
                                <Select.Option key={index} value={operator}>
                                  {startCase(
                                    capitalize(
                                      ReportStatisticsOperatorDisplayName[
                                        operator
                                      ]
                                    )
                                  )}
                                </Select.Option>
                              ))}
                          </Select>
                        </Form.Item>
                        <Form.Item
                          label={
                            <div>
                              Historical Statistics Types{' '}
                              <Tooltip
                                title={
                                  `Select the preferred report run aggregation.` +
                                  `The "Last Run" option will derive the statistic from the last report run of the previous days.` +
                                  `The "Cumulative" option will derive the statistic from the current timestamp report run of the previous days.`
                                }>
                                <InfoCircleOutlined />
                              </Tooltip>
                            </div>
                          }
                          name='historicalStatisticsTypes'
                          rules={[
                            {
                              required: true,
                              message:
                                'Please select a historical statistics type'
                            }
                          ]}>
                          <Select mode='multiple'>
                            {Object.keys(ReportHistoricalStatisticsType).map(
                              (type, index) => (
                                <Select.Option key={index} value={type}>
                                  {startCase(
                                    capitalize(
                                      ReportHistoricalStatisticsType[type]
                                    )
                                  )}
                                </Select.Option>
                              )
                            )}
                          </Select>
                        </Form.Item>
                        <Form.Item
                          label='Number of previous days'
                          name='noOfPreviousDaysReportsToUse'
                          rules={[
                            {
                              required: true,
                              message: 'Please add an entry for Number of Days'
                            }
                          ]}>
                          <Form.List
                            name='noOfPreviousDaysReportsToUse'
                            rules={[
                              {
                                validator: async (
                                  _,
                                  noOfPreviousDaysReportsToUse
                                ) => {
                                  if (
                                    !noOfPreviousDaysReportsToUse ||
                                    noOfPreviousDaysReportsToUse.length === 0
                                  ) {
                                    return Promise.reject(
                                      new Error(
                                        'At least one entry required for Number of Days'
                                      )
                                    );
                                  } else return Promise.resolve();
                                }
                              }
                            ]}>
                            {(fields, { add, remove }, { errors }) => {
                              return (
                                <>
                                  {fields.map(
                                    ({ key, name, ...restFields }, index) => (
                                      <React.Fragment key={index}>
                                        {(isMobile || isTablet) && index > 0 && (
                                          <Divider
                                            dashed
                                            style={{
                                              marginTop: 0,
                                              width: '120%'
                                            }}
                                          />
                                        )}
                                        <Space
                                          key={key}
                                          size={'middle'}
                                          className='form-input-multi-spaced'
                                          align='baseline'>
                                          <Form.Item
                                            {...restFields}
                                            name={name}
                                            label='No of Days'
                                            rules={[
                                              {
                                                required: true,
                                                message:
                                                  'Please add the no of previous days to generate historical reports'
                                              }
                                            ]}>
                                            <InputNumber min={1} />
                                          </Form.Item>
                                          <MinusCircleOutlined
                                            style={{ color: 'red' }}
                                            onClick={() => remove(name)}
                                          />
                                        </Space>
                                      </React.Fragment>
                                    )
                                  )}
                                  <Form.Item>
                                    <Button
                                      type='dashed'
                                      onClick={() => {
                                        const noOfDays = form.getFieldsValue([
                                          'noOfPreviousDaysReportsToUse'
                                        ]);
                                        let emptyExists = false;
                                        noOfDays &&
                                          Object.values(noOfDays)
                                            .flat()
                                            .forEach(val => {
                                              if (val === undefined)
                                                emptyExists = true;
                                            });

                                        if (emptyExists)
                                          message.warning(
                                            'Please fill all details to add another no of days entry'
                                          );
                                        else add();
                                      }}
                                      block
                                      icon={<PlusOutlined />}>
                                      Add No of Days Entry
                                    </Button>
                                    <Form.ErrorList errors={errors} />
                                  </Form.Item>
                                </>
                              );
                            }}
                          </Form.List>
                        </Form.Item>
                        <Form.Item
                          label='Exclusion Dates (EST)'
                          rules={[{ required: false }]}>
                          <Form.List name='exclusionDates'>
                            {(fields, { add, remove }) => {
                              return (
                                <>
                                  {fields.map(
                                    ({ key, name, ...restFields }, index) => (
                                      <React.Fragment key={index}>
                                        {(isMobile || isTablet) && index > 0 && (
                                          <Divider
                                            dashed
                                            style={{
                                              marginTop: 0,
                                              width: '120%'
                                            }}
                                          />
                                        )}
                                        <Space
                                          key={key}
                                          size={'middle'}
                                          className='form-input-multi-spaced'
                                          align='baseline'>
                                          <Form.Item
                                            {...restFields}
                                            name={[name]}
                                            label='Exclusion Date'
                                            rules={[
                                              {
                                                required: false,
                                                message: 'Please select a date'
                                              }
                                            ]}>
                                            <DatePicker
                                              format={
                                                MomentConstants.DATE_ALONE
                                              }
                                            />
                                          </Form.Item>
                                          <MinusCircleOutlined
                                            style={{ color: 'red' }}
                                            onClick={() => remove(name)}
                                          />
                                        </Space>
                                      </React.Fragment>
                                    )
                                  )}
                                  <Form.Item>
                                    <Button
                                      type='dashed'
                                      onClick={() => {
                                        let emptyExists = false;
                                        exclusionDates &&
                                          exclusionDates.forEach((ed: any) => {
                                            if (ed === undefined)
                                              emptyExists = true;
                                          });
                                        if (emptyExists)
                                          message.warning(
                                            'Please fill all details to add another exclusion date'
                                          );
                                        else add();
                                      }}
                                      block
                                      icon={<PlusOutlined />}>
                                      Add Exclusion Dates
                                    </Button>
                                  </Form.Item>
                                </>
                              );
                            }}
                          </Form.List>
                        </Form.Item>
                      </>
                    )}
                  </>
                )}
            </>
          )}

          <Form.Item
            wrapperCol={{
              xl: { offset: 8, span: 16 },
              lg: { offset: 4, span: 20 },
              md: { offset: 2, span: 22 }
            }}>
            <Typography.Text strong italic>
              {getReportRunSummaryString()}
            </Typography.Text>
          </Form.Item>

          <Form.Item
            wrapperCol={{
              xl: { offset: 8, span: 16 },
              lg: { offset: 4, span: 16 },
              md: { offset: 6, span: 18 }
            }}>
            <Space size='middle' wrap>
              <Button type='primary' htmlType='submit' loading={loading}>
                {reportId ? 'Update Report' : 'Create Report'}
              </Button>
              <Button
                type='primary'
                key='preview_report'
                loading={previewReportLoading}
                onClick={handleOnFinishReportPreview}>
                Preview Report
              </Button>
              <Button
                htmlType='button'
                type='primary'
                danger
                onClick={() =>
                  navigate(
                    reportDetail
                      ? RouteConstants.REPORT_DETAILS.replace(
                          ':reportId',
                          reportId as string
                        )
                      : RouteConstants.REPORTS
                  )
                }>
                Cancel
              </Button>
            </Space>
          </Form.Item>
        </Form>
      </PageHeader>

      {previewPopUpVisibility && (
        <ReportsPreviewModal
          visible={previewPopUpVisibility}
          reportResult={previewReportData}
          handleCancel={handleCancelPreviewReport}
        />
      )}
    </>
  ) : (
    <EmptyDataComponent title='Report not found' />
  );
};

export default SecureComponent(CreateOrEDitReportPage, true);
