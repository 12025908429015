export type PaginatedType<T> = {
  data: T[];
  totalCount: number;
  pagination: any;
};

export const InitialValue = {
  data: [],
  totalCount: 0,
  pagination: {}
};
