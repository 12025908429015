import {
  ArrowDownOutlined,
  ArrowUpOutlined,
  LineOutlined
} from '@ant-design/icons';

import { BaseType } from 'antd/lib/typography/Base';
import { ReportRunStatisticsEffect } from '../types/data/report-activities.types';

export class ReportConstants {
  public static readonly ReportStatisticsOverviewMetaDetails: {
    [key: string]: {
      textType?: BaseType;
      tagColor: string;
      tagIcon?: any;
      tagContent?: any;
    };
  } = {
    [`TotalRuns,${ReportRunStatisticsEffect.INCREASE}`]: {
      textType: 'success',
      tagColor: 'green',
      tagIcon: <ArrowUpOutlined />
    },
    [`TotalRuns,${ReportRunStatisticsEffect.DECREASE}`]: {
      textType: 'danger',
      tagColor: 'red',
      tagIcon: <ArrowDownOutlined />
    },
    [`TotalRuns,${ReportRunStatisticsEffect.NIL}`]: {
      tagColor: 'default',
      tagContent: <LineOutlined />
    },
    [`SuccessfulRuns,${ReportRunStatisticsEffect.INCREASE}`]: {
      textType: 'success',
      tagColor: 'green',
      tagIcon: <ArrowUpOutlined />
    },
    [`SuccessfulRuns,${ReportRunStatisticsEffect.DECREASE}`]: {
      textType: 'danger',
      tagColor: 'red',
      tagIcon: <ArrowDownOutlined />
    },
    [`SuccessfulRuns,${ReportRunStatisticsEffect.NIL}`]: {
      tagColor: 'default',
      tagContent: <LineOutlined />
    },
    [`FailedRuns,${ReportRunStatisticsEffect.INCREASE}`]: {
      textType: 'danger',
      tagColor: 'red',
      tagIcon: <ArrowUpOutlined />
    },
    [`FailedRuns,${ReportRunStatisticsEffect.DECREASE}`]: {
      textType: 'success',
      tagColor: 'green',
      tagIcon: <ArrowDownOutlined />
    },
    [`FailedRuns,${ReportRunStatisticsEffect.NIL}`]: {
      tagColor: 'default',
      tagContent: <LineOutlined />
    },
    [`NoDataReports,${ReportRunStatisticsEffect.INCREASE}`]: {
      textType: 'danger',
      tagColor: 'red',
      tagIcon: <ArrowUpOutlined />
    },
    [`NoDataReports,${ReportRunStatisticsEffect.DECREASE}`]: {
      textType: 'success',
      tagColor: 'green',
      tagIcon: <ArrowDownOutlined />
    },
    [`NoDataReports,${ReportRunStatisticsEffect.NIL}`]: {
      tagColor: 'default',
      tagContent: <LineOutlined />
    }
  };
}
