import { Card, Col, Modal, Row, Space, Tooltip, message } from 'antd';
import {
  DeleteOutlined,
  EditOutlined,
  ExpandAltOutlined
} from '@ant-design/icons';
import { useEffect, useState } from 'react';

import ChartComponent from '../chart-layout';
import ConfirmationModal from '../../../modals/confirmation';
import CreateOrEditWidget from '../create-or-edit-chart';
import LoadingComponent from '../../../components/loading';
import { ReportService } from '../../../service/report.service';
import { WidgetService } from '../../../service/chart.service';
import capitalize from 'lodash.capitalize';
import isUndefined from 'lodash.isundefined';
import { useParams } from 'react-router-dom';

const { Meta } = Card;

type WidgetProps = {
  reportId: any;
  widgetId: string[];
  numericColumns: any;
  displayNames: any;
  isAdmin: boolean;
};

const SavedCharts: React.FC<WidgetProps> = (props: WidgetProps) => {
  const { reportId } = useParams();

  const [widgetLoading, setWidgetLoading] = useState(true);
  const [widgetData, setWidgetData] = useState<any[]>([]);

  const [expanded, setExpanded] = useState(false);

  const [selectedWidget, setSelectedWidget] = useState<any>();
  const [editWidget, setEditWidget] = useState(false);
  const [editWidgetData, setEditWidgetData] = useState<any>();

  const [widgetVisible, setWidgetVisible] = useState(false);

  const handleRemoveWidgetFromReportCancel = () => {
    setWidgetLoading(false);
    setSelectedWidget(undefined);
    setWidgetVisible(false);
  };

  const handleDeleteWidget = (data: any) => {
    setSelectedWidget(data);
    setWidgetVisible(true);
  };

  const handleExpandWidget = (data: any) => {
    setSelectedWidget(data);
    setExpanded(true);
  };

  const handleCancel = () => {
    setSelectedWidget(undefined);
    setExpanded(false);
  };

  const handleEditCancel = () => {
    setEditWidget(false);
  };

  const handleEditWidget = (data: any) => {
    setEditWidgetData(data);
    setEditWidget(true);
  };

  const getWidgetDetails = (reportId: string, widgetId: string[]) => {
    setWidgetLoading(true);
    Promise.all(widgetId.map(wid => WidgetService.getWidgetById(reportId, wid)))
      .then(widgetResponseData => {
        const updatedWidgetData = widgetResponseData.map(data => {
          return data;
        });
        setWidgetData(updatedWidgetData);
        setWidgetLoading(false);
      })
      .catch(err => {
        console.log('Error while fetching report widget details ', err);
        message.error(
          'Failed to fetch report widget details, please try again later'
        );
        setWidgetLoading(false);
      });
  };

  const [loadData, setLoadData] = useState(false);
  const handleReload = () => {
    if (loadData === true) setLoadData(false);
    else setLoadData(true);
  };

  const DeleteWidgetOk = () => {
    setWidgetLoading(true);
    WidgetService.deleteWidgetById(
      selectedWidget?.reportId as string,
      selectedWidget?.id as string
    )
      .then(() => {
        message.success('Widget successfully deleted');
        handleReload();
      })
      .catch(err => {
        console.log('Error while deleting widget :: ', err);
        message.error('Error while deleting widget');
      })
      .finally(() => {
        setWidgetLoading(false);
        setSelectedWidget(undefined);
        setWidgetVisible(false);
      });
  };

  useEffect(() => {
    setWidgetLoading(true);
    ReportService.getReportById(reportId as string)
      .then(report => {
        getWidgetDetails(reportId as string, report.widgets as any);
      })
      .catch(err => {
        console.log('Error while fetching report', err);
        message.error('Failed to fetch report detail, please try again later');
        setWidgetLoading(false);
      });
  }, [loadData]);

  if (widgetLoading) return <LoadingComponent tip='Loading Widgets...' />;
  else if (widgetData && !isUndefined(widgetData))
    return (<>
      <Row justify='space-evenly'>
        <Col span={24}>
          <Space direction='vertical' style={{ display: 'flex' }}>
            <Row>
              {widgetData?.map((widget, index) => (
                <Col span={12} key={index}>
                  <Card
                    loading={widgetLoading}
                    headStyle={{ color: 'gray' }}
                    style={{ height: '100%' }}>
                    <Meta
                      style={{ display: 'inline' }}
                      title={
                        <>
                          <span>{widget?.name}</span>
                          <span style={{ marginLeft: '5px' }}>
                            <>
                              {widget?.dateRangeType !== 'STATIC' ? (
                                <>
                                  (
                                  {capitalize(
                                    widget?.dateRangeType.replaceAll('_', ' ')
                                  )}
                                  )
                                </>
                              ) : (
                                <>
                                  ({' '}
                                  {widget?.staticDateRange.from.split('T')[0]}
                                  / {widget?.staticDateRange.to.split('T')[0]}
                                  )
                                </>
                              )}
                            </>
                          </span>
                          <div style={{ float: 'right' }}>
                            {props.isAdmin && (
                              <Tooltip title='Edit'>
                                <EditOutlined
                                  key='edit'
                                  style={{
                                    marginRight: '1px',
                                    padding: '5px'
                                  }}
                                  onClick={() => handleEditWidget(widget)}
                                />
                              </Tooltip>
                            )}
                            {props.isAdmin && (
                              <Tooltip title='Delete' key='delete'>
                                <DeleteOutlined
                                  key='delete'
                                  style={{
                                    marginRight: '1px',
                                    padding: '5px'
                                  }}
                                  onClick={() => handleDeleteWidget(widget)}
                                />
                              </Tooltip>
                            )}
                            <Tooltip title='Expand'>
                              <ExpandAltOutlined
                                key='expand'
                                style={{ marginRight: '1px', padding: '5px' }}
                                onClick={() => handleExpandWidget(widget)}
                              />
                            </Tooltip>
                          </div>
                        </>
                      }
                      description={
                        <>
                          <hr
                            style={{
                              borderTop: '1px solid #f0f0f0',
                              margin: '8px 0'
                            }}
                          />

                          <ChartComponent
                            dataset={widget}
                            chartType={widget.chartType}
                          />
                        </>
                      }
                    />
                  </Card>
                </Col>
              ))}
            </Row>
          </Space>
        </Col>
      </Row>
      <Modal
        open={expanded}
        onCancel={handleCancel}
        footer={null}
        width={'50%'}
        centered>
        <Card
          loading={widgetLoading}
          headStyle={{ color: 'gray' }}
          style={{ height: '100%', marginTop: '20px' }}>
          <Meta
            style={{ display: 'inline' }}
            title={
              <>
                <span>{selectedWidget?.name}</span>
                <span style={{ marginLeft: '10px' }}>
                  <>
                    {selectedWidget?.dateRangeType !== 'STATIC' ? (
                      <>
                        (
                        {capitalize(
                          selectedWidget?.dateRangeType.replaceAll('_', ' ')
                        )}
                        )
                      </>
                    ) : (
                      <>
                        ( {selectedWidget?.staticDateRange.from.split('T')[0]}
                        / {selectedWidget?.staticDateRange.to.split('T')[0]})
                      </>
                    )}
                  </>
                </span>
              </>
            }
            description={
              <>
                <hr
                  style={{
                    borderTop: '1px solid #f0f0f0',
                    margin: '8px 0'
                  }}
                />
                <ChartComponent
                  dataset={selectedWidget}
                  chartType={selectedWidget?.chartType}
                />
              </>
            }
          />
        </Card>
      </Modal>
      {editWidget && (
        <CreateOrEditWidget
          editWidgetData={editWidgetData}
          numericColumns={props.numericColumns}
          displayNames={props.displayNames}
          handleCancel={handleEditCancel}
          reportId={props.reportId}
          handleReload={handleReload}
        />
      )}
      <ConfirmationModal
        title={`Are you sure to remove widget (${selectedWidget?.name})?`}
        visible={widgetVisible}
        danger={true}
        handleOk={DeleteWidgetOk}
        handleCancel={handleRemoveWidgetFromReportCancel}
        content={`This action would remove widget (${selectedWidget?.name}) from this report.`}
      />
    </>);
  else return <LoadingComponent tip='Failed to load report widget...' />;
};

export default SavedCharts;
