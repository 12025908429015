const baseURL = process.env.REACT_APP_BACKEND_BASE_URL + '/api/v1';

export const UrlConstants = {
  // USER APIs
  GET_USER_DETAIL: baseURL + '/user/details',
  GET_ALL_USERS: baseURL + '/user',
  DELETE_USER: baseURL + '/user/:userId',
  ADD_USER: baseURL + '/user/add',

  //UserGuide
  GET_USER_GUIDE: baseURL + '/report/userguide',

  // GROUP APIs
  CREATE_GROUP: baseURL + '/group',
  GET_ALL_GROUPS: baseURL + '/group',
  GET_GROUP_BY_ID: baseURL + '/group/:groupId',
  UPDATE_GROUP_BY_ID: baseURL + '/group/:groupId',
  DELETE_GROUP_BY_ID: baseURL + '/group/:groupId',
  ADD_USERS_TO_GROUP: baseURL + '/group/:groupId/users/add',
  REMOVE_USERS_FROM_GROUP: baseURL + '/group/:groupId/users/remove',

  // REPORT APIs
  CREATE_REPORT: baseURL + '/report',
  GET_ALL_REPORTS: baseURL + '/report',
  GET_REPORT_BY_ID: baseURL + '/report/:reportId',
  UPDATE_REPORT_BY_ID: baseURL + '/report/:reportId',
  DELETE_REPORT_BY_ID: baseURL + '/report/:reportId',
  ADD_GROUPS_TO_REPORT: baseURL + '/report/:reportId/groups/add',
  REMOVE_GROUPS_FROM_REPORT: baseURL + '/report/:reportId/groups/remove',
  REMOVE_USERS_FROM_REPORT: baseURL + '/report/:reportId/users/remove',
  GET_ALL_RESULTS_FOR_REPORT: baseURL + '/report/:reportId/result',
  GET_REPORT_RECIPIENTS_BY_ID: baseURL + '/report/:reportId/recipients',
  GET_RESULT_FOR_REPORT_BY_ID: baseURL + '/report/:reportId/result/:resultId',
  PREVIEW_REPORT: baseURL + '/report/preview',

  // Report Activity APIs
  GET_REPORT_STATS_OVERVIEW:
    baseURL + '/report/:reportId/result/stats/overview',
  GET_REPORT_STATUS_BY_ID: baseURL + '/report/:reportId/audit',

  //Chart-widget APIs
  PREVIEW_WIDGET: baseURL + '/report/:reportId/widget/preview',
  CREATE_WIDGET: baseURL + '/report/:reportId/widget',
  UPDATE_WIDGET_BY_ID: baseURL + '/report/:reportId/widget/:widgetId',
  GET_WIDGET_BY_ID: baseURL + '/report/:reportId/widget/:widgetId',
  DELETE_WIDGET_BY_ID: baseURL + '/report/:reportId/widget/:widgetId'
};
