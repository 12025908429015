import './index.css';

import App from './App';
import { BrowserRouter } from 'react-router-dom';
import { MsalProvider } from '@azure/msal-react';
import { PublicClientApplication } from '@azure/msal-browser';
import React from 'react';
import { createRoot } from 'react-dom/client';
import { msalConfiguration } from './configurations/msal.configuration';

const msalInstance: PublicClientApplication = new PublicClientApplication(
  msalConfiguration
);

const root = createRoot(document.getElementById('root') as HTMLElement);

root.render(
  <>
    <MsalProvider instance={msalInstance}>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </MsalProvider>
  </>
);
