export enum ReportStatisticsOperator {
  TOTAL = 'TOTAL',
  AVERAGE = 'AVERAGE',
  MAXIMUM = 'MAXIMUM',
  MINIMUM = 'MINIMUM',
  SUB_TOTAL = 'SUB_TOTAL',
  SUB_AVERAGE = 'SUB_AVERAGE'
}

export enum ReportStatisticsOperatorDisplayName {
  TOTAL = 'TOTAL',
  AVERAGE = 'AVERAGE',
  MAXIMUM = 'MAXIMUM',
  MINIMUM = 'MINIMUM',
  SUB_TOTAL = 'SUB TOTAL',
  SUB_AVERAGE = 'SUB AVERAGE'
}
