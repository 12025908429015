import { Button, Space } from 'antd';

import { ColumnsType } from 'antd/lib/table';
import { DeleteOutlined } from '@ant-design/icons';
import { Report } from '../../types/data/report.type';
import { User } from '../../types/data/user.type';
import { UsersTableProps } from '../../types/props/users-table.props';
import isEmpty from 'lodash.isempty';
import isUndefined from 'lodash.isundefined';

// import { Link } from 'react-router-dom';

// import { RouteConstants } from '../../constants/route.constants';

/**
 * get users columns
 * @returns {ColumnsType<User>}
 */
export const getIndividualUserColumns = (
  props: UsersTableProps
): ColumnsType<User> => [
  {
    title: 'Name',
    dataIndex: 'name',
    key: 'name',
    sorter: (a, b) => a.name.localeCompare(b.name)
  },
  {
    title: 'Email Address',
    dataIndex: 'email',
    key: 'email',
    render: (email: string) => (email ? email : '-')
  },
  {
    title: 'No. of Groups',
    dataIndex: 'groups',
    key: 'groups',
    render: (users: User[]) => (isEmpty(users) ? 0 : users.length),
    sorter: (a, b) => {
      const a_users = isUndefined(a.groups) ? 0 : a.groups.length,
        b_users = isUndefined(b.groups) ? 0 : b.groups.length;
      return a_users - b_users;
    }
  },
  {
    title: 'No. of Reports',
    dataIndex: 'reports',
    key: 'reports',
    render: (reports: Report[]) => (isEmpty(reports) ? 0 : reports.length),
    sorter: (a, b) => {
      const a_reports = isUndefined(a.reports) ? 0 : a.reports.length,
        b_reports = isUndefined(b.reports) ? 0 : b.reports.length;
      return a_reports - b_reports;
    }
  },
  {
    title: '',
    key: 'view-group',
    render: data => (
      <Space>
        <Button
          type='primary'
          danger
          onClick={() => props.handleUserRemoval?.(data)}>
          <DeleteOutlined /> Remove
        </Button>
      </Space>
    )
  }
];
