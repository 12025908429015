import { ApiUtils } from '../utils/api.utils';
import { ReportHistory } from '../types/data/report-history.type';
import { ReportStatisticsOverview } from '../types/data/report-activities.types';
import { UrlConstants } from '../constants/url.constants';
import axios from 'axios';

const createReport = (data: any): Promise<any> => {
  return new Promise((resolve, reject) =>
    axios
      .post(
        UrlConstants.CREATE_REPORT,
        { ...data, isActive: true },
        ApiUtils.getHeaders()
      )
      .then(response => {
        if (response.status === 200 || response.status === 201)
          resolve(response.data);
        else throw response;
      })
      .catch(err => reject(err.response.data))
  );
};

const getAllReports = (params?: any): Promise<any> => {
  return new Promise((resolve, reject) => {
    const url: string = ApiUtils.addQueryParamsToUrl(
      UrlConstants.GET_ALL_REPORTS,
      params
    );
    axios
      .get(url, ApiUtils.getHeaders())
      .then(response => {
        if (response.status === 200) resolve(response.data);
        else throw response;
      })
      .catch(err => reject(err.response.data));
  });
};

const getReportById = (reportId: string): Promise<any> => {
  const url = UrlConstants.GET_REPORT_BY_ID.replaceAll(':reportId', reportId);
  return new Promise((resolve, reject) =>
    axios
      .get(url, ApiUtils.getHeaders())
      .then(response => {
        if (response.status === 200) resolve(response.data.data);
        else throw response;
      })
      .catch(err => reject(err.response.data))
  );
};

const getReportRecipientsById = (reportId: string): Promise<any> => {
  const url = UrlConstants.GET_REPORT_RECIPIENTS_BY_ID.replaceAll(
    ':reportId',
    reportId
  );
  return new Promise((resolve, reject) =>
    axios
      .get(url, ApiUtils.getHeaders())
      .then(response => {
        if (response.status === 200) {
          resolve(response.data.data);
        } else throw response;
      })
      .catch(err => reject(err.response.data))
  );
};

const updateReport = (reportId: string, data: any): Promise<any> => {
  const url = UrlConstants.UPDATE_REPORT_BY_ID.replaceAll(
    ':reportId',
    reportId
  );
  return new Promise((resolve, reject) =>
    axios
      .patch(url, data, ApiUtils.getHeaders())
      .then(response => {
        if (response.status === 200) resolve(response.data);
        else throw response;
      })
      .catch(err => reject(err.response.data))
  );
};

const deleteReport = (reportId: string): Promise<any> => {
  const url = UrlConstants.DELETE_REPORT_BY_ID.replaceAll(
    ':reportId',
    reportId
  );
  return new Promise((resolve, reject) => {
    axios
      .delete(url, ApiUtils.getHeaders())
      .then(response => {
        if (response.status === 200 || response.status === 204)
          resolve(response.data);
        else throw response;
      })
      .catch(err => reject(err.response.data));
  });
};

const addGroupsToReport = (reportId: string, data: any): Promise<any> => {
  const url = UrlConstants.ADD_GROUPS_TO_REPORT.replaceAll(
    ':reportId',
    reportId
  );
  return new Promise((resolve, reject) => {
    axios
      .patch(url, data, ApiUtils.getHeaders())
      .then(response => {
        if (response.status === 200 || response.status === 204)
          resolve(response.data);
        else throw response;
      })
      .catch(err => reject(err.response.data));
  });
};

const removeGroupsFromReport = (reportId: string, data: any): Promise<any> => {
  const url = UrlConstants.REMOVE_GROUPS_FROM_REPORT.replaceAll(
    ':reportId',
    reportId
  );
  return new Promise((resolve, reject) => {
    axios
      .patch(url, data, ApiUtils.getHeaders())
      .then(response => {
        if (response.status === 200 || response.status === 204)
          resolve(response.data);
        else throw response;
      })
      .catch(err => reject(err.response.data));
  });
};

const removeUsersFromReport = (reportId: string, data: any): Promise<any> => {
  const url = UrlConstants.REMOVE_USERS_FROM_REPORT.replaceAll(
    ':reportId',
    reportId
  );
  return new Promise((resolve, reject) => {
    axios
      .patch(url, data, ApiUtils.getHeaders())
      .then(response => {
        if (response.status === 200 || response.status === 204)
          resolve(response.data);
        else throw response;
      })
      .catch(err => reject(err.response.data));
  });
};

const getAllResultsForReport = (
  reportId: string,
  params?: any
): Promise<any> => {
  const url = ApiUtils.addQueryParamsToUrl(
    UrlConstants.GET_ALL_RESULTS_FOR_REPORT.replaceAll(':reportId', reportId),
    params
  );
  return new Promise((resolve, reject) =>
    axios
      .get(url, ApiUtils.getHeaders())
      .then(response => {
        if (response.status === 200) resolve(response.data);
        else throw response;
      })
      .catch(err => reject(err.response.data))
  );
};

const previewReport = (
  data: any,
  reportId?: string
): Promise<ReportHistory> => {
  return new Promise((resolve, reject) => {
    axios
      .post(
        UrlConstants.PREVIEW_REPORT,
        { ...data, reportId },
        ApiUtils.getHeaders()
      )
      .then((response: any) => {
        if (response.status === 201) {
          resolve(response.data as ReportHistory);
        } else throw response;
      })
      .catch(err => reject(err.response.data));
  });
};

const getResultForReportById = (
  reportId: string,
  reportResultId: string
): Promise<any> => {
  const url = UrlConstants.GET_RESULT_FOR_REPORT_BY_ID.replaceAll(
    ':reportId',
    reportId
  ).replaceAll(':resultId', reportResultId);
  return new Promise((resolve, reject) => {
    axios
      .get(url, ApiUtils.getHeaders())
      .then(response => {
        if (response.status === 200) {
          resolve(response.data.data);
        } else throw response;
      })
      .catch(err => {
        reject(err.response.data);
      });
  });
};

/**
 * Service function to call backend api to get stats overview for last 7 days
 * @param {string} reportId - id of the report
 * @returns {ReportStatisticsOverview} - report stats overview
 */
const getReportStatisticsOverview = (
  reportId: string
): Promise<ReportStatisticsOverview> => {
  const url = UrlConstants.GET_REPORT_STATS_OVERVIEW.replaceAll(
    ':reportId',
    reportId
  );

  return new Promise((resolve, reject) => {
    axios
      .get(url, ApiUtils.getHeaders())
      .then(response => {
        if (response.status === 200) {
          resolve(response.data.data);
        } else throw response;
      })
      .catch(err => {
        reject(err.response.data);
      });
  });
};

/**
 * Service function to call backend api to get report audit details
 * @param {string} reportId - id of the report
 * @returns {any} - report audit details
 */

const getReportStatusById = (reportId: string): Promise<any> => {
  const url = UrlConstants.GET_REPORT_STATUS_BY_ID.replaceAll(
    ':reportId',
    reportId
  );
  return new Promise((resolve, reject) =>
    axios
      .get(url, ApiUtils.getHeaders())
      .then(response => {
        if (response.status === 200) {
          resolve(response.data.data);
        } else throw response;
      })
      .catch(err => reject(err))
  );
};

export const ReportService = {
  createReport,
  getAllReports,
  getReportById,
  updateReport,
  deleteReport,
  previewReport,
  addGroupsToReport,
  removeGroupsFromReport,
  removeUsersFromReport,
  getAllResultsForReport,
  getResultForReportById,
  getReportRecipientsById,
  getReportStatisticsOverview,
  getReportStatusById
};
