import {
  Button,
  Col,
  DatePicker,
  Form,
  FormInstance,
  Input,
  Modal,
  Radio,
  Row,
  Select,
  Space,
  TreeSelect,
  Typography,
  message
} from 'antd';
import {
  CloseCircleOutlined,
  EditOutlined,
  SaveOutlined
} from '@ant-design/icons';
import React, { useEffect, useState } from 'react';
import {
  ReportStatisticsOperator,
  ReportStatisticsOperatorDisplayName
} from '../../../enums/report-statistics-operator.enum';

import ChartComponent from '../chart-layout';
import { DateRangeType } from '../../../enums/chart-time-operator.enum';
import EmptyDataComponent from '../../../components/empty-data';
import LoadingComponent from '../../../components/loading';
import { PageHeader } from '@ant-design/pro-layout';
import { WidgetService } from '../../../service/chart.service';
import capitalize from 'lodash.capitalize';
import dayjs from 'dayjs';
import isEmpty from 'lodash.isempty';
import startCase from 'lodash.startcase';

type WidgetDataProp = {
  handleCancel: (data: any) => void;
  reportId: any;
  displayNames: any;
  numericColumns: any;
  editWidgetData?: any;
  handleReload?: any;
  reloadData?: any;
};

const CreateOrEditWidget: React.FC<WidgetDataProp> = (
  props: WidgetDataProp
) => {
  const createModalRef: React.RefObject<FormInstance> =
    React.createRef<FormInstance>();

  const createModalTimeRef: React.RefObject<FormInstance> =
    React.createRef<FormInstance>();

  const [widgetEditDetail, setWidgetEditDetail] = useState<any>();

  const [loading, setLoading] = React.useState(false);

  const [Dataset, setDataset] = useState({});
  const [previewChartData, setPreviewChartData] = useState();

  const [chartType, setChartType] = useState('bar');
  const [onBlur, setOnBlur] = useState(false);

  const [selectedOption, setSelectedOption] = useState('');

  const [form] = Form.useForm();
  const columns = Form.useWatch('columns', form);

  const handleColumnsChange = (selectedColumns: any) => {
    if (selectedColumns.includes('All')) {
      form.setFieldsValue({ columns: ['All'] });
    }
  };

  const formateChartData = (data: any) => {
    try {
      if (data.columns?.includes('All')) {
        delete data.columns;
        data.columns = props.numericColumns.map(
          (column: string) => props.displayNames?.[column] || column
        );
      }

      if (
        data.dateRangeType === DateRangeType.STATIC &&
        data.staticDateRange?.length === 2
      ) {
        const from = data.staticDateRange[0].format('YYYY-MM-DDTHH:mm:ss.SSSZ');
        const to = data.staticDateRange[1].format('YYYY-MM-DDTHH:mm:ss.SSSZ');
        delete data.staticDateRange;
        data.staticDateRange = { from: from, to: to };
      }
      //for edit form fail to set initial value
      if (
        data.dateRangeType === DateRangeType.STATIC &&
        isEmpty(data.staticDateRange) &&
        !isEmpty(widgetEditDetail)
      ) {
        const from = widgetEditDetail.staticDateRange.from;
        const to = widgetEditDetail.staticDateRange.to;
        data.staticDateRange = { from: from, to: to };
      }

      return data;
    } catch (err) {
      console.log('Error while formatting preview widget for report :: ', err);
    }
  };

  const handleFormBlur = async () => {
    try {
      if ((props.editWidgetData && widgetEditDetail) || !props.editWidgetData) {
        const [timeRange, formData] = await Promise.all([
          createModalTimeRef.current?.validateFields(),
          createModalRef.current?.validateFields()
        ]);

        const data = { ...formData, ...timeRange };

        const chartData = formateChartData(data);

        setChartType(chartData.chartType);
        delete chartData.chartType;
        delete chartData.name;

        if (
          chartData.dateRangeType !== DateRangeType.STATIC ||
          (chartData.dateRangeType === DateRangeType.STATIC &&
            Object.keys(chartData.staticDateRange)?.length === 2)
        ) {
          setOnBlur(true);
          WidgetService.previewWidget(chartData, props.reportId)
            .then(data => {
              setPreviewChartData(data.data);
              setDataset(data.data);
              setOnBlur(false);
            })
            .catch(err => {
              console.log(
                'Error while generating preview widget for report :: ',
                err
              );
              message.error(
                'Unable to generate preview, please try again later.'
              );

              setOnBlur(false);
            });
        }
      }
    } catch (error) {
      // Handle form validation error
      console.log(`Error while fetching widget data form::  ${error}`);
      setOnBlur(false);
    }
  };

  const handleChartType = async () => {
    const formData = await createModalRef.current?.validateFields();
    if (Dataset) setChartType(formData.chartType);
  };

  const saveWidget = async () => {
    try {
      setLoading(true);
      const [timeRange, formData] = await Promise.all([
        createModalTimeRef.current?.validateFields(),
        createModalRef.current?.validateFields()
      ]);

      const data = { ...formData, ...timeRange };

      const chartData = formateChartData(data);

      if (previewChartData)
        chartData.columnColor = (previewChartData as any)?.columnColor;

      if (props.editWidgetData) {
        WidgetService.updateWidget(
          props.editWidgetData.reportId,
          props.editWidgetData.id,
          chartData
        )
          .then((data: any) => {
            setLoading(false);
            if (data.response?.status !== 400) {
              message.success('Widget updated successfully');
              props.handleCancel(data);
              props.handleReload();
            } else throw data.response.data;
          })
          .catch(err => {
            console.log('Error while updated widget', err);
            message.error('Failed to updated widget, please try again later');
            setLoading(false);
          });
      } else {
        WidgetService.createWidget(chartData, props.reportId)
          .then((data: any) => {
            setLoading(false);
            if (data.response?.status !== 400) {
              message.success('Widget saved successfully');
              props.handleCancel(data);
              props.reloadData();
            } else throw data.response.data;
          })
          .catch(err => {
            console.log('Error while saving widget', err);
            message.error('Failed to save widget, please try again later');
            setLoading(false);
          });
      }
    } catch (err) {
      console.log('Form has validation errors :: ', err);
      setLoading(false);
    }
  };

  useEffect(() => {
    if (widgetEditDetail) {
      setSelectedOption(widgetEditDetail.dateRangeType);
      handleFormBlur();
    }
  }, [widgetEditDetail]);

  useEffect(() => {
    setWidgetEditDetail(props.editWidgetData);
    setSelectedOption('');
    handleFormBlur();
  }, [props.editWidgetData]);

  const ChartTypeData = [
    {
      value: 'bar',
      title: <b style={{ color: 'gray' }}>Bar</b>,
      disabled: true,
      children: [
        {
          value: 'BAR',
          title: 'Vertical Bar '
        },
        {
          value: 'STACKED_BAR',
          title: 'Stacked Bar'
        },
        {
          value: 'HORIZONTAL_BAR',
          title: 'Horizontal Bar '
        }
      ]
    },
    {
      value: 'line_',
      title: <b style={{ color: 'gray' }}>Line</b>,
      disabled: true,
      children: [
        {
          value: 'LINE',
          title: 'Line'
        }
      ]
    },
    {
      value: 'Pie',
      title: <b style={{ color: 'gray' }}>Pie</b>,
      disabled: true,
      children: [
        {
          value: 'PIE',
          title: 'Pie'
        },
        { value: 'DOUGHNUT', title: 'DoughNut' }
      ]
    },

    {
      value: 'Area',
      title: <b style={{ color: 'gray' }}>Area</b>,
      disabled: true,
      children: [
        {
          value: 'AREA',
          title: 'Area'
        }
      ]
    }
  ];

  const handleSelectChange = (value: any) => {
    setSelectedOption(value);
    handleFormBlur();
  };

  const handleDropdownVisibleChange = (visible: any) => {
    if (visible && selectedOption !== DateRangeType.STATIC) {
      setSelectedOption('');
    }
  };

  return (props.editWidgetData && widgetEditDetail) ||
    !props?.editWidgetData ? (
    <Modal
      open={true}
      centered={true}
      bodyStyle={{ width: '100%' }}
      footer={null}
      className='ant-modal-content'
      onCancel={props.handleCancel}
      width={'90%'}
      closeIcon={
        <>
          <Typography.Text strong>
            <CloseCircleOutlined />
          </Typography.Text>
        </>
      }>
      {isEmpty(props.numericColumns) ? (
        <EmptyDataComponent title='No Numeric columns available' />
      ) : (
        <>
          <Form
            ref={createModalTimeRef}
            initialValues={
              widgetEditDetail
                ? {
                    dateRangeType: widgetEditDetail.dateRangeType,
                    name: widgetEditDetail.name,
                    staticDateRange: [
                      dayjs(widgetEditDetail.staticDateRange?.from),
                      dayjs(widgetEditDetail.staticDateRange?.to)
                    ]
                  }
                : {
                    dateRangeType: DateRangeType.LAST_SEVEN_DAYS,
                    name: 'Widget'
                  }
            }>
            <PageHeader
              style={{ backgroundColor: 'white', borderRadius: 6 }}
              title={
                <Form.Item name='name'>
                  <div style={{ display: 'flex' }}>
                    <Input
                      type='string'
                      placeholder='Widget Name'
                      defaultValue={
                        widgetEditDetail?.name
                          ? widgetEditDetail?.name
                          : 'Widget'
                      }
                      bordered={false}
                      style={{
                        fontWeight: 'bold',
                        fontSize: '20px',
                        borderBottom: '1px solid #f0f0f0'
                      }}
                    />
                    <div style={{ marginTop: '13px' }}>
                      <EditOutlined style={{ fontSize: '17px' }} />
                    </div>
                  </div>
                </Form.Item>
              }
              extra={[
                <Space key='buttons'>
                  <Button
                    disabled={onBlur}
                    key='save_report'
                    type='primary'
                    loading={loading}
                    onClick={() => {
                      setLoading(true);
                      saveWidget();
                    }}>
                    <SaveOutlined /> Save
                  </Button>
                  <Button
                    key='cancel'
                    type='primary'
                    danger
                    onClick={props.handleCancel}>
                    Cancel
                  </Button>
                  <Row gutter={8} style={{ marginTop: '20px' }}>
                    <Col span={12}>
                      <Form.Item
                        name='dateRangeType'
                        rules={[
                          {
                            required: false
                          }
                        ]}>
                        <Select
                          disabled={onBlur}
                          style={{ width: 150 }}
                          value={selectedOption}
                          defaultValue={DateRangeType.LAST_SEVEN_DAYS}
                          onChange={handleSelectChange}
                          onDropdownVisibleChange={handleDropdownVisibleChange}>
                          {Object.keys(DateRangeType).map((operator, index) => (
                            <Select.Option key={index} value={operator}>
                              {operator === DateRangeType.STATIC
                                ? capitalize('CUSTOM')
                                : capitalize(operator)?.replaceAll('_', ' ')}
                            </Select.Option>
                          ))}
                        </Select>
                      </Form.Item>
                    </Col>

                    {selectedOption === DateRangeType.STATIC && (
                      <Col span={12}>
                        <div style={{ display: 'flex' }}>
                          <Form.Item name='staticDateRange'>
                            <DatePicker.RangePicker
                              onChange={handleFormBlur}
                              style={{ width: 170 }}
                              disabled={onBlur}
                            />
                          </Form.Item>
                        </div>
                      </Col>
                    )}
                  </Row>
                </Space>
              ]}></PageHeader>
          </Form>

          <Row gutter={16} style={{ height: '100%' }}>
            <Col span={7} style={{ height: '100%' }}>
              <div
                style={{
                  padding: 5,
                  border: '2px solid #F0F0F0',
                  height: '100%'
                }}>
                <Form
                  layout='vertical'
                  style={{ padding: '16px' }}
                  ref={createModalRef}
                  initialValues={
                    widgetEditDetail
                      ? {
                          columns: widgetEditDetail.columns,
                          statistics: widgetEditDetail.statistics,
                          chartType: widgetEditDetail.chartType
                        }
                      : {
                          columns: 'All',
                          statistics: ReportStatisticsOperator.TOTAL,
                          chartType: 'BAR'
                        }
                  }
                  form={form}>
                  <Form.Item label='Chart Type:' name='chartType'>
                    <TreeSelect
                      showSearch
                      style={{
                        width: '80%'
                      }}
                      dropdownStyle={{
                        maxHeight: 400,
                        overflow: 'auto'
                      }}
                      defaultValue='BAR'
                      allowClear
                      treeDefaultExpandAll
                      onChange={handleChartType}
                      treeData={ChartTypeData}
                    />
                  </Form.Item>

                  <Form.Item
                    label='Columns:'
                    name='columns'
                    rules={[
                      {
                        required: true,
                        message: 'please choose the column'
                      }
                    ]}>
                    <Select
                      disabled={onBlur}
                      style={{ width: '80%' }}
                      onBlur={handleFormBlur}
                      removeIcon={null}
                      onChange={handleColumnsChange}
                      mode='multiple'>
                      <Select.Option key='All' value='All'>
                        {'All'}
                      </Select.Option>
                      {props.numericColumns.map((column: any, index: any) => (
                        <Select.Option
                          disabled={columns?.includes('All')}
                          key={index}
                          value={props.displayNames?.[column] || column}>
                          {props.displayNames?.[column] || column}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>

                  <Form.Item name='statistics'>
                    <Radio.Group
                      defaultValue='TOTAL'
                      disabled={onBlur}
                      onChange={handleFormBlur}
                      name='statistics'
                      style={{
                        marginTop: '1%',
                        width: '90%'
                      }}>
                      {Object.keys(ReportStatisticsOperator)
                        .filter(
                          operator =>
                            operator !== ReportStatisticsOperator.SUB_AVERAGE &&
                            operator !== ReportStatisticsOperator.SUB_TOTAL
                        )
                        .map((operator, index) => (
                          <Radio.Button key={index} value={operator}>
                            {startCase(
                              capitalize(
                                ReportStatisticsOperatorDisplayName[operator]
                              )
                            )}
                          </Radio.Button>
                        ))}
                    </Radio.Group>
                  </Form.Item>
                </Form>
              </div>
            </Col>
            <Col span={17} style={{ height: '100%' }}>
              <div
                style={{
                  padding: 16,
                  border: '2px solid #F0F0F0',
                  height: '100%'
                }}>
                {!isEmpty(Dataset) ? (
                  <ChartComponent dataset={Dataset} chartType={chartType} />
                ) : (
                  <LoadingComponent />
                )}
              </div>
            </Col>
          </Row>
        </>
      )}
    </Modal>
  ) : (
    <LoadingComponent />
  );
};

export default CreateOrEditWidget;
