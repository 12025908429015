import { Button, Col, PaginationProps, Row, message } from 'antd';
import { InitialValue, PaginatedType } from '../../types/data/paginated.type';
import { useEffect, useState } from 'react';

import ConfirmationModal from '../../modals/confirmation';
import CreateOrEditGroupModal from '../../modals/groups/create-or-edit';
import { CurrentUserProp } from '../../types/props/current-user.props';
import { Group } from '../../types/data/group.type';
import { GroupService } from '../../service/group.service';
import { HeaderMenuItemStore } from '../../store';
import { Helmet } from 'react-helmet';
import SecureComponent from '../../components/authorization';
import TableComponent from '../../components/table';
import { TableType } from '../../enums/table-type.enum';
import { useSearchParams } from 'react-router-dom';

const GroupsPage = (props: CurrentUserProp) => {
  const [loading, setLoading] = useState(false);
  const [groups, setGroups] = useState<PaginatedType<Group>>(InitialValue);
  const [addGroupVisible, setAddGroupVisibility] = useState(false);
  const [addGroupLoading, setAddGroupLoading] = useState(false);

  const [removeGroupModalVisible, setRemoveGroupModalVisible] = useState(false);
  const [selectedGroup, setSelectedGroup] = useState<Group>();

  const [searchParams, setSearchParams] = useSearchParams();
  const [page, setPage] = useState(searchParams.get('page') || 1);
  const [size, setPageSize] = useState(searchParams.get('size') || 50);

  const getAllGroups = () => {
    setLoading(true);
    HeaderMenuItemStore.setDisableMenuItems(true);
    setAddGroupLoading(true);
    GroupService.getAllGroups({ page, size })
      .then(groups => {
        setGroups(groups);
        setSearchParams(
          {
            page: groups.page,
            size: groups.size
          },
          {
            replace: true
          }
        );
      })
      .catch(err => {
        console.log('Error in getting all groups :: ', err);
        message.error('Failed to fetch groups, please try again later');
      })
      .finally(() => {
        setLoading(false);
        HeaderMenuItemStore.setDisableMenuItems(false);
      });
  };

  useEffect(() => {
    getAllGroups();
  }, [page, size]);

  const onPageChange: PaginationProps['onChange'] = (
    page_: number,
    size_: number
  ) => {
    setPage(page_);
    setPageSize(size_);
  };

  const toggleAddGroupVisibility = () => {
    setAddGroupVisibility(true);
  };

  const handleAddGroupCancel = () => {
    setAddGroupLoading(false);
    setAddGroupVisibility(false);
  };

  const handleAddGroupOk = (data: any) => {
    setAddGroupLoading(true);
    GroupService.createGroup(data)
      .then(() => {
        setAddGroupVisibility(false);
        setAddGroupLoading(false);
        message.success('Group created successfully');
        getAllGroups();
      })
      .catch(err => {
        setAddGroupLoading(false);
        message.error('Failed to create group, please try again later');
        console.log('Error while creating group :: ', err);
      });
  };

  const handleDeleteGroup = (data: Group) => {
    setSelectedGroup(data);
    setRemoveGroupModalVisible(true);
  };

  const handleDeleteGroupOk = () => {
    setLoading(true);
    GroupService.deleteGroup(selectedGroup?.id as string)
      .then(() => {
        message.success('Group successfully deleted');
        getAllGroups();
      })
      .catch(err => {
        console.log('Error while deleting group :: ', err);
        message.error('Error while deleting group');
      })
      .finally(() => {
        setLoading(false);
        setSelectedGroup(undefined);
        setRemoveGroupModalVisible(false);
      });
  };

  const handleDeleteGroupCancel = () => {
    setLoading(false);
    setSelectedGroup(undefined);
    setRemoveGroupModalVisible(false);
  };

  return (
    <>
      <Helmet>
        <title>Groups | Analytics Dashboard | Elite Insurance Partners</title>
        <meta
          name='description'
          content={`Elite Insurance Partners | Analytics Dashboard - Groups`}
        />
      </Helmet>
      <Row>
        {/* Display this only if user is admin */}
        {props.isAdmin && (
          <Col xs={24} className='table-action-btn'>
            <Button type='primary' onClick={toggleAddGroupVisibility}>
              Create Group
            </Button>
          </Col>
        )}
        <Col xs={24} className='space-top-10'>
          <TableComponent
            loading={loading}
            data={groups}
            tableType={TableType.GROUPS}
            handleGroupRemoval={handleDeleteGroup}
            onPageChange={onPageChange}
          />
        </Col>
        <CreateOrEditGroupModal
          visible={addGroupVisible}
          loading={addGroupLoading}
          handleOk={handleAddGroupOk}
          handleCancel={handleAddGroupCancel}
        />
        {/* Delete Group  */}
        <ConfirmationModal
          title={`Are you sure to delete group (${selectedGroup?.name}) ?`}
          visible={removeGroupModalVisible}
          danger={true}
          handleOk={handleDeleteGroupOk}
          handleCancel={handleDeleteGroupCancel}
          content={`This action would remove group (${selectedGroup?.name}) permanently`}
        />
      </Row>
    </>
  );
};

export default SecureComponent(GroupsPage);
