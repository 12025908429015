import '../../App.css';

import { useEffect, useState } from 'react';

import { DocumentService } from '../../service/document.service';
import { Helmet } from 'react-helmet';
import LoadingComponent from '../../components/loading';
import SecureComponent from '../../components/authorization';

const DocumentPage: React.FC = () => {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState<string>('');

  useEffect(() => {
    async function fetchData() {
      await DocumentService.getUserGuide().then(url => {
        setData(url.data);
        setLoading(false);
      });
    }
    fetchData();
  }, []);

  return (
    <>
      <Helmet>
        <title>
          User Guide | Analytics Dashboard | Elite Insurance Partners
        </title>
        <meta
          name='description'
          content={`Elite Insurance Partners | Analytics Dashboard - User Guide`}
        />
      </Helmet>
      {loading && <LoadingComponent></LoadingComponent>}
      <div className='document-view'>
        <object className='pdf-view' data={data}></object>
      </div>
    </>
  );
};

export default SecureComponent(DocumentPage, false);
