export const RouteConstants = {
  HOME: '/',
  LOGIN: '/login',
  REDIRECT: '/redirect',
  REPORTS: '/reports',
  GROUPS: '/groups',
  USERS: '/users',
  DOCUMENT: '/userguide',
  // REPORT_DETAILS: '/:reportId',
  // REPORT_HISTORY: '/history',
  // REPORT_RESULT: '/:reportHistoryId'
  REPORT_DETAILS: '/reports/:reportId',
  REPORT_HISTORY: '/reports/:reportId/history',
  REPORT_RESULT: '/reports/:reportId/history/:reportHistoryId',
  GROUP_DETAILS: '/groups/:groupId',
  CREATE_REPORT: '/reports/create',
  EDIT_REPORT: '/reports/:reportId/edit',
  PREVIEW_REPORT: '/preview'
};
