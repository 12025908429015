import { makeAutoObservable } from 'mobx';

export class HeaderMenuItemsStore {
  disableMenuItems: boolean = false;

  constructor() {
    makeAutoObservable(this);
  }

  setDisableMenuItems(disableMenuItems_: boolean) {
    this.disableMenuItems = disableMenuItems_;
  }

  getDisableMenuItems(): boolean {
    return this.disableMenuItems;
  }
}
