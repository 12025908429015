import { ApiUtils } from '../utils/api.utils';
import { UrlConstants } from '../constants/url.constants';
import axios from 'axios';

const previewWidget = (data: any, reportId: string): Promise<any> => {
  return new Promise((resolve, reject) => {
    axios
      .post(
        UrlConstants.PREVIEW_WIDGET.replaceAll(':reportId', reportId),
        {
          ...data
        },
        ApiUtils.getHeaders()
      )
      .then((response: any) => {
        if (response.status === 201) {
          resolve(response.data as any);
        }
      })
      .catch(err => reject(err));
  });
};

const createWidget = (data: any, reportId: string): Promise<any> => {
  return axios.post(
    UrlConstants.CREATE_WIDGET.replaceAll(':reportId', reportId),
    { ...data },
    ApiUtils.getHeaders()
  );
};

const updateWidget = (
  reportId: string,
  widgetId: string,
  data: any
): Promise<any> => {
  const url = UrlConstants.UPDATE_WIDGET_BY_ID.replaceAll(
    ':reportId',
    reportId
  ).replaceAll(':widgetId', widgetId);
  return axios.patch(url, data, ApiUtils.getHeaders());
};

const getWidgetById = (reportId: string, widgetId: string): Promise<any> => {
  const url = UrlConstants.GET_WIDGET_BY_ID.replaceAll(
    ':reportId',
    reportId
  ).replaceAll(':widgetId', widgetId);
  return new Promise((resolve, reject) =>
    axios
      .get(url, ApiUtils.getHeaders())
      .then(response => {
        if (response.status === 200) resolve(response.data.data);
        else throw response;
      })
      .catch(err => reject(err))
  );
};

const deleteWidgetById = (reportId: string, widgetId: string): Promise<any> => {
  const url = UrlConstants.DELETE_WIDGET_BY_ID.replaceAll(
    ':reportId',
    reportId
  ).replaceAll(':widgetId', widgetId);
  return axios.delete(url, ApiUtils.getHeaders());
};

export const WidgetService = {
  previewWidget,
  createWidget,
  updateWidget,
  getWidgetById,
  deleteWidgetById
};
