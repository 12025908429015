import { ApiUtils } from '../utils/api.utils';
import { UrlConstants } from '../constants/url.constants';
import axios from 'axios';

const getAllUsers = (params?: any): Promise<any> => {
  return new Promise((resolve, reject) => {
    const url: string = ApiUtils.addQueryParamsToUrl(
      UrlConstants.GET_ALL_USERS,
      params
    );
    axios
      .get(url, ApiUtils.getHeaders())
      .then(response => {
        if (response.status === 200) {
          resolve(response.data);
        } else throw response;
      })
      .catch(err => {
        reject(err);
      });
  });
};

const getCurrentUserDetails = (): Promise<any> => {
  return axios.get(UrlConstants.GET_USER_DETAIL, ApiUtils.getHeaders());
};

const deleteUser = (userId: string): Promise<any> => {
  const url = UrlConstants.DELETE_USER.replaceAll(':userId', userId);
  return axios.delete(url, ApiUtils.getHeaders());
};

const addUser = (data: any): any => {
  return axios
    .post(
      UrlConstants.ADD_USER,
      ApiUtils.cleanEmptyString(data),
      ApiUtils.getHeaders()
    )
    .then(response => {
      if (response.status === 201) {
        return response.data;
      } else throw response;
    })
    .catch(err => {
      throw err.response.data;
    });
};

export const UserService = {
  getAllUsers,
  getCurrentUserDetails,
  deleteUser,
  addUser
};
