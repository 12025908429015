import { ApiUtils } from '../utils/api.utils';
import { UrlConstants } from '../constants/url.constants';
import axios from 'axios';

const getUserGuide = (): Promise<any> => {
  const url = UrlConstants.GET_USER_GUIDE;
  return new Promise((resolve, reject) =>
    axios
      .get(url, ApiUtils.getHeaders())
      .then(response => {
        if (response.status === 200) {
          resolve(response.data);
        } else throw response;
      })
      .catch(err => reject(err))
  );
};

export const DocumentService = {
  getUserGuide
};
