import { ReportStatisticsOperator } from '../../enums/report-statistics-operator.enum';

export enum ReportRunStatisticsEffect {
  INCREASE = 'Increase',
  DECREASE = 'Decrease',
  NIL = 'Nil'
}

type ReportRunStatistics = {
  count: number;
  statistics: { effect: ReportRunStatisticsEffect; by: string };
};

export type ReportStatisticsOverview = {
  totalReportRuns: ReportRunStatistics;
  successfulReportRuns: ReportRunStatistics;
  failedReportRuns: ReportRunStatistics;
  noDataReports: ReportRunStatistics;
};

export type ReportWidgetDetails = {
  reportId: number;
  widgetId: ReportRunStatistics;
  columns: any;
  chartType: string;
  statistics: ReportStatisticsOperator;
  staticDateRange: any;
  dataRangeType: string;
  data: any;
};
