import { Button, Col, PaginationProps, Row, message } from 'antd';
import { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { CurrentUserProp } from '../../types/props/current-user.props';
import { HeaderMenuItemStore } from '../../store';
import { Helmet } from 'react-helmet';
import { ReportService } from '../../service/report.service';
import ReportsComponent from '../../components/report/all-reports';
import { RouteConstants } from '../../constants/route.constants';
import SecureComponent from '../../components/authorization';

const ReportsPage: React.FC<CurrentUserProp> = (props: CurrentUserProp) => {
  const [reports, setReports] = useState([]);
  const [loading, setLoading] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const [page, setPage] = useState(searchParams.get('page') || 1);
  const [size, setPageSize] = useState(searchParams.get('size') || 50);

  const navigate = useNavigate();

  const getReports = () => {
    setLoading(true);
    HeaderMenuItemStore.setDisableMenuItems(true);
    ReportService.getAllReports({ page, size })
      .then(reports => {
        setReports(reports);
        setSearchParams(
          {
            page: reports.page,
            size: reports.size
          },
          {
            replace: true
          }
        );
      })
      .catch(err => {
        console.log('Error while fetching reports :: ', err);
        message.error('Failed to fetch reports, please try again later');
      })
      .finally(() => {
        setLoading(false);
        HeaderMenuItemStore.setDisableMenuItems(false);
      });
  };

  useEffect(() => {
    getReports();
  }, [page, size]);

  const onPageChange: PaginationProps['onChange'] = (
    page_: number,
    size_: number
  ) => {
    setPage(page_);
    setPageSize(size_);
  };

  return (
    <>
      <Helmet>
        <title>Reports | Analytics Dashboard | Elite Insurance Partners</title>
        <meta
          name='description'
          content={`Elite Insurance Partners | Analytics Dashboard - Reports`}
        />
      </Helmet>
      <Row>
        {/* Display this only if user is admin */}
        {props.isAdmin && (
          <Col xs={24} className='table-action-btn'>
            <Button
              type='primary'
              onClick={() => navigate(RouteConstants.CREATE_REPORT)}>
              Create New Report
            </Button>
          </Col>
        )}
        <Col xs={24} className='space-top-10'>
          <ReportsComponent
            loading={loading}
            reports={reports}
            onPageChange={onPageChange}
          />
        </Col>
      </Row>
    </>
  );
};

export default SecureComponent(ReportsPage, false);
