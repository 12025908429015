export class MomentConstants {
  // format constants
  public static readonly DATE_ALONE: string = 'YYYY-MM-DD';
  public static readonly DATE_TIME_12_HOUR_FORMAT: string =
    'YYYY-MM-DD hh:mm:ss';
  public static readonly DATE_TIME_24_HOUR_FORMAT: string =
    'YYYY-MM-DD HH:mm:ss';
  public static readonly DATE_TIME_LOCALE_FORMAT: string = 'lll';
  public static readonly timeFormat: string = 'hh A';
  public static readonly HOUR_FORMAT: string = 'HH';
  public static readonly TIME_ALONE_FORMAT: string = 'HH:mm:ss';
  public static readonly DATE_TIME_WITH_INDICATOR: string =
    'MMM DD, YYYY h:mm A';
  public static readonly TIMEFORMAT_With_INDICATOR: string = 'h:mm A ';
  public static readonly MONTH_DATE: string = 'MM/DD';
  public static readonly WEEKDAY_NAME: string = 'dddd';
}
