import { Badge, Button, Popover, Space, Tag, Tooltip, Typography } from 'antd';
import {
  CalendarOutlined,
  DownloadOutlined,
  EyeOutlined
} from '@ant-design/icons';
import { ReportDetailStatus, ReportStatus } from '../../enums/status.enum';
import { formatDate, zoneAbbr } from '../moment.utils';

import type { ColumnsType } from 'antd/lib/table';
import { Group } from '../../types/data/group.type';
import { Link } from 'react-router-dom';
import { MomentConstants } from '../../constants/moment.constants';
import { Report } from '../../types/data/report.type';
import { ReportDataSource } from '../../enums/report-data-source.enum';
import { ReportHistory } from '../../types/data/report-history.type';
import { RouteConstants } from '../../constants/route.constants';
import { StringConstants } from '../../constants/string.constants';
import { getReportResultCellPopoverContent } from '../report.utils';
import isEmpty from 'lodash.isempty';
import { isNull } from 'lodash';
import isUndefined from 'lodash.isundefined';
import moment from 'moment';

/**
 * Get columns for report history table
 * @returns {ColumnsType<ReportHistory>}
 */
export const getReportHistoryColumns = (): ColumnsType<ReportHistory> => [
  {
    title: 'Trigger time',
    dataIndex: ['jobStartedAt', 'isPreview'],
    key: 'jobStartedAt',
    render: (text, row) =>
      row['isPreview'] ? (
        <Badge.Ribbon text='Preview' color='#F4A460' placement='end'>
          {formatDate(
            row['jobStartedAt'],
            MomentConstants.DATE_TIME_LOCALE_FORMAT
          )}
          &nbsp;({zoneAbbr})
        </Badge.Ribbon>
      ) : (
        <>
          {formatDate(
            row['jobStartedAt'],
            MomentConstants.DATE_TIME_LOCALE_FORMAT
          )}
          &nbsp;({zoneAbbr})
        </>
      ),
    sorter: (a, b) => moment(a.jobStartedAt).diff(b.jobStartedAt),
    align: 'center'
  },
  {
    title: 'Status',
    dataIndex: 'status',
    key: 'status',
    render: status => (
      <Tag color={status === ReportStatus.SUCCESS ? 'green' : 'red'}>
        {status}
      </Tag>
    ),
    sorter: (a, b) => a.status.localeCompare(b.status),
    filters: [
      {
        text: <Tag color='green'>{ReportStatus.SUCCESS}</Tag>,
        value: ReportStatus.SUCCESS
      },
      {
        text: <Tag color='red'>{ReportStatus.FAILED}</Tag>,
        value: ReportStatus.FAILED
      }
    ],
    onFilter: (value, record) => record.status.indexOf(value as string) === 0,
    align: 'center'
  },

  {
    title: 'Total data fetched',
    render: (_, { dataSize, status }) =>
      status === ReportStatus.FAILED ? '-' : dataSize,
    sorter: (a, b) => {
      if (
        a.status === ReportStatus.SUCCESS &&
        b.status === ReportStatus.SUCCESS
      )
        return a.dataSize - b.dataSize;
      else if (a.status === ReportStatus.SUCCESS) return 1;
      else return -1;
    },
    align: 'center'
  },
  {
    title: 'Download Report',
    render: (_, { s3Url }) =>
      s3Url ? (
        <Button type='link' href={s3Url} icon={<DownloadOutlined />}>
          Download
        </Button>
      ) : (
        '-'
      ),
    align: 'center'
  },
  {
    title: '',
    key: 'view-record',
    render: (_, { id, dataSize, status }) =>
      status === ReportStatus.SUCCESS && dataSize > 0 ? (
        <Button type='primary'>
          <Link to={`history/${id}`}>
            <EyeOutlined /> View
          </Link>
        </Button>
      ) : (
        '-'
      ),
    align: 'center'
  }
];

/**
 * get column names of report results
 * @param {any} data - report result
 * @param {Report} report - report details
 * @returns {ColumnsType<any>}
 */
export const getReportResultColumns = (
  data: any,
  report?: Report
): ColumnsType<any> => {
  const keys = Object.keys(data).filter(key => key !== 'cellFormats');

  return keys.map(key => ({
    title: key,
    dataIndex: key,
    key,
    onCell: (data: any) => ({
      style: {
        ...(data[key] !== '' && data['cellFormats']
          ? { background: data['cellFormats'][key] }
          : {})
      }
    }),
    render: (text: any, data: any) => (
      <Popover
        content={
          data['cellFormats'] && data['cellFormats'][key] && report
            ? getReportResultCellPopoverContent(
                key,
                data['cellFormats'][key],
                report
              )
            : ''
        }>
        {isNull(text)
          ? '-'
          : typeof text === 'boolean'
          ? text.toString()
          : text}
      </Popover>
    ),
    align: 'center'
  }));
};

export const getReportResultSubStatsColumns = (
  data: any,
  baseColumn: any
): ColumnsType<any> => {
  const keys = Object.keys(data);

  return keys.map(key => ({
    title: (
      <Typography.Text strong={baseColumn === key ? true : false}>
        {key}
      </Typography.Text>
    ),
    dataIndex: key,
    key,
    render: (text: any) => (
      <>
        <Typography.Text strong={baseColumn === key ? true : false}>
          {text !== 'null' ? text : '-'}
        </Typography.Text>
      </>
    ),

    align: 'center'
  }));
};

/**
 * get columns of reports
 * @returns {ColumnsType<Report>}
 */
export const getReportsColumns = (): ColumnsType<Report> => [
  {
    title: 'Name',
    dataIndex: 'name',
    key: 'name',
    sorter: (a, b) => a.name.localeCompare(b.name),
    align: 'center'
  },
  {
    title: 'Data Source',
    dataIndex: 'dataSource',
    key: 'dataSource',
    sorter: (a, b) => a.dataSource.localeCompare(b.dataSource),
    defaultSortOrder: 'ascend',
    align: 'center',
    filters: Object.values(ReportDataSource).map(dataSource => ({
      text: dataSource,
      value: dataSource
    })),
    onFilter: (value, record) => record.dataSource === value
  },
  {
    title: 'Query/URL',
    dataIndex: 'query',
    key: 'query',
    ellipsis: {
      showTitle: false
    },
    render: query => (
      <Tooltip placement='topLeft' title={query}>
        {query}
      </Tooltip>
    ),
    align: 'center'
  },
  {
    title: 'Status',
    dataIndex: 'isActive',
    key: 'isActive',
    render: (isActive: boolean) => (
      <Tag color={isActive ? 'green' : 'red'}>
        {isActive ? ReportDetailStatus.ACTIVE : ReportDetailStatus.DISABLED}
      </Tag>
    ),
    sorter: (a, b) => Number(b.isActive) - Number(a.isActive),
    defaultSortOrder: 'ascend',
    filters: [
      {
        text: <Tag color='green'>ACTIVE</Tag>,
        value: true
      },
      {
        text: <Tag color='red'>DISABLED</Tag>,
        value: false
      }
    ],
    onFilter: (value, record) => Number(value) === Number(record.isActive),
    align: 'center'
  },
  {
    title: 'Frequency',
    dataIndex: 'frequency',
    key: 'frequency',
    render: (frequency: string, record: Report) => {
      const frequencyValue = StringConstants.REPORT_FREQUENCY.find(
        reportFrequency => reportFrequency.FREQUENCY === frequency
      )?.VALUE;
      return (
        <Space>
          {frequencyValue}
          {record.repeatedDays === '0111110' && (
            <Tooltip title='Runs only on weekdays'>
              <CalendarOutlined />
            </Tooltip>
          )}
        </Space>
      );
    },
    sorter: (a, b) => {
      let indexOfA = -1,
        indexOfB = -1;
      StringConstants.REPORT_FREQUENCY.forEach((reportFrequency, index) => {
        if (reportFrequency.FREQUENCY === a.frequency) indexOfA = index;
        else if (reportFrequency.FREQUENCY === b.frequency) indexOfB = index;
      });

      return indexOfA - indexOfB;
    },
    align: 'center'
  },
  {
    title: 'Add result as attachment',
    dataIndex: 'addAsAttachment',
    key: 'addAsAttachment',
    render: (addAsAttachment: boolean) => (addAsAttachment ? 'Yes' : 'No'),
    filters: [
      {
        text: 'Yes',
        value: true
      },
      {
        text: 'No',
        value: false
      }
    ],
    onFilter: (value, record) =>
      Number(value) === Number(record.addAsAttachment),
    align: 'center'
  },
  {
    title: 'No. of Watchers (groups)',
    dataIndex: 'groups',
    key: 'watchers',
    render: (groups: Group[]) => (isEmpty(groups) ? 0 : groups.length),
    sorter: (a, b) => {
      const a_groups = isUndefined(a.groups) ? 0 : a.groups.length,
        b_groups = isUndefined(b.groups) ? 0 : b.groups.length;
      return a_groups - b_groups;
    },
    align: 'center'
  },
  {
    title: '',
    dataIndex: 'id',
    key: 'id',
    render: id => (
      <Space>
        <Button type='primary'>
          <Link to={RouteConstants.REPORT_DETAILS.replace(':reportId', id)}>
            <EyeOutlined /> View
          </Link>
        </Button>
      </Space>
    ),
    align: 'center'
  }
];
