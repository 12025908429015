import { CurrentUserProp } from '../../types/props/current-user.props';
import { Helmet } from 'react-helmet';
import React from 'react';
import { SecureComponent } from '../../components';
import { UserRoles } from '../../enums/user-role.enum';
import UsersComponent from '../../components/user';

const UsersPage: React.FC<CurrentUserProp> = (props: CurrentUserProp) => (
  <>
    <Helmet>
      <title>Users | Analytics Dashboard | Elite Insurance Partners</title>
      <meta
        name='description'
        content={`Elite Insurance Partners | Analytics Dashboard - Users`}
      />
    </Helmet>
    <UsersComponent
      userRole={props.isAdmin ? UserRoles.ADMIN : UserRoles.VIEWER}
      currentUserOid={props.oid}
    />
  </>
);

export default SecureComponent(UsersPage, true);
