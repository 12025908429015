import {
  Button,
  Col,
  Collapse,
  Descriptions,
  Divider,
  List,
  Row,
  Tabs,
  Tag,
  Typography,
  message
} from 'antd';
import {
  ReportConditionalFormattingRules,
  ReportEmailRules
} from '../../../types/data/report.type';
import { formatDate, zoneAbbr } from '../../../utils/moment.utils';
import {
  getConditionalFormattingOperatorKey,
  getConfiguredStatisticsDetails,
  getReportEmailConditionalOperatorKey
} from '../../../utils/report.utils';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router';

import { DownloadOutlined } from '@ant-design/icons';
import EmptyDataComponent from '../../../components/empty-data';
import { Helmet } from 'react-helmet';
import LoadingComponent from '../../../components/loading';
import { MomentConstants } from '../../../constants/moment.constants';
import { PageHeader } from '@ant-design/pro-layout';
import { ReportDataSource } from '../../../enums/report-data-source.enum';
import { ReportHistory } from '../../../types/data/report-history.type';
import { ReportService } from '../../../service/report.service';
import { RouteConstants } from '../../../constants/route.constants';
import TableComponent from '../../../components/table';
import { TableType } from '../../../enums/table-type.enum';
import isEmpty from 'lodash.isempty';
import isUndefined from 'lodash.isundefined';
import secureComponent from '../../../components/authorization';

const ReportResultsPage: React.FC = () => {
  const navigate = useNavigate();

  const [loading, setLoading] = useState(true);
  const [reportResult, setReportResult] = useState<ReportHistory>();
  const { reportId, reportHistoryId } = useParams();
  const [collapseHeader, setCollapseHeader] = useState<boolean>(false);

  const getReportHistory = () => {
    setLoading(true);
    ReportService.getResultForReportById(
      reportId as string,
      reportHistoryId as string
    )
      .then(reportResult => {
        setReportResult(reportResult);
      })
      .catch(err => {
        message.error('Failed to fetch report history, please try again later');
        console.log('Error while getting reporting history :: ', err);
      })
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    getReportHistory();
  }, []);

  if (loading)
    return <LoadingComponent tip='Loading Report Result'></LoadingComponent>;
  else if (!isEmpty(reportResult) && reportResult?.data.length > 0) {
    return (
      // Show Report Details and the specific time of run
      <>
        <Helmet>
          <title>
            {reportResult?.report.name} Report Result | Analytics Dashboard |
            Elite Insurance Partners
          </title>
          <meta
            name='description'
            content={`${reportResult?.report.name} | Elite Insurance Partners | Analytics Dashboard - Report Result`}
          />
        </Helmet>
        <PageHeader
          style={{ backgroundColor: 'white', borderRadius: 6 }}
          subTitle={
            !isUndefined(reportResult)
              ? `Report ran at - ${formatDate(
                  reportResult.jobStartedAt,
                  MomentConstants.DATE_TIME_LOCALE_FORMAT
                )}` +
                ' (' +
                zoneAbbr +
                ')'
              : ''
          }
          onBack={() =>
            navigate(
              RouteConstants.REPORT_DETAILS.replaceAll(
                ':reportId',
                reportId as string
              )
            )
          }
          title={`Report : ${reportResult?.report.name}`}
          extra={[
            // Should be visible only for admins
            <Button
              key='edit_report'
              type='primary'
              href={reportResult?.s3Url as string}>
              <DownloadOutlined />
              Download
            </Button>
          ]}>
          <Descriptions
            size='small'
            column={{ xxl: 3, xl: 3, lg: 3, md: 3, sm: 1, xs: 1 }}
            className='space-top-10'>
            <Descriptions.Item label='Report Status' span={1}>
              <Tag color={reportResult?.status ? 'green' : 'red'}>
                {reportResult?.status}
              </Tag>
            </Descriptions.Item>
            <Descriptions.Item label='Data Source' span={2}>
              <Typography.Text strong>
                {reportResult?.report.dataSource}
              </Typography.Text>
            </Descriptions.Item>
            <Descriptions.Item label='Query/URL' span={3}>
              <Typography.Text strong copyable>
                {reportResult?.report.query}
              </Typography.Text>
            </Descriptions.Item>
            {reportResult?.report.dataSource ===
              ReportDataSource.SALESFORCE && (
              <>
                <Descriptions.Item>
                  <Divider style={{ borderTop: 'black' }} dashed={true}>
                    <Collapse
                      bordered={false}
                      ghost
                      onChange={() => {
                        setCollapseHeader(!collapseHeader);
                      }}>
                      <Collapse.Panel
                        header={
                          <Button type='dashed'>
                            {collapseHeader ? 'Show Less' : 'Show More'}
                          </Button>
                        }
                        key='1'
                        showArrow={false}
                        className='custom-panel-header'>
                        <Descriptions>
                          <Descriptions.Item
                            label='Configured Aggregations'
                            span={3}>
                            {isEmpty(reportResult?.report.statistics) ? (
                              '-'
                            ) : (
                              <div
                                style={{
                                  overflow: 'auto',
                                  padding: '0 16px',
                                  border: '1px solid rgba(140, 140, 140, 0.35)',
                                  width: '100%'
                                }}>
                                {reportResult?.report.statistics ? (
                                  <List
                                    itemLayout='horizontal'
                                    dataSource={getConfiguredStatisticsDetails(
                                      reportResult?.report.statistics
                                    )}
                                    renderItem={(reportStatistics: string) => (
                                      <List.Item>
                                        <Typography.Text strong>
                                          {reportStatistics}
                                        </Typography.Text>
                                      </List.Item>
                                    )}
                                  />
                                ) : (
                                  ''
                                )}
                              </div>
                            )}
                          </Descriptions.Item>

                          <Descriptions.Item
                            label='Conditional Formatting'
                            span={3}>
                            {isEmpty(
                              reportResult?.report.conditionalFormattingRules
                            ) ? (
                              '-'
                            ) : (
                              <div
                                style={{
                                  overflow: 'auto',
                                  padding: '0 16px',
                                  border: '1px solid rgba(140, 140, 140, 0.35)',
                                  width: '100%',
                                  marginLeft: '34px'
                                }}>
                                <List
                                  itemLayout='horizontal'
                                  dataSource={
                                    reportResult?.report
                                      .conditionalFormattingRules
                                  }
                                  renderItem={(
                                    conditionalFormattingRules: ReportConditionalFormattingRules
                                  ) => (
                                    <List.Item
                                      style={{
                                        display: 'flex',
                                        justifyContent: 'start'
                                      }}>
                                      <Typography.Text strong>
                                        {conditionalFormattingRules.column}
                                      </Typography.Text>
                                      :&nbsp;
                                      {conditionalFormattingRules.conditions.map(
                                        (condition, index) => (
                                          <Typography.Text
                                            key={index}
                                            style={{
                                              display: 'flex',
                                              flexDirection: 'row'
                                            }}>
                                            {getConditionalFormattingOperatorKey(
                                              condition.operator
                                            )}
                                            &nbsp;
                                            {condition.value ||
                                              condition.from ||
                                              condition.field}
                                            &nbsp;-{condition.to}&nbsp;
                                            <div
                                              key={index}
                                              style={{
                                                height: '20px',
                                                width: '20px',
                                                border: '1px solid black',
                                                clear: 'both',
                                                backgroundColor:
                                                  condition.colour
                                              }}
                                            />
                                            {index ===
                                            conditionalFormattingRules
                                              .conditions.length -
                                              1
                                              ? ''
                                              : ', '}
                                            &nbsp;
                                          </Typography.Text>
                                        )
                                      )}
                                    </List.Item>
                                  )}
                                />
                              </div>
                            )}
                          </Descriptions.Item>

                          <Descriptions.Item
                            label='Configured Email Conditions'
                            span={3}>
                            {isEmpty(reportResult?.report.emailConditions) ? (
                              '-'
                            ) : (
                              <div
                                style={{
                                  overflow: 'auto',
                                  padding: '0 16px',
                                  border: '1px solid rgba(140, 140, 140, 0.35)',
                                  width: '100%'
                                }}>
                                <List
                                  itemLayout='horizontal'
                                  dataSource={
                                    reportResult?.report.emailConditions
                                  }
                                  renderItem={(
                                    emailConditions: ReportEmailRules
                                  ) => (
                                    <List.Item
                                      style={{
                                        display: 'flex',
                                        justifyContent: 'start'
                                      }}>
                                      <Typography.Text strong>
                                        {emailConditions.column}
                                      </Typography.Text>
                                      :&nbsp;
                                      {emailConditions.conditions.map(
                                        (condition, index) => (
                                          <Typography.Text
                                            key={index}
                                            style={{
                                              display: 'flex',
                                              flexDirection: 'row'
                                            }}>
                                            &nbsp;
                                            {condition.statistics}
                                            &nbsp;
                                            {getReportEmailConditionalOperatorKey(
                                              condition.operator
                                            )}
                                            &nbsp;
                                            {condition.value}
                                            {index ===
                                            emailConditions.conditions.length -
                                              1
                                              ? ''
                                              : ', '}
                                          </Typography.Text>
                                        )
                                      )}
                                    </List.Item>
                                  )}
                                />
                              </div>
                            )}
                          </Descriptions.Item>
                        </Descriptions>
                      </Collapse.Panel>
                    </Collapse>
                  </Divider>
                </Descriptions.Item>
              </>
            )}
          </Descriptions>
          <Tabs defaultActiveKey='1' className='space-top'>
            <Tabs.TabPane tab='Report Result' key='1'>
              <Row className='space-top-10'>
                <Col xs={24}>
                  <TableComponent
                    loading={loading}
                    data={reportResult?.data}
                    tableType={TableType.REPORT_RESULT}
                    summary={reportResult?.summary}
                    paginationAtTop={true}
                    report={reportResult?.report}
                    historicalSummary={reportResult?.historicalSummary}
                  />
                </Col>
              </Row>
            </Tabs.TabPane>
            <>
              {reportResult &&
              reportResult.subStats &&
              !isEmpty(reportResult.subStats['subTotals']) ? (
                <Tabs.TabPane tab='Sub Total' key='2'>
                  <Row gutter={16} className='space-top-10'>
                    <Col xs={24}>
                      <>
                        {Object.entries(
                          reportResult?.subStats?.['subTotals']
                        ).map((key, index) => (
                          <>
                            <Typography>
                              <Typography.Text strong>
                                Base Column{': '}
                              </Typography.Text>
                              <Typography.Text mark strong>
                                {key[0]}
                              </Typography.Text>
                            </Typography>
                            <TableComponent
                              key={index}
                              loading={loading}
                              data={key[1]}
                              tableType={TableType.SUB_STATS}
                              paginationAtTop={false}
                              baseColumn={key[0]}
                            />
                          </>
                        ))}
                      </>
                    </Col>
                  </Row>
                </Tabs.TabPane>
              ) : null}
            </>
            <>
              {reportResult &&
              reportResult.subStats &&
              !isEmpty(reportResult.subStats['subAverage']) ? (
                <Tabs.TabPane tab='Sub Average' key='3'>
                  <Row gutter={16} className='space-top-10'>
                    <Col xs={24}>
                      <>
                        {Object.entries(
                          reportResult?.subStats?.['subAverage']
                        ).map((key, index) => (
                          <>
                            <Typography>
                              <Typography.Text strong>
                                Base Column{': '}
                              </Typography.Text>
                              <Typography.Text mark strong>
                                {key[0]}
                              </Typography.Text>
                            </Typography>
                            <TableComponent
                              key={index}
                              loading={loading}
                              data={key[1]}
                              tableType={TableType.SUB_STATS}
                              paginationAtTop={false}
                              baseColumn={key[0]}
                            />
                          </>
                        ))}
                      </>
                    </Col>
                  </Row>
                </Tabs.TabPane>
              ) : null}
            </>
            :
          </Tabs>
        </PageHeader>
      </>
    );
  } else
    return (
      <EmptyDataComponent
        title={`Report Result Not Found`}
        subTitle='Sorry, the report result you are looking for might have been deleted'
        navigateTo={RouteConstants.REPORTS}
      />
    );
};

export default secureComponent(ReportResultsPage, false);
