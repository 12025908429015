import {
  AccountInfo,
  AuthenticationResult,
  PublicClientApplication,
  SilentRequest
} from '@azure/msal-browser';
import { handleLogout, saveCookie } from '../utils/auth.utils';

import axios from 'axios';
import { message } from 'antd';
import { msalConfiguration } from '../configurations/msal.configuration';

axios.interceptors.response.use(
  response => response,
  async (error: any) => {
    const originalRequest = error.config;
    console.log(`Backend api request failed :: ${originalRequest.url}`);

    if (error.response.status === 401) {
      console.log('Unauthorized Error. Acquiring new token from MS');

      originalRequest._retry = true;
      const instance = new PublicClientApplication(msalConfiguration);
      const account: AccountInfo | null = instance.getActiveAccount();

      if (account) {
        const tokenResponse: AuthenticationResult | null = await instance
          .acquireTokenSilent((account ? { account } : {}) as SilentRequest)
          .catch(error => {
            console.log(
              'error while fetching token secure component :: ',
              error
            );
            message.error('Please login to continue accessing the dashboard');
            return null;
          });

        if (!tokenResponse) handleLogout(instance);
        else {
          console.log('New Token Acquired');
          saveCookie(tokenResponse.idToken);
          originalRequest.headers.Authorization = `Bearer ${tokenResponse.idToken}`;
          return axios(originalRequest);
        }
      } else {
        message.error('Please login to continue accessing the dashboard');
        handleLogout(instance);
      }
    } else {
      return error;
    }
  }
);
