import {
  InitialValue,
  PaginatedType
} from '../../../types/data/paginated.type';
import { PaginationProps, message } from 'antd';
import { useEffect, useState } from 'react';

import { ReportHistory } from '../../../types/data/report-history.type';
import { ReportService } from '../../../service/report.service';
import TableComponent from '../../../components/table';
import { TableType } from '../../../enums/table-type.enum';
import { useSearchParams } from 'react-router-dom';

type ReportHistoryProps = {
  reportId: string;
  minimal: boolean;
};

const ReportHistoryComponent = (props: ReportHistoryProps) => {
  const [loading, setLoading] = useState(false);
  const [reportHistory, setReportHistory] =
    useState<PaginatedType<ReportHistory>>(InitialValue);

  const [searchParams, setSearchParams] = useSearchParams();
  const [page, setPage] = useState(searchParams.get('page') || 1);
  const [size, setPageSize] = useState(searchParams.get('size') || 100);

  const getReportHistory = (reportId: string) => {
    setLoading(true);
    ReportService.getAllResultsForReport(reportId, {
      minimal: props.minimal,
      page,
      size
    })
      .then(reportHistory => {
        setReportHistory(reportHistory);

        setSearchParams(
          {
            page: reportHistory.page,
            size: reportHistory.size
          },
          {
            replace: true
          }
        );
      })
      .catch(err => {
        console.log('Error While Fetching Results :: ', err);
        message.error('Failed to fetch report results, please try again later');
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    getReportHistory(props.reportId);
  }, [page, size]);

  const onPageChange: PaginationProps['onChange'] = (
    page_: number,
    size_: number
  ) => {
    setPage(page_);
    setPageSize(size_);
  };

  return (
    <TableComponent
      loading={loading}
      data={reportHistory}
      tableType={TableType.REPORT_HISTORY}
      paginationAtTop={true}
      onPageChange={onPageChange}
    />
  );
};

export default ReportHistoryComponent;
