import { MomentConstants } from '../constants/moment.constants';
import { formatDate } from '../utils/moment.utils';
import moment from 'moment';

/**
 * Format job start and end time in EST format
 * @param {string } frequency - frequency
 * @param  {number} jobStartTime - JobStartTime in hours
 * @param  {number} jobEndTime - JobEndTime in hours
 */

export const formatJobTime = (
  frequency: any,
  jobStartTime: any,
  jobEndTime: any
) => {
  if (frequency === '1440') {
    const estToUtc = moment(jobStartTime, MomentConstants.HOUR_FORMAT).format(
      MomentConstants.DATE_TIME_24_HOUR_FORMAT
    );
    const startTime = moment.tz(estToUtc, 'America/New_York');
    const localStartTime = formatDate(
      startTime.toISOString(),
      MomentConstants.DATE_TIME_LOCALE_FORMAT
    );
    return `Once a day at ${moment(
      localStartTime,
      MomentConstants.DATE_TIME_WITH_INDICATOR
    ).format(MomentConstants.TIMEFORMAT_With_INDICATOR)}`;
  } else {
    const estToUtcstart = moment(
      jobStartTime,
      MomentConstants.HOUR_FORMAT
    ).format(MomentConstants.DATE_TIME_24_HOUR_FORMAT);
    const estToUtcEnd = moment(jobEndTime, MomentConstants.HOUR_FORMAT).format(
      MomentConstants.DATE_TIME_24_HOUR_FORMAT
    );
    const startTime = moment.tz(estToUtcstart, 'America/New_York');
    const localStartTime = formatDate(
      startTime.toISOString(),
      MomentConstants.DATE_TIME_LOCALE_FORMAT
    );

    const endTime = moment.tz(estToUtcEnd, 'America/New_York');
    const localEndTime = formatDate(
      endTime.toISOString(),
      MomentConstants.DATE_TIME_LOCALE_FORMAT
    );
    return `${moment(
      localStartTime,
      MomentConstants.DATE_TIME_WITH_INDICATOR
    ).format(MomentConstants.TIMEFORMAT_With_INDICATOR)} - ${moment(
      localEndTime,
      MomentConstants.DATE_TIME_WITH_INDICATOR
    ).format(MomentConstants.TIMEFORMAT_With_INDICATOR)} `;
  }
};
