import {
  AccountInfo,
  EventType,
  IPublicClientApplication
} from '@azure/msal-browser';
import { NavigateFunction, useNavigate } from 'react-router-dom';

import LoadingComponent from '../../components/loading';
import { getActiveSignedInAccount } from '../../utils/auth.utils';
import { loginRequest } from '../../configurations/msal.configuration';
import { message } from 'antd';
import moment from 'moment';
import { useEffect } from 'react';
import { useMsal } from '@azure/msal-react';

const logIn = (
  instance: IPublicClientApplication,
  navigate: NavigateFunction
) => {
  const accounts: AccountInfo[] = instance.getAllAccounts();
  const activeSignedInAccount: AccountInfo | undefined =
    getActiveSignedInAccount(accounts);
  if (activeSignedInAccount) instance.setActiveAccount(activeSignedInAccount);
  instance.addEventCallback((event: any) => {
    // set active account after redirect
    if (event.eventType === EventType.LOGIN_SUCCESS && event.payload.account) {
      const account = event.payload.account;
      instance.setActiveAccount(account);
    }
  });

  instance
    .handleRedirectPromise()
    .then(() => {
      // Check if user signed in
      const account: AccountInfo | null = instance.getActiveAccount();

      if (account) {
        if (!account.idTokenClaims?.roles) {
          message.error('Invalid Login. User is not part of EIP Azure app.');
          instance.setActiveAccount(null);
          instance.logout();
        }
        let tokenExpiryTime: moment.Moment | null = null;
        if (account.idTokenClaims?.exp)
          tokenExpiryTime = moment.unix(account.idTokenClaims.exp);

        if (tokenExpiryTime && tokenExpiryTime.isBefore(moment())) {
          // redirect anonymous user to login page
          instance.loginRedirect(loginRequest);
        } else {
          navigate('/redirect');
        }
      } else {
        // redirect anonymous user to login page
        instance.loginRedirect(loginRequest);
      }
    })
    .catch(err => {
      console.log(err);
      instance.loginRedirect(loginRequest);
    });
};

const LoginPage = () => {
  const { instance } = useMsal();
  const navigate = useNavigate();

  useEffect(() => {
    logIn(instance, navigate);
  });

  return <LoadingComponent tip='Logging you in ...' />;
};

export default LoginPage;
