import { Col, Modal, Result, Row, Tabs, Tag, Typography } from 'antd';

import { CloseCircleOutlined } from '@ant-design/icons';
import TableComponent from '../../../components/table';
import { TableType } from '../../../enums/table-type.enum';
import isEmpty from 'lodash.isempty';

type ReportsPreviewProps = {
  visible: boolean;
  reportResult: any;
  handleCancel: any;
};

const ReportsPreviewModal = (previewData: ReportsPreviewProps) => {
  return (
    <Modal
      title={
        <>
          <Typography style={{ paddingTop: '10px' }}>
            <Tag color='orange'>Preview</Tag>
            {previewData.reportResult.data &&
              `Report: ${previewData.reportResult.data.report.name}`}
          </Typography>
        </>
      }
      open={previewData.visible}
      centered={true}
      bodyStyle={{ width: '100%', overflowX: 'scroll' }}
      footer={null}
      className='ant-modal-content'
      onCancel={previewData.handleCancel}
      width='90%'
      closeIcon={
        <>
          <Typography.Text strong>
            <CloseCircleOutlined />
          </Typography.Text>
        </>
      }>
      <Tabs defaultActiveKey='1' className='space-top'>
        <Tabs.TabPane tab='Report Result' key='1'>
          <Row className='space-top-10'>
            <Col xs={24}>
              {previewData.reportResult.data?.data &&
              previewData.reportResult.data?.data.length > 0 ? (
                <TableComponent
                  data={previewData.reportResult.data?.data}
                  tableType={TableType.REPORT_RESULT}
                  summary={previewData.reportResult.data?.summary}
                  paginationAtTop={true}
                  historicalSummary={
                    previewData.reportResult.data?.historicalSummary
                  }
                />
              ) : (
                <Result
                  status='404'
                  title='No Data'
                  subTitle='Sorry, looks like the query returned an empty result.'
                />
              )}
            </Col>
          </Row>
        </Tabs.TabPane>
        <>
          {previewData.reportResult.data &&
            previewData.reportResult.data.subStats &&
            !isEmpty(previewData.reportResult.data.subStats['subTotals']) && (
              <Tabs.TabPane tab='Sub Total' key='2'>
                <Row gutter={16} className='space-top-10'>
                  <Col xs={24}>
                    <>
                      {Object.entries(
                        previewData.reportResult.data?.subStats?.['subTotals']
                      ).map((key, index) => (
                        <>
                          <Typography>
                            <Typography.Text strong>
                              Base Column{': '}
                            </Typography.Text>
                            <Typography.Text mark strong>
                              {key[0]}
                            </Typography.Text>
                          </Typography>
                          <TableComponent
                            key={index}
                            data={key[1]}
                            tableType={TableType.SUB_STATS}
                            paginationAtTop={false}
                            baseColumn={key[0]}
                          />
                        </>
                      ))}
                    </>
                  </Col>
                </Row>
              </Tabs.TabPane>
            )}
        </>
        <>
          {previewData.reportResult.data &&
            previewData.reportResult.data?.subStats &&
            !isEmpty(previewData.reportResult.data?.subStats['subAverage']) && (
              <Tabs.TabPane tab='Sub Average' key='3'>
                <Row gutter={16} className='space-top-10'>
                  <Col xs={24}>
                    <>
                      {Object.entries(
                        previewData.reportResult.data?.subStats?.['subAverage']
                      ).map((key, index) => (
                        <>
                          <Typography>
                            <Typography.Text strong>
                              Base Column{': '}
                            </Typography.Text>
                            <Typography.Text mark strong>
                              {key[0]}
                            </Typography.Text>
                          </Typography>
                          <TableComponent
                            key={index}
                            data={key[1]}
                            tableType={TableType.SUB_STATS}
                            paginationAtTop={false}
                            baseColumn={key[0]}
                          />
                        </>
                      ))}
                    </>
                  </Col>
                </Row>
              </Tabs.TabPane>
            )}
        </>
        :
      </Tabs>
    </Modal>
  );
};

export default ReportsPreviewModal;
