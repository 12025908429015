import { Button, Space } from 'antd';
import { DeleteOutlined, EyeOutlined } from '@ant-design/icons';

import { ColumnsType } from 'antd/lib/table';
import { Group } from '../../types/data/group.type';
import { GroupTableProps } from '../../types/props/group-table.props';
import { Link } from 'react-router-dom';
import { Report } from '../../types/data/report.type';
import { RouteConstants } from '../../constants/route.constants';
import { User } from '../../types/data/user.type';
import isEmpty from 'lodash.isempty';
import isUndefined from 'lodash.isundefined';

/**
 * get groups columns
 * @returns {ColumnsType<Group>}
 */
export const getGroupsColumns = (
  props: GroupTableProps
): ColumnsType<Group> => [
  {
    title: 'Name',
    dataIndex: 'name',
    key: 'name',
    sorter: (a, b) => a.name.localeCompare(b.name)
  },
  {
    title: 'Group Email',
    dataIndex: 'email',
    key: 'email',
    render: (email: string) => (email ? email : '-')
  },
  {
    title: 'No. of Users',
    dataIndex: 'users',
    key: 'users',
    render: (users: User[]) => (isEmpty(users) ? 0 : users.length),
    sorter: (a, b) => {
      const a_users = isUndefined(a.users) ? 0 : a.users.length,
        b_users = isUndefined(b.users) ? 0 : b.users.length;
      return a_users - b_users;
    }
  },
  {
    title: 'No. of Reports',
    dataIndex: 'reports',
    key: 'reports',
    render: (reports: Report[]) => (isEmpty(reports) ? 0 : reports.length),
    sorter: (a, b) => {
      const a_reports = isUndefined(a.reports) ? 0 : a.reports.length,
        b_reports = isUndefined(b.reports) ? 0 : b.reports.length;
      return a_reports - b_reports;
    }
  },
  {
    title: '',
    key: 'view-group',
    render: data => (
      <Space>
        <Button type='primary'>
          <Link to={RouteConstants.GROUP_DETAILS.replace(':groupId', data.id)}>
            <EyeOutlined /> View
          </Link>
        </Button>
        <Button
          type='primary'
          danger
          onClick={() => props.handleGroupRemoval?.(data)}>
          <DeleteOutlined /> Remove
        </Button>
      </Space>
    )
  }
];
